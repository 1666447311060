import React from "react";
import styles from "./masterLocation.module.css";
import PageHeader from "../../../components/PageHeader/PageHeader";
import Countries from "./Countries/Countries";
import MenuItems from "../../../components/MenuItems/MenuItems";
import State from "./State/State";
import City from "./City/City";
import Zipcode from "./Zipcode/Zipcode";

function MasterLocation() {
  const menuItems = [
    { label: "Country", state: "Country" },
    { label: "State", state: "State" },
    { label: "City", state: "City" },
    { label: "Zipcode", state: "Zipcode" },
  ];
  const contentMap = {
    Country: <Countries />,
    State: <State />,
    City: <City />,
    Zipcode: <Zipcode />,
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Master Data", path: "/master/location" },
          { label: "Location", path: "/master/location" },
        ]}
      />

      <MenuItems menuItems={menuItems} contentMap={contentMap} />
    </div>
  );
}

export default MasterLocation;
