import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";


const ViewZipcode = ({ item,fetchData }) => {

    const initialFormData = {
      zipcode: item?.zipcode,
    city: item?.city,
    state: item?.state,
  };

  const formFields = [
    { name: "zipcode", label: "Zip Code", type: "text" },
    { name: "city", label: "City Name", type: "text" },
    { name: "state", label: "State Name", type: "text" },
  ];

  return (
    <ViewItem
      fetchData={fetchData}
      initialFormData={initialFormData}
      formFields={formFields}
      title="View Zipcode"
      item={item}
    />
  );
};

export default ViewZipcode;


