import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./userFilter.module.css";
import SelectComponent from "../../../components/SelectComponent";
import { userRequest } from "../../../requestMethod";

const Filters = ({ isModalOpen, handleOk, handleCancel }) => {
  const [cityOptions , setCityOptions] = useState([])
  const [formData, setFormData] = useState({
    userType: "All",
    city: "",
  });

  const option = [
    { value: "All", label: "All" },
    { value: "Pandit", label: "Pandit" },
    { value: "Devotee", label: "Devotee" },
  ];

  const handleUserSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      userType: selectedOption.value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFilter = () => {
    handleOk(formData); // Pass formData back to parent component
  };


  useEffect(() => {
    fetchCity();
  }, []);
  const fetchCity = async () => {
    try {
    
      let response = await userRequest.get(
          `/master/getMasterCities`
        );
        const cityData = response?.data?.data
        let cityOpt = cityData?.map((item)=>({
          label:item?.city,
          value:item?.city
        }))
      setCityOptions(cityOpt);
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCitySelect = (selectedOption) => {
    console.log("formData@",selectedOption)
    setFormData((prevState) => ({
      ...prevState,
      city: selectedOption.value,
    }));
  };
  console.log("formData" ,formData)
  return (
    <Modal
      visible={isModalOpen}
      onCancel={handleCancel}
      onOk={handleFilter}
      // width={"29vw"}
      className={styles.modal}
    >
      <div className={styles.modalTitle}>
        <p>Filter</p>
      </div>
      <div className={styles.contentMain}>
 
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>User Type</p>
          </div>
          <div className={styles.fieldInput}>
          <SelectComponent
              options={option}
              onSelect={handleUserSelect}
              // initialSelectedOption={formData.userType}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>City</p>
          </div>
          <div className={styles.fieldInput}>
            {/* <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
            /> */}
               <SelectComponent
              options={cityOptions}
              onSelect={handleCitySelect}
              initialSelectedOption={formData.city}
            />
          </div>
        </div>
   
        <div className={styles.modalButton}>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleFilter}>Filter</button>
        </div>
      </div>
    </Modal>
  );
};

export default Filters;
