import React, { useState } from "react";
import { Modal } from "antd";
import styles from './reason.module.css'


const SeeReason = ({reason}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleDiscard = () => {
    setIsModalOpen(false);
  };



  return (
    <>
      <p onClick={showModal} className={styles.reasonButton}>View Reason</p>
      <Modal
        visible={isModalOpen}
        closable={false}
        footer={null}
        width={"29vw"}
        style={{ top: "35vh" }}
      >
        <div className={styles.lableName}>
          <p>Reason</p>
        </div>
        <div className={styles.reason}>
          <p>{reason}</p>
        </div>
        <div className={styles.action}>
          <button onClick={handleDiscard}>Back</button>
        </div>
      </Modal>
    </>
  );
};

export default SeeReason;
