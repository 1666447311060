import React from "react";
import styles from "./viewBanner.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import copiedImgIcon from "../../../assets/images/copiedImgIcon.png";
import { toast } from "sonner";

import { userRequest } from "../../../requestMethod";
import { Popconfirm } from "antd";
import { formatDate } from "../../../utils/DateFormat";

function ViewBanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const banner = location.state && location.state.banner;

  const handleEditClick = (banner) => {
    navigate(`/bannermanagement/edit`, { state: { banner } });
  };

  if (!banner) {
    return <div>No banner data available</div>;
  }

  const handleDelete = async (id) => {
    try {
      await userRequest.put("/master/updateMasterBanner", {
        id,
        deleted: true,
      });
      navigate("/bannermanagement");
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };
  const copyToClipboardForImg = () => {
    if (banner?.imageUrl) {
      navigator.clipboard
        .writeText(banner?.imageUrl)
        .then(() => {
          toast.success("Image URL copied");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  const copyToClipboardForLink = () => {
    if (banner?.linkUrl) {
      navigator.clipboard
        .writeText(banner?.linkUrl)
        .then(() => {
          toast.success("Link URL copied");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p className={styles.editBannerPara}>
            <span onClick={() => navigate("/bannermanagement")}>
              Banner Management
            </span>{" "}
            <span className={styles.activePageStyle}>&gt; Details</span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <Popconfirm
            title={`Are you sure to delete ${banner.title}?`}
            onConfirm={() => handleDelete(banner._id)}
            okText="Yes"
            cancelText="No"
            overlayClassName={styles.largePopconfirm}
          >
            <button className={styles.btnSaveDiscard}>Delete</button>
          </Popconfirm>
          <button
            className={styles.btnSaveDiscard}
            onClick={() => handleEditClick(banner)}
          >
            Edit
          </button>
        </div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.bannerImageSection}>
            <img src={banner.imageUrl} alt={banner.title} />
          </div>
          <div className={styles.bannerTitle}>
            <p>{banner.title}</p>
          </div>
          <div className={styles.bannerLink}>
            <p>Image URL</p>
            <p>
              {banner?.imageUrl}

              <img
                src={copiedImgIcon}
                className={styles.copiedImgIcon}
                onClick={copyToClipboardForImg}
                alt="Copy to clipboard"
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <div className={styles.bannerLink}>
            <p>Link URL</p>
            <p>
              {banner?.linkUrl}

              <img
                src={copiedImgIcon}
                className={styles.copiedImgIcon}
                onClick={copyToClipboardForLink}
                alt="Copy to clipboard"
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <div className={styles.bannerContent}>
            <p>Content</p>
            <p>{banner?.content}</p>
          </div>
          <div className={styles.bannerDetails}>
            <p>Venue Details</p>
            <div className={styles.singleDetails}>
              <div className={styles.details}>
                <p>Start Date</p>
                <p>{formatDate(banner.startDate)}</p>
              </div>
              <div className={styles.details}>
                <p>End Date</p>
                <p>{formatDate(banner.endDate)}</p>
              </div>
            </div>
            <div className={styles.singleDetails}>
              <div className={styles.details}>
                <p>Target Audience</p>
                <p>{banner?.targetAudience}</p>
              </div>
              <div className={styles.details}>
                <p>Geographical Scope</p>
                <p>{banner?.geographicalScope}</p>
              </div>
            </div>
            <div className={styles.singleDetails}>
              <div className={styles.details}>
                <p>Language</p>
                <p>{banner?.language}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewBanner;
