import React, { useEffect, useState } from "react";
import styles from "./city.module.css";
import { userRequest } from "../../../../requestMethod.js";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent.jsx";
import { handleExportDoc } from "../../../../utils/exportDoc.js";
import useDebounce from "../../../../utils/useDebounce.js";
import TableComponent from "../../../../components/TableComponent/TableComponent.jsx";
import DeleteAction from "../../../../components/TableComponent/DeleteAction.jsx";
import StatusToggleAction from "../../../../components/TableComponent/StatusToggleAction.jsx";
import ViewCity from "./ViewCity/ViewCity.jsx";
import EditCity from "./EditCity/EditCity.jsx";
import AddCity from "./AddCity/AddCity.jsx";

function City() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await userRequest.get(
        `/master/getMasterCities?search=${debouncedSearchQuery}&page=${page}`
      );
      setData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.put(`/master/updateMasterCities/${id}`, {
        deleted: true,
      });
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (id, activeStatus) => {
    try {
      const newActiveStatus = !activeStatus;
      await userRequest.put(`/master/updateMasterCities/${id}`, {
        active: newActiveStatus,
      });
      fetchData();
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handleExport = () => {
    handleExportDoc("/master/exportMasterCities", "city.xlsx");
  };

  const columns = [
    {
      title: "S.No",
      key: "sno",
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    { title: "Cities Id", key: "cityId" },
    { title: "Cities Name", key: "city" },
    {
      title: "State Name",
      key: "state",
      render: (item) => item.stateId?.state || "N/A",
    },
    { title: "latitude", key: "latitude" },
    { title: "longitude", key: "longitude" },
  ];

  const actions = [
    ({ item }) => <ViewCity item={item} />,
    ({ item }) => <EditCity fetchData={fetchData} item={item} />,
    // ({ item }) => <DeleteAction item={item} onDelete={handleDelete} />,
    ({ item }) => (
      <StatusToggleAction item={item} onStatusChange={handleChange} />
    ),
  ];

  const customWidths = {
    sno: "5%",
    countryId: "15%",
    country: "20%",
    continent: "20%",
    action: "40%",
  };

  return (
    <div className={styles.main}>
      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
        addComponent={() => <AddCity fetchData={fetchData} />}
      />
      <div className={styles.contentContainer}>
        <TableComponent
          columns={columns}
          data={data}
          loading={loading}
          actions={actions}
          // onDelete={handleDelete}
          onStatusChange={handleChange}
          currentPage={currentPage}
          totalPages={totalPages}
          onNext={() => setPage(page + 1)}
          onPrevious={() => setPage(page - 1)}
          customWidths={customWidths}
        />
      </div>
    </div>
  );
}

export default City;
