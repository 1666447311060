import React, { useState } from "react";
import styles from "./taskManagement.module.css";
import { useNavigate } from "react-router-dom";
import Tasks from "./Tasks/Tasks";



function TaskManagement() {
  const menuItems = [
    { label: "Tasks", state: "Tasks" },
  ];

  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("Tasks");

  const handlePoliciesMenu = (menu) => {
    setActiveMenuItem(menu);
  };
  return (
    <div className={styles.main}>
      <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/master/taskmanagement")}>
              Master Data{" "}
              <span className={styles.devalayaHeadingColor}>{">"} Tasks</span>
            </span>
          </p>
        </div>

        <div className={styles.pageHeaderRight}></div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={
                activeMenuItem === item.state
                  ? `${styles.singleMenu} ${styles.activeMenu}`
                  : styles.singleMenu
              }
              onClick={() => handlePoliciesMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.activePage}>
          {activeMenuItem === "Tasks" && <Tasks />}
        </div>
      </div>
    </div>
  );
}

export default TaskManagement;
