import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";

const ViewRules = ({ fetchData, item }) => {
  const initialFormData = {
    name: item?.name,
    rules: item?.rules,
  };

  const apiEndpoint = "/master/getAdminMasterRules";
  const formFields = [
    { name: "name", label: "Name", type: "text" },
    { name: "rules", label: "Rules", type: "text" },
  ];

  return (
    <ViewItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="View Rules"
      item={item}
    />
  );
};

export default ViewRules;
