// import React, { useEffect, useState } from "react";
// import styles from "./roles.module.css";
// import { useNavigate } from "react-router-dom";
// import { userRequest } from "../../../requestMethod";
// import editIcon from "../../../assets/images/edit.png";
// import deleteIcon from "../../../assets/images/delete.png";
// import { Popconfirm, Spin, Switch } from "antd";
// import { getConfig } from "../../../utils/getConfig";
// import useDebounce from "../../../utils/useDebounce";

// function Roles() {
//   const { BASE_URL } = getConfig();
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const debouncedSearchQuery = useDebounce(searchQuery, 1000);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [loading, setLoading] = useState(true);

//   const handleNext = () => {
//     if (page < totalPages) {
//       setPage(page + 1);
//     }
//   };

//   const handlePrevious = () => {
//     if (page > 1) {
//       setPage(page - 1);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [debouncedSearchQuery, page]);

//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       const response = await userRequest.get(
//         `/adminUserPermission/getAllUserForAdmin?search=${debouncedSearchQuery}&page=${page}`
//       );
//       setData(response?.data?.data);
//       setTotalPages(response?.data?.pagination?.totalPages);
//       setCurrentPage(response?.data?.pagination?.page);
//     } catch (error) {
//       console.log("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       await userRequest.delete(`/adminUserPermission/deleteUserForAdmin/${id}`);
//       fetchData();
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

//   const handleChange = async (email, activeStatus) => {
//     try {
//       const newActiveStatus = !activeStatus;
//       await userRequest.patch(`/roles/updateRolesAndPermissionsStatus`, {
//         email: email,
//         activeStatus: newActiveStatus,
//       });
//       fetchData();
//     } catch (error) {
//       console.error("Error updating user status:", error);
//     }
//   };

//   const handleExport = async () => {
//     try {
//       const exportUrl = `/adminUserPermission/exportUserForAdmin?page=${page}&search=${debouncedSearchQuery}`;
//       const exportResponse = await userRequest.get(exportUrl, {
//         responseType: "blob",
//       });

//       // Create a Blob object from the response data
//       const blob = new Blob([exportResponse.data], {
//         type: "application/octet-stream",
//       });

//       // Create a URL for the blob and initiate download
//       const downloadUrl = URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = downloadUrl;
//       a.download = "exported_data.xlsx"; // Set the file name here
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//     } catch (error) {
//       console.error("Error exporting data:", error);
//     }
//   };

//   return (
//     <Spin spinning={loading} size="small">
//       <div className={styles.tableMain}>
//         <div className={styles.filterExport}>
//           <div className={styles.search}>
//             <input
//               type="text"
//               onChange={(e) => setSearchQuery(e.target.value)}
//               value={searchQuery}
//             />
//           </div>

//           <div className={styles.export}>
//             <p onClick={handleExport}>Export as CSV, PDF, Excel doc</p>
//           </div>
//         </div>
//         <div className={styles.rolesTableMain}>
//           <table>
//             <thead>
//               <tr>
//                 <th>S.No.</th>
//                 <th>ID</th>
//                 <th>User Name</th>
//                 <th>User Type</th>
//                 <th>Email</th>
//                 <th>Permission</th>
//                 <th>Details</th>
//                 <th>Action & Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.length > 0 ? (
//                 data.map((item, index) => (
//                   <tr key={item._id}>
//                     <td>{index + 1}</td>
//                     <td>{item?._id}</td>
//                     <td>{item?.userId?.name}</td>
//                     <td>{item?.userId?.roleId?.roleName}</td>
//                     <td>{item?.userId?.email}</td>
//                     <td>
//                       {item?.permissions?.map((per) => (
//                         <>{per?.permissionsId?.permissionName}...</>
//                       ))}
//                     </td>
//                     <td
//                       className={styles.viewDetails}
//                       onClick={() =>
//                         navigate(`/rolesandpermissions/viewroles/${item._id}`, {
//                           state: { item },
//                         })
//                       }
//                     >
//                       View
//                     </td>
//                     <td className={styles.actionTd}>
//                       <img
//                         src={editIcon}
//                         alt=""
//                         onClick={() =>
//                           navigate(
//                             `/rolesandpermissions/editroles/${item._id}`,
//                             { state: { item } }
//                           )
//                         }
//                       />
//                       <Popconfirm
//                         title={`Are you sure to delete ${item?.userId?.name}?`}
//                         onConfirm={() => handleDelete(item?._id)}
//                         okText="Yes"
//                         cancelText="No"
//                         overlayClassName={styles.largePopconfirm}
//                       >
//                         <img src={deleteIcon} alt="" />
//                       </Popconfirm>
//                       <Switch
//                         size="small"
//                         checked={item?.activeStatus}
//                         onChange={() =>
//                           handleChange(item?.email, item.activeStatus)
//                         }
//                       />
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="8">No data found</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//         {data.length > 0 ? (
//           <div className={styles.footer}>
//             <div
//               className={styles.action}
//               style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
//             >
//               <button
//                 className={`${styles.actionButton} ${
//                   page === 1 ? styles.disabled : styles.activeButton
//                 }`}
//                 onClick={handlePrevious}
//                 disabled={page === 1}
//               >
//                 Previous
//               </button>
//               <button
//                 className={`${styles.actionButton} ${
//                   page === totalPages ? styles.disabled : styles.activeButton
//                 }`}
//                 onClick={handleNext}
//                 disabled={page === totalPages}
//               >
//                 Next
//               </button>
//             </div>
//             <div className={styles.page}>
//               <span>{currentPage}</span> <span>of</span>{" "}
//               <span>{totalPages}</span>
//             </div>
//           </div>
//         ) : (
//           <div></div>
//         )}
//       </div>
//     </Spin>
//   );
// }

// export default Roles;




import React, { useEffect, useState } from "react";
import styles from "./roles.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import { Popconfirm, Switch } from "antd";
import useDebounce from "../../../utils/useDebounce";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { handleExportDoc } from "../../../utils/exportDoc";

function Roles() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await userRequest.get(
        `/adminUserPermission/getAllUserForAdmin?search=${debouncedSearchQuery}&page=${page}`
      );
      setData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setCurrentPage(response?.data?.pagination?.page);
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.delete(`/adminUserPermission/deleteUserForAdmin/${id}`);
      fetchData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleChange = async (email, activeStatus) => {
    try {
      const newActiveStatus = !activeStatus;
      await userRequest.patch(`/roles/updateRolesAndPermissionsStatus`, {
        email: email,
        activeStatus: newActiveStatus,
      });
      fetchData();
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };


  const handleExport = () => {
    handleExportDoc('/adminUserPermission/exportUserForAdmin?page', 'AdminRoles.xlsx');
  };


  const columns = [
    {
      title: 'S.No.',
      key: 'serialNumber',
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: 'ID',
      key: '_id',
      render: item => item._id,
    },
    {
      title: 'User Name',
      key: 'userName',
      render: item => item.userId?.name,
    },
    {
      title: 'User Type',
      key: 'userType',
      render: item => item.userId?.roleId?.roleName,
    },
    {
      title: 'Email',
      key: 'email',
      render: item => item.userId?.email,
    },
    // {
    //   title: 'Permission',
    //   key: 'permissions',
    //   render: item => item?.permissions?.map(per => per?.permissionsId?.permissionName).join(', '),
    // },
    {
      title: 'Details',
      key: 'details',
      render: item => (
        <span
          className={styles.viewDetails}
          onClick={() =>
            navigate(`/rolesandpermissions/viewroles/${item._id}`, { state: { item } })
          }
        >
          View
        </span>
      ),
    },
    {
      title: 'Action & Status',
      key: 'actions',
      render: item => (
        <div className={styles.actionTd}>
          <img
            src={editIcon}
            alt="Edit"
            onClick={() =>
              navigate(`/rolesandpermissions/editroles/${item._id}`, { state: { item } })
            }
          />
          <Popconfirm
            title={`Are you sure to delete ${item?.userId?.name}?`}
            onConfirm={() => handleDelete(item?._id)}
            okText="Yes"
            cancelText="No"
            overlayClassName={styles.largePopconfirm}
          >
            <img src={deleteIcon} alt="Delete" />
          </Popconfirm>
          <Switch
            size="small"
            checked={item?.activeStatus}
            onChange={() => handleChange(item?.email, item?.activeStatus)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={styles.rolesContainer}>
        <div className={styles.filterExport}>
          <div className={styles.search}>
            <input
              type="text"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
              placeholder="Search..."
            />
          </div>
          <div className={styles.export}>
            <p onClick={handleExport}>Export as CSV, PDF, Excel doc</p>
          </div>
        </div>
        <TableComponent
          columns={columns}
          data={data}
          loading={loading}
          onNext={handleNext}
          onPrevious={handlePrevious}
          currentPage={currentPage}
          totalPages={totalPages}
          customWidths={{
            serialNumber: "8vw",
            _id: "10vw",
            userName: "20vw",
            userType: "10vw",
            email: "20vw",
            permissions: "20vw",
            details: "8vw",
            actions: "20vw",
          }}
        />
      </div>
    </>
  );
}

export default Roles;
