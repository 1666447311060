import React, { useState } from "react";
import styles from "./RolesAndPermissions.module.css";
import { useNavigate } from "react-router-dom";
import Roles from "../RolesAndPermissionsManagement/Roles/Roles";
import PageHeader from "../../components/PageHeader/PageHeader";
import MenuItems from "../../components/MenuItems/MenuItems";

function RolesAndPermissionsManagement() {
  const navigate = useNavigate();
  const menuItems = [{ label: "All", state: "All" }];

  const contentMap = {
    All: <Roles />,
  };
  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          {
            label: "Roles And Permissions Management",
            path: "/rolesandpermissions",
          },
        ]}
        buttonLabel={"Add New"}
        onButtonClick={() => navigate("/rolesandpermissions/createroles")}
      />

      <MenuItems menuItems={menuItems} contentMap={contentMap} />
    </div>
  );
}

export default RolesAndPermissionsManagement;
