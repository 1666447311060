import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";


const EditIshtDhaiva = ({ fetchData, task }) => {
  const initialFormData = {
    daivaName: task?.daivaName || "",
    daivaPic: task?.daivaPic || "",
    daivaSlogan: task?.daivaSlogan || "",
  };

  const formFields = [
    { name: "daivaName", label: "Daiva Name", type: "text" },
    { name: "daivaPic", label: "Daiva Pic", type: "file", uploadLabel: "Upload Media" },
    { name: "daivaSlogan", label: "Daiva Slogan", type: "text" },
  ];

  const apiEndpoint = `/master/updateDaiva/`;

  return (
    <EditItem
      fetchData={fetchData}
      item={task}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Istha Dhaiva"
      uploadFieldName="daivaPic"
    />
  );
};

export default EditIshtDhaiva;
