import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";

const EditUtsavAttraction = ({ fetchData, item }) => {
  const initialFormData = {
    name: item?.name,
  };

  const apiEndpoint = "/master/updateMasterUtsavAttraction";
  const formFields = [{ name: "name", label: "Name", type: "text" }];

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Utsav Attraction"
      item={item}
    />
  );
};

export default EditUtsavAttraction;