import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Spin } from "antd";
import { toast } from "sonner";
import { userRequest } from "../../../../requestMethod";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import styles from "./CreateTermsAndPolices.module.css";
import SelectComponent from "../../../../components/SelectComponent";

function CreateTermsAndPolicies() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { type } = location.state;

  const [data, setData] = useState({ type: type });
  // const languages = ["Hindi", "English", "Sanskrit"];
  const [languages, setLanguages] = useState([]);
  const fetchLanguages = async () => {
    try {
      let response = await userRequest.get("/master/getMasterLanguage");
      setLanguages(response?.data?.data);
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    }
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  const handleChangeContent = (value) => {
    setData({ ...data, content: value });
  };

  const handleChange = (e) => {
    setData({ ...data, section: e.target.value });
  };
  const handleDateChange = (e) => {
    setData({ ...data, activation_date: e.target.value });
  };

  const breadcrumb = [
    {
      label:
        type === "termsAndConditions" ? "Terms & Conditions" : "Privacy Policy",
      path:
        type === "termsAndConditions"
          ? "/staticcontents/termsandconditions"
          : "/staticcontents/privacypolicy",
    },
    {
      label: "Create",
      path:
        type === "termsAndConditions"
          ? "/staticcontents/termsandconditions/create"
          : "/staticcontents/privacypolicy/create",
    },
  ];

  // Function to strip HTML tags and validate content
  const stripHtml = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const handleSaveClick = async () => {
    // Validation
    if (!data.section || data.section.trim() === "") {
      toast.error("Section is required and cannot be empty.");
      return;
    }

    const strippedContent = stripHtml(data.content);

    if (!strippedContent || strippedContent.trim() === "") {
      toast.error("Content is required and cannot be empty.");
      return;
    }
    if (!data.language) {
      toast.error("Language is required and cannot be empty.");
      return;
    }
    if (!data.activation_date) {
      toast.error("Activation date is required and cannot be empty.");
      return;
    }
    try {
      setIsLoading(true);

     await userRequest.post(
        `/admin/static/createStaticContent`,
        data
      );
      type === "termsAndConditions"
        ? navigate("/staticcontents/termsandconditions")
        : navigate("/staticcontents/privacypolicy");
      toast.success("Created successfully!");
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDiscard = () => {
    type === "termsAndConditions"
      ? navigate("/staticcontents/termsandconditions")
      : navigate("/staticcontents/privacypolicy");
  };
  const languageOptions = languages?.map((item) => ({
    value: item?.language,
    label: item?.language,
  }));

  return (
    <Spin spinning={isLoading} size="small">
      <div className={styles.main}>
        <PageHeaderTwo
          breadcrumb={breadcrumb}
          discardLabel="Back"
          saveLabel="Save"
          onDiscardClick={handleDiscard}
          onSaveClick={handleSaveClick}
        />

        <div className={styles.contentContainerTask}>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Section</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="section"
                  value={data?.section}
                  onChange={handleChange}
                  placeholder="Enter"
                />
              </div>
              <div className={styles.fieldLabel}>
                <p>Activation Date</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="date"
                  name="activation_date"
                  value={data?.activation_date}
                  onChange={handleDateChange}
                  placeholder="Enter"
                />
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Language</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={languageOptions}
                  onSelect={(selectedOption) =>
                    setData({
                      ...data,
                      language: selectedOption.value,
                    })
                  }
                  initialSelectedOption={data.language}
                />
              </div>
            </div>
          </div>

          {data?.content !== null && (
            <>
              <label>Content</label>
              <RichTextEditor
                value={data?.content}
                onChange={handleChangeContent}
              />
            </>
          )}
        </div>
      </div>
    </Spin>
  );
}

export default CreateTermsAndPolicies;
