// // import React, { useState } from "react";
// // import { Modal } from "antd";
// // import styles from "./addDevalayaType.module.css";
// // import { toast } from "sonner";
// // import FileUpload from "../../../../../components/FileUpload/FileUpload";
// // import { userRequest } from "../../../../../requestMethod";

// // const AddDevalayaType = ({fetchData}) => {
// //   const [isModalOpen, setIsModalOpen] = useState(false);
// //   const initialFormData = {
// //     type: '',
// //   };
// //   const [formData, setFormData] = useState(initialFormData);
// //   const [resetKey, setResetKey] = useState(0);

// //   const showModal = () => {
// //     setIsModalOpen(true);
// //   };

// //   const handleChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({
// //       ...formData,
// //       [name]: value,
// //     });
// //   };


// //   const handleOk = async () => {
// //     setIsModalOpen(false);
// //     const { type } = formData;

// //     if (!type.trim()) {
// //       toast.error("Type is required and cannot be empty");
// //       resetForm();
// //       await fetchData()
// //       return;
// //     }

// //     if (/\d/.test(type)) {
// //       toast.error("Type should not contain numbers");
// //       resetForm();
// //       await fetchData()
// //       return;
// //     }

// //     try {
// //      await userRequest.post(`/master/createMasterDevalayaType`, formData);    
// //       toast.success("Devalaya type added successfully");
// //       resetForm();
// //       await fetchData()
// //     } catch (error) {
// //       console.log("Error creating Daiva:", error);
// //       // Show error message
// //       toast.error("Failed to create devalaya type");
// //     }
// //   };

// //   const handleCancel = () => {
// //     setIsModalOpen(false);
// //     resetForm();
// //   };

// //   const resetForm = () => {
// //     setFormData(initialFormData);
// //     setIsModalOpen(false);
// //     setResetKey((prevKey) => prevKey + 1);
// //   };

// //   return (
// //     <>
// //     <button className={styles.addNewBtn} onClick={showModal}>Add New</button>
// //       <Modal
// //         visible={isModalOpen}
// //         onCancel={handleCancel}
// //         onOk={handleOk}
       
// //         className={styles.modal}
// //       >
// //         <div className={styles.modalTitle}>
// //           <p>Add Devalaya Type</p>
// //         </div>
// //         <div className={styles.contentMain}>
// //           <div className={styles.fieldContainer}>
// //             <div className={styles.fieldLabel}>
// //               <p>Type</p>
// //             </div>
// //             <div className={styles.fieldInput}>
// //               <input
// //                 type="text"
// //                 name="type"
// //                 value={formData.type}
// //                 onChange={handleChange}
// //                 key={`type-${resetKey}`}
// //               />
// //             </div>
// //           </div>
// //           <div className={styles.modalButton}>
// //             <button onClick={handleCancel}>Cancel</button>
// //             <button onClick={handleOk}>Add</button>
// //           </div>
// //         </div>
// //       </Modal>
// //     </>
// //   );
// // };

// // export default AddDevalayaType;



import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";

const AddDevalayaType = ({ fetchData }) => {
  const initialFormData = {
    type: "",
  };

  const apiEndpoint = "/master/createMasterDevalayaType";
  const formFields = [{ name: "type", label: "Type", type: "text" }];
  


  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Devalaya Type"
    />
  );
};

export default AddDevalayaType;

// import React, { useState } from "react";
// import { Modal } from "antd";
// import styles from "./addDevalayaType.module.css";
// import { toast } from "sonner";
// import { userRequest } from "../../../../../requestMethod";

// const AddDevalayaType = ({ fetchData }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const initialFormData = {
//     type: '',
//   };
//   const [formData, setFormData] = useState(initialFormData);
//   const [resetKey, setResetKey] = useState(0);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleOk = async () => {
//     // Trim the type value before validation and submission
//     const trimmedType = formData.type.trim();

//     if (!trimmedType) {
//       toast.error("Type is required and cannot be empty");
//       resetForm();
//       await fetchData();
//       return;
//     }

//     if (/\d/.test(trimmedType)) {
//       toast.error("Type should not contain numbers");
//       resetForm();
//       await fetchData();
//       return;
//     }

//     try {
//       await userRequest.post(`/master/createMasterDevalayaType`, { type: trimmedType });
//       toast.success("Devalaya type added successfully");
//       resetForm();
//       await fetchData();
//     } catch (error) {
//       console.log("Error creating Devalaya:", error);
//       toast.error("Failed to create devalaya type");
//     }
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//     resetForm();
//   };

//   const resetForm = () => {
//     setFormData(initialFormData);
//     setIsModalOpen(false);
//     setResetKey((prevKey) => prevKey + 1);
//   };

//   return (
//     <>
//       <button className={styles.addNewBtn} onClick={showModal}>Add New</button>
//       <Modal
//         visible={isModalOpen}
//         onCancel={handleCancel}
//         onOk={handleOk}
//         className={styles.modal}
//       >
//         <div className={styles.modalTitle}>
//           <p>Add Devalaya Type</p>
//         </div>
//         <div className={styles.contentMain}>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Type</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="type"
//                 value={formData.type}
//                 onChange={handleChange}
//                 key={`type-${resetKey}`}
//               />
//             </div>
//           </div>
//           <div className={styles.modalButton}>
//             <button onClick={handleCancel}>Cancel</button>
//             <button onClick={handleOk}>Add</button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default AddDevalayaType;

