import React, { useEffect, useState } from "react";
import styles from "./bannerManagement.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import BannerFilter from "./BannerFilter";
import useDebounce from "../../utils/useDebounce";
import SearchFilterComponent from "../../components/SearchFilter/SearchFilterComponent";
import { handleExportDoc } from "../../utils/exportDoc";
import PageHeader from "../../components/PageHeader/PageHeader";
import TableComponent from "../../components/TableComponent/TableComponent";
import { formatDate } from "../../utils/DateFormat";

function BannerManagement() {
  const [bannerData, setBannerData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({ startDate: "", endDate: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filters]);

  const fetchData = async () => {
    try {
      const { startDate, endDate } = filters;
      const response = await userRequest.get(
        `/master/getMasterBannerAdmin?search=${debouncedSearchQuery}&startDate=${startDate}&endDate=${endDate}`
      );
      setBannerData(response.data.banners);
      setTotalPages(response.data.totalPages);
      setCurrentPage(response.data.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewClick = (banner) => {
    navigate(`/bannermanagement/view`, { state: { banner } });
  };

  const handleEditClick = (banner) => {
    navigate(`/bannermanagement/edit`, { state: { banner } });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (filterData) => {
    setFilters(filterData);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleExport = () => {
    handleExportDoc("/master/exportBannerDataToExcel", "BannerManagement.xlsx");
  };

  const handleButtonClick = () => {
    navigate("/bannermanagement/create");
  };

  const columns = [
    { key: "title", title: "Title" },
    {
      key: "startDate",
      title: "Start Date",
      render: (item) => formatDate(item.startDate),
    },
    {
      key: "endDate",
      title: "End Date",
      render: (item) => formatDate(item.endDate),
    },
    { key: "language", title: "Languages" },
    { key: "geographicalScope", title: "Geographical Scope" },
    { key: "targetAudience", title: "Target Audience" },
  ];

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Banner Management", path: "/bannermanagement" },
        ]}
        buttonLabel="Create New Banner"
        onButtonClick={handleButtonClick}
      />
      <div className={styles.contentContainer}>
        <SearchFilterComponent
          setSearchQuery={setSearchQuery}
          onExportClick={handleExport}
          onFilterClick={showModal}
          filter
        />
        <div className={styles.contentContainer}>
          <div className={styles.activePage}>
            <TableComponent
              columns={columns}
              data={bannerData}
              loading={loading}
              currentPage={currentPage}
              totalPages={totalPages}
              onNext={handleNext}
              onPrevious={handlePrevious}
              actions={[
                (props) => (
                  <span
                    className={styles.viewDetails}
                    onClick={() => handleViewClick(props.item)}
                  >
                    View
                  </span>
                ),
                (props) => (
                  <span
                    className={styles.editDetails}
                    onClick={() => handleEditClick(props.item)}
                  >
                    Edit
                  </span>
                ),
              ]}
            />
          </div>
        </div>
      </div>
      <BannerFilter
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
}

export default BannerManagement;
