import React, { useState } from "react";
import styles from "./setKYC.module.css";
import { useNavigate, useParams } from "react-router-dom";
import KYCPrice from "./KYCPrice";
import SetCoupon from "./SetCoupon";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";
import MenuItems from "../../../../components/MenuItems/MenuItems";


function SetKYCPrice() {
  const [editKYC, setEditKYC] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const initialFormData = {
    country: "India",
    state: "",
    kycPrice: "",
    DiscountType: "",
    finalAmount: "",
    discountAmount: "",
    taxType: "",
    taxPercentage: "",
    finalTaxAmount: "",
    startDate: "",
    expiryDate: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const menuItems = [
    { label: "KYC Price", state: "KYC Price" },
    { label: "Coupon", state: "Coupon" },
  ];

  const contentMap = {
    "KYC Price": (
      <KYCPrice
        formData={formData}
        setFormData={setFormData}
        setEditKYC={setEditKYC}
        errors={errors}
        setErrors={setErrors}
      />
    ),
    Coupon: <SetCoupon />,
  };

  const validateFormData = (formData) => {
    let errors = {};
    if (!formData.country) errors.country = "Country is required";
    if (!formData.state) errors.state = "State is required";
    if (!formData.kycPrice) errors.kycPrice = "KYC Price is required";
    if (!formData.DiscountType) errors.DiscountType = "Discount Type is required";
    if (!formData.discountAmount) errors.discountAmount = "Discount Amount is required";
    if (!formData.taxType) errors.taxType = "Tax Type is required";
    if (!formData.taxPercentage) errors.taxPercentage = "Tax Percentage is required";
    if (!formData.startDate) errors.startDate = "Start Date is required";
    if (!formData.expiryDate) errors.expiryDate = "Expiry Date is required";
    return errors;
  };

  const handleSaveClick = async () => {
    const validationErrors = validateFormData(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      if (editKYC) {
        await userRequest.put(
          `/admin/adminPanditKycRoutes/updateKycPrice/${id}`,
          formData
        );
        toast.success("Updated successfully!");
      } else {
        await userRequest.post(
          "/admin/adminPanditKycRoutes/createKycPrice",
          formData
        );
        toast.success("Saved successfully!");
      }
      navigate("/panditkycmanagement/viewkycprice");
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error(error.message);
    }
  };

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Pandit KYC Management", path: "/panditkycmanagement" },
          { label: "Set Price", path: "#" },
        ]}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={() => navigate("/panditkycmanagement/viewkycprice")}
        onSaveClick={handleSaveClick}
      />
      <MenuItems menuItems={menuItems} contentMap={contentMap} />
    </div>
  );
}

export default SetKYCPrice;
