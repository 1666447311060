import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import { userRequest } from '../../../../../requestMethod';

function ViewKYCPriceDetail() {
    const navigate = useNavigate()
    const {id} = useParams(); 
  const [kycPriceData, setkycPriceData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(`/admin/adminPanditKycRoutes/getSingleAdminKycPrice/${id}`);
      setkycPriceData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
    return (
        <div className={styles.main}>
     <div className={styles.pageHeader}>
        <div className={styles.pageHeaderLeft}>
          <p>
            <span onClick={() => navigate("/panditkycmanagement")}>
              Pandit KYC Management
            </span>{" "}
            <span onClick={() => navigate("/panditkycmanagement/viewkycprice")}>&gt; View KYC Price</span>
            <span className={styles.activePageStyle}>&gt; Details</span>
          </p>
        </div>
        <div className={styles.pageHeaderRight}>
          <button onClick={() => navigate("/panditkycmanagement/viewkycprice")}>
            Back
          </button>
          <button onClick={() => navigate(`/panditkycmanagement/setprice/${id}`)}>
            Edit
          </button>
        </div>
      </div>
        <div className={styles.subMain}>
            <div className={styles.subMainHeading}><p>KYC Price Details</p></div>
          <div className={styles.detailsContainer}>
            <div className={styles.singleDetailContainer}>
              <div className={styles.singleDetail}>
                <p>Country</p>
                <p>{kycPriceData?.country}</p>
              </div>
              <div className={styles.singleDetail}>
                <p>State</p>
                <p>{kycPriceData?.state}</p>
              </div>
            </div>
            <div className={styles.singleDetailContainer}>
              <div className={styles.singleDetail}>
                <p>KYC Price</p>
                <p>{kycPriceData?.kycPrice}</p>
              </div>

            </div>
            <div className={styles.singleDetailContainer}>
              <div className={styles.singleDetail}>
                <p>Discount Type</p>
                <p>{kycPriceData?.DiscountType}</p>
              </div>
            </div>
            <div className={styles.singleDetailContainer}>
              <div className={styles.singleDetail}>
                <p>Discount Amount</p>
                <p>{kycPriceData?.discountAmount}</p>
              </div>
              <div className={styles.singleDetail}>
                <p>Final Amount</p>
                <p>{kycPriceData?.finalAmount}</p>
              </div>
            </div>
            <div className={styles.singleDetailContainer}>
              <div className={styles.singleDetail}>
                <p>Tax Type</p>
                <p>{kycPriceData?.taxType}</p>
              </div>
              <div className={styles.singleDetail}>
                <p>Tax Percentage</p>
                <p>{kycPriceData?.taxPercentage}</p>
              </div>
            </div>
            <div className={styles.singleDetailContainer}>
              <div className={styles.singleDetail}>
                <p>Final Tax Amount</p>
                <p>{kycPriceData?.finalTaxAmount}</p>
              </div>
            </div>
            <div className={styles.singleDetailContainer}>
              <div className={styles.singleDetail}>
                <p>Start Date</p>
                <p>{new Date(kycPriceData?.startDate).toLocaleDateString()}</p>
              </div>
              <div className={styles.singleDetail}>
                <p>End Date</p>
                <p>{new Date(kycPriceData?.expiryDate).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      );
}

export default ViewKYCPriceDetail
