import React, { useEffect, useState } from "react";
import styles from "../CreateTejPoints/createTejPoints.module.css";
import { useLocation } from "react-router-dom";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { userRequest } from "../../../../requestMethod";

function ViewTejPoints() {
  const location = useLocation();
  const tejPoint = location.state && location.state.tejPoint;
  const [roles, setRoles] = useState([]);
  const [formData, setFormData] = useState(null);

  const fetchRoles = async () => {
    try {
      const response = await userRequest.get("/userRoles/getAllUserRoles");
      const fetchedRoles = response?.data?.data || [];
      setRoles(fetchedRoles);

      // Calculate rolesType and initialize formData after roles are fetched
      const roleOptions = fetchedRoles.map((role) => ({
        label: role.roleName,
        value: role._id,
      }));

      const rolesType = tejPoint?.roleId
        ? roleOptions.find((option) => option?.value === tejPoint?.roleId)?.label
        : undefined;

      const initialFormData = {
        geographicalScope: tejPoint?.geographicalScope,
        country: tejPoint?.geolocation?.map((geo) => geo.country) || [],
        state: tejPoint?.geolocation[0]?.state,
        city: tejPoint?.geolocation[0]?.city,
        roleType: rolesType,
        loginPoint: tejPoint?.loginPoint,
        templePoint: tejPoint?.templePoint,
        referPoint: tejPoint?.referPoint,
        utsavPoint: tejPoint?.utsavPoint,
        bookingPoint: tejPoint?.bookingPoint,
        completeKycPoint: tejPoint?.completeKycPoint,
      };

      setFormData(initialFormData);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  if (!formData) {
    return <div>Loading...</div>; // Optional: Add a loading indicator
  }

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Svarna Management", path: "/svarnamanagement" },
          { label: "View Tej Points", path: "#" },
        ]}
      />
      <div className={styles.contentContainer}>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Geographical Scope</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData?.geographicalScope} disabled />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Country</p>
            </div>
            <div className={styles.fieldInput}>
              {tejPoint?.geographicalScope === "International" ? (
                <div>
                  {formData.country.map((country, index) => (
                    <span
                      key={index}
                      className="badge badge-light text-light bg-secondary me-2"
                    >
                      {country}
                    </span>
                  ))}
                </div>
              ) : (
                <input type="text" value={formData?.country[0]} disabled />
              )}
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Role</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.roleType} disabled />
            </div>
          </div>
          {tejPoint?.geographicalScope !== "International" &&
            tejPoint?.geographicalScope !== "National" && (
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>State</p>
                </div>
                <div className={styles.fieldInput}>
                  <input type="text" value={formData?.state} disabled />
                </div>
              </div>
            )}
        </div>
        <div className={styles.fieldContainer}>
          {tejPoint?.geographicalScope === "Local" && (
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>City</p>
              </div>
              <div className={styles.fieldInput}>
                <input type="text" value={formData?.city} disabled />
              </div>
            </div>
          )}
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Login Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.loginPoint} disabled />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Add Temple Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.templePoint} disabled />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Refer Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.referPoint} disabled />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Create Utsav Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.utsavPoint} disabled />
            </div>
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Booking Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.bookingPoint} disabled />
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Complete KYC Point</p>
            </div>
            <div className={styles.fieldInput}>
              <input type="text" value={formData.completeKycPoint} disabled />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewTejPoints;
