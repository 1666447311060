import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./addDevalaya.module.css";
import { toast } from "sonner";
import { userRequest } from "../../../../requestMethod";
import SelectComponent from "../../../../components/SelectComponent";
import FileUpload from "../../../../components/FileUpload/FileUpload";

const AddDevalaya = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resetKey, setResetKey] = useState(0); // Key to force component remount
  const [options, setOptions] = useState([]);

  const initialFormData = {
    templeName: "",
    description: "",
    devalayaType: "",
    profileImage: "",
    gopuramPhoto: "",
    geolocation: {
      type: "Point",
      coordinates: [],
    },
    address: {
      address1: "",
      address2: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
    },
  };

  const initialErrors = {
    templeName: "",
    description: "",
    devalayaType: "",
    profileImage: "",
    gopuramPhoto: "",
    address1: "",
    address2: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors); // State for errors

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const regex = /^[a-zA-Z\s]*$/;

    // Clear any existing error for the field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    if (name === "templeName") {
      if (!regex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          templeName: "Devalaya name should contain only letters",
        }));
        return;
      }
      if (value.length > 75) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          templeName: "Devalaya name cannot exceed 75 characters.",
        }));
        return;
      }
    }

    if (name === "lat" || name === "long") {
      const coordinates = [...formData.geolocation.coordinates];
      if (name === "lat") {
        coordinates[0] = parseFloat(value);
      } else {
        coordinates[1] = parseFloat(value);
      }

      setFormData({
        ...formData,
        geolocation: {
          ...formData.geolocation,
          coordinates,
        },
      });
    } else if (name in formData.address) {
      setFormData({
        ...formData,
        address: {
          ...formData.address,
          [name]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleOk = async () => {
    let hasError = false;

    console.log(formData);

    if (!formData.templeName) {
      setErrors((prev) => ({
        ...prev,
        templeName: "Temple name is required.",
      }));
      hasError = true;
    }
    if (!formData.description) {
      setErrors((prev) => ({
        ...prev,
        description: "Description is required.",
      }));
      hasError = true;
    }
    if (!formData.devalayaType) {
      setErrors((prev) => ({
        ...prev,
        devalayaType: "Devalaya type is required.",
      }));
      hasError = true;
    }
    if (!formData.profileImage) {
      setErrors((prev) => ({
        ...prev,
        profileImage: "Profile image is required.",
      }));
      hasError = true;
    }
    if (!formData.gopuramPhoto) {
      setErrors((prev) => ({
        ...prev,
        gopuramPhoto: "Gopuram photo is required.",
      }));
      hasError = true;
    }
    if (formData.geolocation.coordinates.length !== 2) {
      setErrors((prev) => ({
        ...prev,
        latitude: "Latitude is required.",
        longitude: "Longitude is required.",
      }));
      hasError = true;
    }
    if (!formData?.address?.address1) {
      setErrors((prev) => ({ ...prev, address1: "Address 1 is required." }));
      hasError = true;
    }
    if (!formData?.address?.address2) {
      setErrors((prev) => ({ ...prev, address2: "Address 2 is required." }));
      hasError = true;
    }
    if (!formData?.address?.pincode) {
      setErrors((prev) => ({ ...prev, pincode: "Pincode is required." }));
      hasError = true;
    }
    if (!formData?.address?.city) {
      setErrors((prev) => ({ ...prev, city: "City is required." }));
      hasError = true;
    }
    if (!formData?.address?.state) {
      setErrors((prev) => ({ ...prev, state: "State is required." }));
      hasError = true;
    }
    if (!formData?.address?.country) {
      setErrors((prev) => ({ ...prev, country: "Country is required." }));
      hasError = true;
    }

    if (hasError) {
      toast.error("Please fill in all fields.");
      return;
    }

    setIsModalOpen(false);
    try {
      await userRequest.post(`/master/createDevalaya`, formData);
      toast.success("Devalaya created successfully");
      resetForm();
    } catch (error) {
      console.log("Error creating Devalaya:", error);
      toast.error("Failed to create Devalaya");
    }
  };

  const handleCancel = () => {
    resetForm();
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setErrors(initialErrors); // Reset errors on form reset
    setIsModalOpen(false);
    setResetKey((prevKey) => prevKey + 1);
  };

  // Fetch options for Devalaya type
  const fetchData = async () => {
    try {
      const response = await userRequest.get(`/master/getMasterDevalayaType`);
      const fetchedOptions = response.data.data.map((item) => ({
        value: item._id,
        label: item.type,
      }));
      setOptions(fetchedOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDevalyaTypeSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      devalayaType: selectedOption.value,
    }));
  };

  return (
    <>
      <button className={styles.addNewBtn} onClick={showModal}>
        Add Devalaya
      </button>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Add Devalaya</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Devalaya Name *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="templeName"
                value={formData.templeName}
                onChange={handleChange}
                placeholder="Enter"
              />
              {errors.templeName && (
                <span className={styles.error}>{errors.templeName}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Description *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter"
              />
              {errors.description && (
                <span className={styles.error}>{errors.description}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Devalaya Type *</p>
            </div>
            <div className={styles.fieldInput}>
              <SelectComponent
                key={`devalayaType-${resetKey}`}
                options={options}
                onSelect={handleDevalyaTypeSelect}
              />
              {errors.devalayaType && (
                <span className={styles.error}>{errors.devalayaType}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Devalaya Profile Image *</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                key={`profileImage-${resetKey}`}
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, profileImage: file })
                }
                fieldName="profileImage"
              />
              {errors.profileImage && (
                <span className={styles.error}>{errors.profileImage}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Gopuram Photo *</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                key={`gopuramPhoto-${resetKey}`}
                label="Upload Media"
                onChange={(fieldName, file) =>
                  setFormData({ ...formData, gopuramPhoto: file })
                }
                fieldName="gopuramPhoto"
              />
              {errors.gopuramPhoto && (
                <span className={styles.error}>{errors.gopuramPhoto}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainerLat}>
            <div className={styles.itemLeft}>
              <div className={styles.fieldLabel}>
                <p>Address 1 *</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="address1"
                  value={formData?.address?.address1 || ""}
                  onChange={handleChange}
                  placeholder="Enter"
                />
                {errors?.address1 && (
                  <span className={styles.error}>{errors?.address1}</span>
                )}
              </div>
            </div>
            <div className={styles.itemRight}>
              <div className={styles.fieldLabel}>
                <p>Address 2 *</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="address2"
                  value={formData.address.address2 || ""}
                  onChange={handleChange}
                  placeholder="Enter"
                />
                {errors.address2 && (
                  <span className={styles.error}>{errors.address2}</span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainerLat}>
            <div className={styles.itemLeft}>
              <div className={styles.fieldLabel}>
                <p>Pincode *</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="pincode"
                  value={formData.address.pincode || ""}
                  onChange={handleChange}
                  placeholder="Enter"
                />
                {errors.pincode && (
                  <span className={styles.error}>{errors.pincode}</span>
                )}
              </div>
            </div>
            <div className={styles.itemRight}>
              <div className={styles.fieldLabel}>
                <p>City *</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="city"
                  value={formData.address.city || ""}
                  onChange={handleChange}
                  placeholder="Enter"
                />
                {errors.city && (
                  <span className={styles.error}>{errors.city}</span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainerLat}>
            <div className={styles.itemLeft}>
              <div className={styles.fieldLabel}>
                <p>State *</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="state"
                  value={formData.address.state || ""}
                  onChange={handleChange}
                  placeholder="Enter"
                />
                {errors.state && (
                  <span className={styles.error}>{errors.state}</span>
                )}
              </div>
            </div>
            <div className={styles.itemRight}>
              <div className={styles.fieldLabel}>
                <p>Country *</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="country"
                  value={formData.address.country || ""}
                  onChange={handleChange}
                  placeholder="Enter"
                />
                {errors.country && (
                  <span className={styles.error}>{errors.country}</span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainerLat}>
            <div className={styles.itemLeft}>
              <div className={styles.fieldLabel}>
                <p>Latitude *</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="number"
                  name="lat"
                  value={formData.geolocation.coordinates[0] || ""}
                  onChange={handleChange}
                  placeholder="Enter"
                />
                {errors.latitude && (
                  <span className={styles.error}>{errors.latitude}</span>
                )}
              </div>
            </div>
            <div className={styles.itemRight}>
              <div className={styles.fieldLabel}>
                <p>Longitude *</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="number"
                  name="long"
                  value={formData.geolocation.coordinates[1] || ""}
                  onChange={handleChange}
                  placeholder="Enter"
                />
                {errors.longitude && (
                  <span className={styles.error}>{errors.longitude}</span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Add</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddDevalaya;
