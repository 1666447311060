import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";

const AddLanguage = ({ fetchData }) => {
  const initialFormData = {
    language: "",
  };

  const apiEndpoint = "/master/createMasterLanguage";
  const formFields = [{ name: "language", label: "Language", type: "text" }];
  


  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Language"
    />
  );
};

export default AddLanguage;