// import React, { useEffect, useState } from "react";
// import styles from "./TermsAndPolices.module.css";
// import { useNavigate } from "react-router-dom";
// import { fileRequest } from "../../../requestMethod";
// import editIcon from "../../../assets/images/edit.png";
// import deleteIcon from "../../../assets/images/delete.png";
// import viewIcon from "../../../assets/images/viewIcons.png";
// import { Popconfirm, Spin, Switch } from "antd";
// import { getConfig } from "../../../utils/getConfig";
// import useDebounce from "../../../utils/useDebounce";
// import PageHeader from "../../../components/PageHeader/PageHeader";

// function TermsAndPolicies({staticsType}) {
//   const limit = 10;
//   const { BASE_URL } = getConfig();
//   const navigate = useNavigate();
//   const [data, setData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const debouncedSearchQuery = useDebounce(searchQuery, 1000);
//   const [page, setPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [loading, setLoading] = useState(true);
//   const [expandedContent, setExpandedContent] = useState({});

//   const handleNext = () => {
//     if (page < totalPages) {
//       setPage(page + 1);
//     }
//   };

//   const handlePrevious = () => {
//     if (page > 1) {
//       setPage(page - 1);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [debouncedSearchQuery, page, currentPage , staticsType]);

//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       const response = await fileRequest.get(
//         `/static/getAllStaticContent?type=${staticsType}&search=${debouncedSearchQuery}&page=${page}&limit=${limit}`
//       );
//       setData(response?.data?.data);
//       setTotalPages(response?.data?.pagination?.totalPages);
//       setCurrentPage(response?.data?.pagination?.currentPage);
//       setPage(response?.data?.pagination?.currentPage);
//     } catch (error) {
//       console.log("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       await fileRequest.put(`/static/updateStaticContent/${id}`, {
//         deleted: true,
//       });
//       fetchData();
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

//   const stripHtml = (html) => {
//     const tempDiv = document.createElement("div");
//     tempDiv.innerHTML = html;
//     return tempDiv.textContent || tempDiv.innerText || "";
//   };

//   const renderContent = (content) => {
//     const plainContent = stripHtml(content);
//     return (
//       <div>
//         <span
//         >
//          {plainContent.substring(0, 20)}...
//         </span>

//       </div>
//     );
//   };
//   const handleAddNew = ()=>{
//     staticsType ==="termsAndConditions" ?   navigate("/staticcontents/termsandconditions/create",{state:{type:staticsType}}) :  navigate("/staticcontents/privacypolicy/create",{state:{type:staticsType}})
//   }

//   const handleView = (id , item)=>{
//     staticsType ==="termsAndConditions" ?
//     navigate(`/staticcontents/termsandconditions/view/${id}`, {
//       state: { item },
//     }):
//     navigate(`/staticcontents/privacypolicy/view/${id}`, {
//       state: { item },
//     })

//   }

//   const handleEdit = (id , item)=>{
//     staticsType ==="termsAndConditions" ?
//     navigate(`/staticcontents/termsandconditions/edit/${id}`, {
//       state: { item },
//     }):
//     navigate(`/staticcontents/privacypolicy/edit/${id}`, {
//       state: { item },
//     })

//   }

//   return (
//     <Spin spinning={loading} size="small">
//          <PageHeader
//         breadcrumbs={[
//           { label:  staticsType ==="termsAndConditions" ?   "Terms & Conditions" : "Privacy Policy",
//              path: staticsType ==="termsAndConditions" ?   "/staticcontents/termsandconditions" :"/staticcontents/privacypolicy" },
//         ]}
//         buttonLabel="Add New"
//         onButtonClick={() => handleAddNew()

//         }
//       />
//           <div className={styles.termAndCPoliciesContainer}>

//       <div className={styles.tableMain}>
//         <div className={styles.filterExport}>
//           <div className={styles.search}>
//             <input
//               type="text"
//               onChange={(e) => setSearchQuery(e.target.value)}
//               value={searchQuery}
//             />
//           </div>
//           {/* <div className={styles.AddNew} onClick={handleAddNew}>Add New</div> */}
//         </div>

//         <div className={styles.rolesTableMain}>
//           <table>
//             <thead>
//               <tr>
//                 <th>Section</th>
//                 {/* {staticsType === "termsAndConditions" ? <th>Language</th> : ""} */}
//                 <th>Language</th>
//                 <th>Content</th>
//                 <th>Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.length > 0 ? (
//                 data.map((item) => (
//                   <tr key={item._id}>
//                     <td>{item?.section}</td>
//                 {/* {staticsType === "termsAndConditions" ?  <td>English</td>: ""} */}
//               <td>{item?.language}</td>

//                     <td>{renderContent(item?.content)}</td>
//                     <td className={styles.actionTd}>
//                       <img
//                         src={viewIcon}
//                         alt="view"
//                         onClick={() => handleView(item?._id , item)

//                         }
//                       />
//                       <img
//                         src={editIcon}
//                         alt="edit"
//                         onClick={() =>
//                           handleEdit(item?._id , item)
//                         }
//                       />
//                       <Popconfirm
//                         title={`Are you sure to delete ${item?.title}?`}
//                         onConfirm={() => handleDelete(item?._id)}
//                         okText="Yes"
//                         cancelText="No"
//                         overlayClassName={styles.largePopconfirm}
//                       >
//                         <img src={deleteIcon} alt="delete" />
//                       </Popconfirm>

//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan="3">No data found</td>
//                 </tr>
//               )}
//             </tbody>
//           </table>
//         </div>
//         {data.length > 0 && (
//           <div className={styles.footer}>
//             <div
//               className={styles.action}
//               style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
//             >
//               <button
//                 className={`${styles.actionButton} ${page === 1 ? styles.disabled : styles.activeButton}`}
//                 onClick={handlePrevious}
//                 disabled={page === 1}
//               >
//                 Previous
//               </button>
//               <button
//                 className={`${styles.actionButton} ${page === totalPages ? styles.disabled : styles.activeButton}`}
//                 onClick={handleNext}
//                 disabled={page === totalPages}
//               >
//                 Next
//               </button>
//             </div>
//             <div className={styles.page}>
//               <span>{currentPage}</span> <span>of</span> <span>{totalPages}</span>
//             </div>
//           </div>
//         )}
//       </div>
//           </div>
//     </Spin>
//   );
// }

// export default TermsAndPolicies;

import React, { useEffect, useState } from "react";
import styles from "./TermsAndPolices.module.css";
import { userRequest } from "../../../requestMethod";
import useDebounce from "../../../utils/useDebounce";
import TableComponent from "../../../components/TableComponent/TableComponent";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../components/PageHeader/PageHeader";
import viewIcon from "../../../assets/images/viewIcons.png";
import editIcon from "../../../assets/images/edit.png";
import deleteIcon from "../../../assets/images/delete.png";
import { Popconfirm, Spin, Switch } from "antd";

function TermsAndPolicies({ staticsType }) {
  const limit = 10;
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedReason, setSelectedReason] = useState("");
  const [reasonModalVisible, setReasonModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, staticsType]);
  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/admin/static/getAllStaticContent?type=${staticsType}&search=${debouncedSearchQuery}&page=${page}&limit=${limit}`
      );
      setData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setCurrentPage(response?.data?.pagination?.currentPage);
      setPage(response?.data?.pagination?.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const stripHtml = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const renderContent = (content) => {
    const plainContent = stripHtml(content);
    return (
      <div>
        <span>{plainContent.substring(0, 20)}...</span>
      </div>
    );
  };
  const handleView = (id, item) => {
    staticsType === "termsAndConditions"
      ? navigate(`/staticcontents/termsandconditions/view/${id}`, {
          state: { item },
        })
      : navigate(`/staticcontents/privacypolicy/view/${id}`, {
          state: { item },
        });
  };
  const handleEdit = (id, item) => {
    staticsType === "termsAndConditions"
      ? navigate(`/staticcontents/termsandconditions/edit/${id}`, {
          state: { item },
        })
      : navigate(`/staticcontents/privacypolicy/edit/${id}`, {
          state: { item },
        });
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.put(`/admin/static/updateStaticContent/${id}`, {
        deleted: true,
      });
      fetchData();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };
  const handleChange = async (id, activeStatus) => {
    try {
   
      const newActiveStatus = !activeStatus;
 
        await userRequest.put(`/admin/static/updateStaticContent/${id}`, {
          is_active: newActiveStatus,
        });
        fetchData();
      
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };
  const columns = [
    {
      title: "S.No.",
      key: "serialNumber",
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Section",
      key: "section",
      render: (item) => `${item?.section}`,
    },
    {
      title: "Language",
      key: "language",
      render: (item) => `${item?.language}`,
    },

    {
      title: "Content",
      key: "content",
      render: (item) => renderContent(item?.content),
    },

  ];

  const customWidths = {
    serialNumber: "8vw",
    section: "20vw",
    language: "20vw",
    content: "10vw",
   
  };

  const handleAddNew = () => {
    staticsType === "termsAndConditions"
      ? navigate("/staticcontents/termsandconditions/create", {
          state: { type: staticsType },
        })
      : navigate("/staticcontents/privacypolicy/create", {
          state: { type: staticsType },
        });
  };

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            label:
              staticsType === "termsAndConditions"
                ? "Terms & Conditions"
                : "Privacy Policy",
            path:
              staticsType === "termsAndConditions"
                ? "/staticcontents/termsandconditions"
                : "/staticcontents/privacypolicy",
          },
        ]}
        buttonLabel="Add New"
        onButtonClick={() => handleAddNew()}
      />
      <div className={styles.contentContainer}>
        {/* <SearchFilterComponent setSearchQuery={setSearchQuery} /> */}
        <div className={styles.staticsContentSearch}>
            <input
              type="text"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </div>

        <TableComponent
          columns={columns}
          data={data}
          loading={loading}
          onDelete={() => {}}
          currentPage={currentPage}
          onStatusChange={handleChange}
          totalPages={totalPages}
          onNext={handleNext}
          onPrevious={handlePrevious}
          customWidths={customWidths}
          actions={[

            (props) => (
              <img
                className={styles.viewDetails}
                src={viewIcon}
                alt="view"
                onClick={() => handleView(props?.item?._id, props.item)}
              />
            ),
            (props) => (
              <img
                className={styles.editDetails}
                src={editIcon}
                alt="edit"
                onClick={() => handleEdit(props?.item?._id, props.item)}
              />
            ),
            (props) => (
              <Popconfirm
                title={`Are you sure to delete ${props?.item?.section}?`}
                onConfirm={() => handleDelete(props?.item?._id)}
                okText="Yes"
                cancelText="No"
                overlayClassName={styles.largePopconfirm}
              >
                <img src={deleteIcon} alt="delete" />
              </Popconfirm>
            ),
            (props) => (
              <Popconfirm
              title={`Are you sure to ${
                props?.item?.is_active ? "deactivate" : "activate"
              } ${props?.item?.section}`}
              onConfirm={() => handleChange(props?.item?._id, props?.item?.is_active )}
              okText="Yes"
              cancelText="No"
            >
              <Switch size="small" checked={props?.item?.is_active} />
            </Popconfirm>
            ),
          ]}
        />
      </div>
    </>
  );
}

export default TermsAndPolicies;
