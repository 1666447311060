import React, { useState } from "react";
import styles from "./CreateFaqCategory.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

import PageHeaderTwo from "../../../../../components/PageHeader/PageHeaderTwo";
import {userRequest } from "../../../../../requestMethod";

function CreateFaqCategory() {
  const navigate = useNavigate();
  const initialFormData = {
    name: "",
    description: "",
  
  };

  const [formData, setFormData] = useState(initialFormData);



  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveClick = async () => {
    if (!formData.name || formData?.name?.trim()==="") {
      toast.error("category is required and cannot be empty");
      return
    }

    if (!formData.description || formData?.description?.trim()==="") {
      toast.error("description  is required and cannot be empty");
      return
    }
      try {
        await userRequest.post("/admin/static/createCategory", formData);
        setFormData(initialFormData);
        navigate("/staticcontents/faq");
        toast.success("Saved successfully!");
      } catch (error) {
        console.error("Error saving data:", error.message);
        toast.error("Error saving data");
      }
    
  };



 
  const handleDiscard = () => {
    navigate("/staticcontents/faq");
  };

  const breadcrumb = [
    { label: "Faq Category", path: "/staticcontents/faq" },
    { label: "Create Faq Category", path: "/staticcontents/faq/createFaqCategory" },
  ];

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={handleDiscard}
        onSaveClick={handleSaveClick}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.createContainer}>
           
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                  <p>Category</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter"
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
               
                <div className={styles.fieldLabel}>
                  <p>Description</p>
                </div>
                <div className={styles.fieldInput}>
                  <textarea
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Enter"
                  />
                </div>
              </div>
            </div>
            
          
            <div className={styles.footer}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateFaqCategory;
