import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { adminResetPassword } from "../../../features/actions/adminAction";
import { resetAdminStatus } from "../../../features/slices/adminSlice";
import "./ResetPassword.css";
import logo from "../../../assets/images/Layer_1.png";
import CarouselComponent from "../../../components/carousel/carouselComponent";

function ResetPassword() {
  const dispatch = useDispatch();
  const { isSuccess } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const {token} = useParams()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = (data) => {
    let payload = data;
    dispatch(adminResetPassword({ payload , token}));
  };

  useEffect(() => {
    if (isSuccess === true) {
      navigate("/");
      dispatch(resetAdminStatus(false));
    }
  }, [isSuccess]);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-6 d-flex justify-content-center  formColbg  resetpass" >
          <div className="">
            <div className="col-sm-6 w-100 d-flex justify-content-center  ">

             <img className="login-logo " src={logo} />
            </div>
            <div className="col-sm-6 w-100 textDiv">

            <h2 className="login-form-heading d-flex justify-content-center mt-2">
              <strong>Reset New Password</strong>
            </h2>
            

            <p className="login-form-paragragh d-flex justify-content-center">Lorem Ipsum has been the industry's </p>
            <p className="login-form-paragragh d-flex justify-content-center">Lorem Ipsum Ipsum is simply   </p>
          
            
            </div>
            <div className="col-sm-6 my-2  loginWidth"  >
            <form className="login-form " onSubmit={handleSubmit(onSubmit)}>
              <div class="mb-3 ">
                <div>
                  <label for="formGroupExampleInput" className="label">
                  Set Password
                  </label>
                </div>
                <div className="email-input-div">
                  <input
                    type="password"
                    className="inputField"
                    id="formGroupExampleInput"
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Password is required",
                      },
                    })}
                    placeholder="Password"
                  />
                  {errors?.password && (
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors?.password?.message}
                    </p>
                  )}
                </div>
              </div>
              <div class="mb-3">
                <div className="password-label-div">
                  <label for="formGroupExampleInput2" className="label">
                  Confirm Password
                  </label>
                </div>
                <div className="password-input-div">
                  <input
                    type="password"
                    className="inputField"
                    id="formGroupExampleInput2"
                    {...register("confirmPassword", {
                      required: {
                        value: true,
                        message: "Confirm Password is required",
                      },
                    })}
                    placeholder="Confirm Password"
                  />
                  {errors?.confirmPassword && (
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors?.confirmPassword?.message}
                    </p>
                  )}
                </div>
              </div>
           
              <div>
                <button
                 
                  type="submit"
                  className="login-button my-4"
                >
                  <strong>Create</strong>
                </button>
              </div>
            </form>
            </div>
           
          </div>
        </div>
     
        <div className="col-sm-6 d-flex align-items-center justify-content-center ">

         <CarouselComponent/>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;