import React from "react";
import styles from "./utsavManagement.module.css";
import UtsavFacilities from "./UtsavFacilities/UtsavFacilities";
import KaryakramamType from "./KaryakramamType/KaryakramamType";
import UtsavType from "./UtsavType/UtsavType";
import UtsavAttraction from "./UtsavAttraction/UtsavAttraction";
import PageHeader from "../../../components/PageHeader/PageHeader";
import MenuItems from "../../../components/MenuItems/MenuItems";

function UtsavManagement() {
  const menuItems = [
    { label: "Utsav Type", state: "UtsavType" },
    { label: "Utsav Facilities", state: "UtsavFacilities" },
    { label: "Utsav Attractions", state: "UtsavAttractions" },
    { label: "Karyakramam Type", state: "KaryakramamType" },
  ];

  const contentMap = {
    UtsavType: <UtsavType />,
    UtsavFacilities: <UtsavFacilities />,
    UtsavAttractions: <UtsavAttraction />,
    KaryakramamType: <KaryakramamType />,
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Master Data", path: "/master/utsavmanagement" },
          { label: "Utsav", path: "/master/utsavmanagement" },
        ]}
      />
      <MenuItems menuItems={menuItems} contentMap={contentMap} />
    </div>
  );
}

export default UtsavManagement;
