import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";


const ViewPanditKyc = ({ item }) => {

    const initialFormData = {
      type: item?.type?.type,
      question: item?.question,
  };

  const formFields = [
    { name: "type", label: "Pandit Type", type: "text" },
    { name: "question", label: "Question", type: "text" },
  ];

  return (
    <ViewItem
      formFields={formFields}
      title="View Pandit Kyc"
      item={initialFormData}
    />
  );
};

export default ViewPanditKyc;


