import React, { useEffect, useState } from "react";
import styles from "./Details.module.css";
import { useNavigate, useParams } from "react-router-dom";
import image from "../../../assets/images/logo.png";
import emailIcon from "../../../assets/images/email.png";
import phoneIcon from "../../../assets/images/phone.png";
import { userRequest } from "../../../requestMethod";
import verifiedIcon from "../../../assets/images/verified.png";
import AddFamilyMember from "../AddFamilyMember/AddFamilyMember";
import PageHeader from "../../../components/PageHeader/PageHeader";

function UserDetails() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const response = await userRequest.get(
        `/userManagement/getSingleUserManagement?id=${userId}`
      );
      setUserData(response.data.data);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };
  useEffect(() => {
    fetchUserDetails();
  }, [userId]);

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "User Management", path: "/usermanagement" },
          { label: "Profile Details", path: "#" },
        ]}
      />
      {userData && userData.basicInfoObj && (
        <div className={styles.contentContainer}>
          <div className={styles.basicInfo}>
            <div className={styles.basicInfoSectionLeft}>
              <div className={styles.profilePic}>
                <p>Basic Info</p>
                <img src={image} alt="" />
              </div>
              <div className={styles.leftBasicInfo}>
                <div className={styles.singleBasicInfo}>
                  <p>Full Name</p>
                  <p>
                    {userData?.basicInfoObj?.firstName || "NA"}{" "}
                    {userData?.basicInfoObj?.lastName || "NA"}
                  </p>
                </div>
                <div className={styles.singleBasicInfo}>
                  <p>Join Date</p>
                  <p>
                    {new Date(
                      userData.basicInfoObj.createdAt
                    ).toLocaleDateString() || "NA"}{" "}
                  </p>
                </div>
                <div className={styles.singleBasicInfo}>
                  <p>Nominated By</p>
                  <p>Tej Verma</p>
                </div>
                <div className={styles.singleBasicInfo}>
                  <p>Lived In</p>
                  <p>
                    {userData.basicInfoObj.livedIn || "Place not available"}
                  </p>
                </div>
                <div className={styles.singleBasicInfo}>
                  <div className={styles.email}>
                    <img src={emailIcon} alt="" />
                    <p>
                      {userData?.basicInfoObj?.email || "Email not available"}
                    </p>
                    <img
                      style={{ width: "7%" }}
                      src={
                        userData?.basicInfoObj?.emailVerified === true
                          ? verifiedIcon
                          : ""
                      }
                      alt=""
                    />
                  </div>
                  <div className={styles.phone}>
                    <img src={phoneIcon} alt="" />
                    <p>
                      {userData.basicInfoObj.mobile || "Mobile not available"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.basicInfoSectionRight}>
              <p>Personal Information</p>
              <div className={styles.singleBasicInfo}>
                <p>Date of Birth</p>
                <p>
                  {new Date(
                    userData.personalInfoObj.dob
                  ).toLocaleDateString() || "DOB not available"}
                </p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Birth Time</p>
                <p>
                  {userData.personalInfoObj.birthTime ||
                    "Birth time not available"}
                </p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Raasi</p>
                <p>
                  {userData?.personalInfoObj?.raasi?.name ||
                    "Raasi not available"}
                </p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Nakshatram</p>
                <p>
                  {userData?.personalInfoObj?.nakshatram?.name ||
                    "Nakshatram not available"}
                </p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Gothram</p>
                <p>
                  {userData?.personalInfoObj?.gothram ||
                    "Gothram not available"}
                </p>
              </div>
              <div className={styles.singleBasicInfo}>
                <p>Relationship Status</p>
                <p>
                  {userData?.personalInfoObj?.relationshipStatus?.name ||
                    "Relationship Status not available"}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.bioContent}>
            <p>Bio</p>
            <p>{userData?.bio || "Bio not available"}</p>
          </div>
          <div className={styles.personalDetails}>
            <div className={styles.abhiruchi}>
              <p>Abhiruchi</p>
              <div className={styles.userDetailBoxContainer}>
                <div className={styles.abhiruchiRow}>
                  {userData?.abhiruchi.length > 0 ? (
                    userData?.abhiruchi.map((interest, index) => (
                      <p key={index}>{interest.name}</p>
                    ))
                  ) : (
                    <div>Abhiruchi not available</div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.placesLived}>
              <p>Places Lived</p>
              <div className={styles.placesLivedSingle}>
                <p>Current City</p>
                <p>{userData?.placesLived?.currentCity || "Not available"}</p>
              </div>
              <div className={styles.placesLivedSingle}>
                <p>Home Town</p>
                <p>{userData?.placesLived?.homeTown || "Not available"}</p>
              </div>
              <div className={styles.placesLivedSingle}>
                <p>Other Cities</p>
                {userData?.placesLived &&
                userData?.placesLived?.otherCity.length > 0 ? (
                  userData?.placesLived?.otherCity.map((city, index) => (
                    <p key={index}>{city}</p>
                  ))
                ) : (
                  <p>Not available</p>
                )}
              </div>
            </div>
            <div className={styles.familyMembers}>
              <div className={styles.familyMembersHeading}>
                <p>Family Members</p>
                <AddFamilyMember
                  userId={userId}
                  fetchUserDetails={fetchUserDetails}
                />
              </div>
              <div className={styles.userDetailBoxContainer}>
                {userData?.familyMemberObj &&
                userData?.familyMemberObj.length > 0 ? (
                  userData?.familyMemberObj.map((member, index) => (
                    <div className={styles.singleMember} key={index}>
                      <p>{member?.name}</p>
                      <p>{member?.relationship?.name}</p>
                    </div>
                  ))
                ) : (
                  <span>Family members info not available</span>
                )}
              </div>
            </div>

            <div className={styles.education}>
              <p>Education</p>
              <div className={styles.userDetailBoxContainer}>
                {userData?.educationObj.length > 0 ? (
                  userData?.educationObj.map((education, index) => (
                    <div className={styles.educationRow} key={index}>
                      <p>{education?.degree?.name}</p>
                      <p>{education?.specialization}</p>
                      <p>
                        {new Date(education?.startDate).toLocaleDateString()} to{" "}
                        {new Date(education?.endDate).toLocaleDateString()}
                      </p>
                    </div>
                  ))
                ) : (
                  <span>Education info not available</span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.personalDetails}>
            <div className={styles.work}>
              <p>Work</p>
              <div className={styles.userDetailBoxContainer}>
                {userData?.workObj.length > 0 ? (
                  userData?.workObj.map((work, index) => (
                    <div className={styles.workRow} key={index}>
                      <p>{work?.workingSector?.workingSector}</p>
                      <p>{work?.jobTitle}</p>
                      <p>{new Date(work.startDate).toLocaleDateString()}</p>
                      {/* {work.endDate && <p>End Date: {new Date(work.endDate).toLocaleDateString()}</p>} */}
                    </div>
                  ))
                ) : (
                  <span>Work info not available</span>
                )}
              </div>
            </div>
            <div className={styles.daiva}>
              <div className={styles.singleDaivaRow}>
                <p>Ishta Daiva</p>
                <div className={styles.singleDaivaContainer}>
                  {userData?.ishtaDaivaArr.length > 0 ? (
                    userData?.ishtaDaivaArr.map((daiva, index) => (
                      <div className={styles.singleDaiva} key={index}>
                        <img src={daiva?.daivaPic} alt="" />
                        <p>{daiva?.daivaName}</p>
                      </div>
                    ))
                  ) : (
                    <span>Ishta daiva not available</span>
                  )}
                </div>
              </div>
              <div className={styles.singleDaivaRow}>
                <p>Grama Devata</p>
                <div className={styles.singleDaivaContainer}>
                  {userData?.gramaDevataArr.length > 0 ? (
                    userData?.gramaDevataArr.map((daiva, index) => (
                      <div className={styles.singleDaiva} key={index}>
                        <img src={daiva?.profileImage} alt="" />
                        <p>{daiva?.deityName}</p>
                      </div>
                    ))
                  ) : (
                    <span>Grama devata not available</span>
                  )}
                </div>
              </div>
              <div className={styles.singleDaivaRow}>
                <p>Kula Dhaivam</p>
                <div className={styles.singleDaivaContainer}>
                  {userData?.kulaDhaivamArr.length > 0 ? (
                    userData?.kulaDhaivamArr.map((daiva, index) => (
                      <div className={styles.singleDaiva} key={index}>
                        <img src={daiva?.profileImage} alt="" />
                        <p>{daiva?.deityName}</p>
                      </div>
                    ))
                  ) : (
                    <span>Kula dhaivam not available</span>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.assistedBy}>
              <p>Assisted by</p>
              <div className={styles.assistedByRow}>
                <div className={styles.assistedByProfile}>
                  <img src={image} alt="" />
                </div>
                <div className={styles.assistedByDetails}>
                  <p>
                    {userData?.assistedByObj[0]?.firstName ||
                      "Name not available"}
                  </p>
                  <p>
                    {userData?.assistedByObj[0]?.mobile ||
                      "Mobile no. not available"}
                  </p>
                </div>
              </div>
              <div className={styles.assistedByRowTwo}>
                <div className={styles.assistedByDetailsTwo}>
                  <img src={emailIcon} alt="" />
                  <p>
                    {userData?.assistedByObj[0]?.email || "Email not available"}
                  </p>
                </div>
                <div className={styles.assistedByDetailsTwo}>
                  <img src={phoneIcon} alt="" />
                  <p>
                    {userData?.assistedByObj[0]?.mobile ||
                      "Mobile no. not available"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserDetails;
