import React, { useEffect, useState } from "react";
import styles from "./masterRules.module.css";
import { useNavigate } from "react-router-dom";
import { Popconfirm, Spin } from "antd";
import { userRequest } from "../../../../requestMethod.js";
import deleteIcon from "../../../../assets/images/deleteIcon.png";
import AddRules from "./AddRules/AddRules.jsx";
import ViewRules from "./ViewRules/ViewRules.jsx";
import EditRules from "./EditRules/EditRules.jsx";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent.jsx";
import useDebounce from "../../../../utils/useDebounce.js";


function Rules() {
  const [utsavData, setUtsavData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(`/master/getAdminMasterRules?search=${debouncedSearchQuery}&page=${page}`);
      setUtsavData(response.data.data);
      setTotalPages(response.data.pagination.totalPages)
      setCurrentPage(response.data.pagination.currentPage)
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.delete(`/master/deleteAdminMasterRules/${id}`);
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    console.log("Export clicked");
  };

  return (
    <div className={styles.main}>
      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
        addComponent={() => <AddRules fetchData={fetchData} />}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <Spin spinning={loading} size="small">
            <div className={styles.tableContainerRule}>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Name</th>
                    <th>Rules</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {utsavData.length > 0 ? (
                    utsavData.map((task, index) => (
                      <tr key={task?._id}>
                        <td>{index + 1}</td>
                        <td>{task?.name}</td>
                        <td>{task?.rules}</td>
                        <td className={styles.actionTd}>
                          <ViewRules item={task} />
                          <EditRules fetchData={fetchData} item={task} />
                        {/*  <Popconfirm
                            title={`Are you sure to delete ${task?.name}?`}
                            onConfirm={() => handleDelete(task?._id)}
                            okText="Yes"
                            cancelText="No"
                            overlayClassName={styles.largePopconfirm}
                          >
                            <img src={deleteIcon} alt="" />
                          </Popconfirm>*/}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {utsavData.length > 0 && (
                <div className={styles.footer}>
                  <div
                    className={styles.action}
                    style={{
                      visibility: totalPages === 1 ? "hidden" : "visible",
                    }}
                  >
                    <button
                      className={`${styles.actionButton} ${
                        page === 1 ? styles.disabled : styles.activeButton
                      }`}
                      onClick={handlePrevious}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                    <button
                      className={`${styles.actionButton} ${
                        page === totalPages
                          ? styles.disabled
                          : styles.activeButton
                      }`}
                      onClick={handleNext}
                      disabled={page === totalPages}
                    >
                      Next
                    </button>
                  </div>
                  <div className={styles.page}>
                    <span>{currentPage}</span> <span>of</span>{" "}
                    <span>{totalPages}</span>
                  </div>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default Rules;
