import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";
import { userRequest } from "../../../../../requestMethod";

const EditState = ({ item, fetchData }) => {
  const initialFormData = {
    stateId: item?.stateId,
    state: item?.state,
    countryId: item?.countryId
  };


  const fetchCountriesData = async () => {
    try {
      const response = await userRequest.get(`/master/getMasterCountry`);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const apiEndpoint = "/master/updateMasterState";
  const formFields = [
    { name: "stateId", label: "State Id", type: "text" },
    { name: "state", label: "State Name", type: "text" },
    {
      name: "countryId",
      label: "Country Name",
      type: "select",
      optionsKey: "countryId",
      optionValueKey: "_id",
      optionLabelKey: "country",
    },
  ];

  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="State"
      item={item}
      fetchDataOptions={{
        countryId: fetchCountriesData,
      }}
    />
  );
};

export default EditState;
