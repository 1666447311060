import React, { useEffect, useState } from "react";
import styles from "./viewKYCPrice.module.css";
import { userRequest } from "../../../../requestMethod";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../../../components/TableComponent/TableComponent";


function KYCAmount({ kycData, setKycData, country, state }) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const limit = 10;

  const columns = [
    { title: "S.no.", key: "index", render: (item, index) => index + 1 },
    { title: "Country", key: "country" },
    { title: "State", key: "state" },
    { title: "KYC Price", key: "kycPrice" },
    { title: "Discount Type", key: "DiscountType" },
    { title: "Discount Amount", key: "discountAmount" },
    { title: "Start Date", key: "startDate", render: (item) => formatDate(item.startDate) },
    { title: "End Date", key: "expiryDate", render: (item) => formatDate(item.expiryDate) },
    {
      title: "Status",
      key: "status",
      render: (item) => (
        <span
          className={styles.tableviewDetails}
          onClick={() => navigate(`/panditkycmanagement/viewkycpricedetails/${item._id}`)}
        >
          View
        </span>
      ),
    },
  ];

  const customWidths = {
    index: "10vw",
    country: "20vw",
    state: "20vw",
    kycPrice: "15vw",
    DiscountType: "20vw",
    discountAmount: "20vw",
    startDate: "15vw",
    expiryDate: "15vw",
    status: "15vw",
  };

  useEffect(() => {
    fetchData();
  }, [country, state, page]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await userRequest.get(
        `admin/adminPanditKycRoutes/getAdminKycPrice?country=${country}&state=${state}&page=${page}&limit=${limit}`
      );
      setKycData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableMain}>
        <TableComponent
          columns={columns}
          data={kycData}
          customWidths={customWidths}
          loading={loading}
          currentPage={page}
          totalPages={totalPages}
          onNext={() => setPage((prev) => Math.min(prev + 1, totalPages))}
          onPrevious={() => setPage((prev) => Math.max(prev - 1, 1))}
        />
      </div>
    </div>
  );
}

export default KYCAmount;

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
}
