import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";


const ViewCity = ({ item,fetchData }) => {

    const initialFormData = {
      cityId: item?.cityId,
      stateId: item?.stateId?.state,
      city: item?.city,
      latitude: item?.latitude,
      longitude: item?.longitude,
  };

  const formFields = [
    { name: "cityId", label: "City Id", type: "text" },
    { name: "city", label: "City Name", type: "text" },
    { name: "stateId", label: "State Name", type: "text" },
    { name: "latitude", label: "latitude", type: "text" },
    { name: "longitude", label: "longitude", type: "text" },
  ];

  return (
    <ViewItem
      fetchData={fetchData}
      initialFormData={initialFormData}
      formFields={formFields}
      title="View City"
      item={item}
    />
  );
};

export default ViewCity;


