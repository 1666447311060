import React, { useEffect, useRef, useState } from "react";
import styles from "./CreateCampaign.module.css";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../../components/SelectComponent";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import Stepper from "awesome-react-stepper";
import { Spin } from "antd";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";
import LocationSearchInput from "../../../../components/LocationSearch/LocationSearchInput";

function CreateCampaign() {
  const navigate = useNavigate();
  const [rules, setRules] = useState([""]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [levelAdded, setLevelAdded] = useState(false);
  const [taskAdded, setTaskAdded] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [rolesType, setRolesType] = useState([]);
  const [errors, setErrors] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTaskIndex, setActiveTaskIndex] = useState(null);
  const [scope, setScope] = useState("");
  const [validationCity, setValidationCity] = useState();
  const [validationState, setValidationState] = useState();
  const [selectedCoins, setSelectedCoins] = useState({});
  const [selectedTaskType, setSelectedTaskType] = useState({});
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleTaskAccordion = (index) => {
    setActiveTaskIndex(activeTaskIndex === index ? null : index);
  };

  const initialCampaignDetails = {
    campaignTitle: "",
    NoOfLevels: 0,
    Duration: {
      startDate: "",
      endDate: "",
    },
    roleId: "",
    country: "",
    state: "",
    city: "",
    rule: [],
    maxTejCoins: "",
    maxLakshmiCoins: "",
    termsAndConditions: "",
    status: "Draft",
    levelsData: [],
    geographicalScope: "",
    geolocation: [],
  };
  const [selectedCountries, setSelectedCountries] = useState([]);

  const geographicalScopes = [
    "Local",
    "Regional",
    "National",
    "International",
    "Global",
  ];

  const geographicalScopeOptions = geographicalScopes.map((geo) => ({
    value: geo,
    label: geo,
  }));

  const [campaignDetails, setCampaignDetails] = useState(
    initialCampaignDetails
  );
  useEffect(() => {
    const fetchTaskTypes = async () => {
      try {
        const response = await userRequest.get("/master/getMasterTaskType");
        setTaskTypes(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchTaskTypes();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await userRequest.get("/userRoles/getAllUserRoles");
        setRolesType(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchRoles();
  }, []);

  const handleAddRule = () => {
    setRules([...rules, ""]);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      rule: [...prevDetails.rule, ""],
    }));
  };

  const handleRemoveRule = (index) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList.splice(index, 1);
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleRuleChange = (index, event) => {
    const newRules = [...rules];
    newRules[index] = event.target.value;
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList[index] = event.target.value;
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleAddLevel = () => {
    setLevelAdded(true);
    setCampaignDetails((prevState) => ({
      ...prevState,
      levelsData: [
        ...prevState.levelsData,
        {
          levelName: "",
          levelDescription: "",
          totalTasks: 0,
          maxCoins: 0,
          coins: "",
          tasksData: [],
        },
      ],
      NoOfLevels: prevState.levelsData.length + 1,
    }));

    setTimeout(() => {
      setLevelAdded(false);
    }, 1000);
  };

  const handleRemoveLevel = (index) => {
    setCampaignDetails((prevState) => {
      const updatedLevelsData = prevState.levelsData.filter(
        (_, i) => i !== index
      );
      return {
        ...prevState,
        levelsData: updatedLevelsData,
        NoOfLevels: updatedLevelsData.length,
      };
    });
  };

  const handleAddTask = (levelIndex) => {
    setTaskAdded(true);
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].totalTasks =
        newLevelsData[levelIndex].tasksData.length + 1;
      newLevelsData[levelIndex].tasksData.push({
        taskName: "",
        taskDescription: "",
        coinValue: 0,
        taskCount: 1,
        operationId: "",
        taskType: "",
        numberOfDays: 0,
      });
      return {
        ...prevState,
        levelsData: newLevelsData,
      };
    });
    setTimeout(() => {
      setTaskAdded(false);
    }, 1000);
  };

  const handleRemoveTask = (levelIndex, taskIndex) => {
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].tasksData.splice(taskIndex, 1);
      return {
        ...prevState,
        levelsData: newLevelsData,
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getMinEndDate = () => {
    const baseDate = startDate ? new Date(startDate) : new Date();
    baseDate.setDate(baseDate.getDate() + 1);
    return baseDate.toISOString().split("T")[0];
  };
  const handleDurationChange = (event, Inputname) => {
    if (Inputname == "startDate") {
      setStartDate(event.target.value);
      setEndDate("");
    }
    if (Inputname == "endDate") {
      setEndDate(event.target.value);
    }
    const { name, value } = event.target;
    setCampaignDetails((prevState) => ({
      ...prevState,
      Duration: {
        ...prevState.Duration,
        [name]: value,
      },
    }));
  };

  const roleOptions = rolesType.map((role) => ({
    label: role.roleName,
    value: role._id,
  }));

  const coinOptions = [
    { value: "Tej", label: "Tej" },
    { value: "Lakshmi", label: "Lakshmi" },
  ];

  const handleRoleSelect = (option) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      roleId: option.value,
    }));
  };

  const handleScopeSelect = (option) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      geographicalScope: option.value,
    }));
    setScope(option.value);
  };

  const selectedGeographicalScope = scope;

  const handleLocationChange = (location) => {
    const {
      country,
      state,
      city,
      coordinates,
      placeId,
      fullAddress,
      country_code,
      state_code,
    } = location;
    setValidationState(state);
    setValidationCity(city);
    const updatedGeoLocation = [
      // ...formData?.geoLocation,
      {
        fullAddress,
        country,
        state: state,
        city: city,
        placeId,
        geolocation: {
          type: "Point",
          coordinates: [coordinates?.lng || 0, coordinates?.lat || 0],
        },
        country_code,
        state_code:
          scope.geographicalScope === "National" ||
          scope.geographicalScope === "International"
            ? ""
            : state_code,
      },
    ];

    setCampaignDetails((prevData) => ({
      ...prevData,
      country,
      state: state,
      city: city,
      geolocation: updatedGeoLocation,
    }));
  };

  useEffect(() => {
    if (selectedGeographicalScope === "International") {
      setCampaignDetails((prevData) => ({
        ...prevData,
        country: selectedCountries[0]?.country,
        geolocation: selectedCountries,
      }));
    }
  }, [selectedCountries, scope]);

  const handleAddCountry = (location) => {
    const { country, fullAddress, coordinates, country_code, city, state } =
      location;
    setValidationState(state);
    setValidationCity(city);
    if (!country) return;
    if (!selectedCountries.some((item) => item.country === country)) {
      const newCountry = {
        fullAddress,
        country,
        geolocation: {
          type: "Point",
          coordinates: [coordinates?.lng || 0, coordinates?.lat || 0],
        },
        country_code,
      };
      setSelectedCountries((prevSelected) => {
        const updatedCountries = [...prevSelected, newCountry];
        return updatedCountries;
      });
    }
  };

  const handleRemoveCountry = (countryToRemove) => {
    const updatedCountries = selectedCountries.filter(
      (item) => item.country !== countryToRemove
    );
    setSelectedCountries(updatedCountries);
    const updatedGeoLocation = campaignDetails.geolocation.filter(
      (item) => item.country !== countryToRemove
    );
    setCampaignDetails((prevData) => ({
      ...prevData,
      geolocation: updatedGeoLocation,
    }));
  };

  const handleCoinSelect = (option, levelIndex) => {
    setSelectedCoins((prev) => ({ ...prev, [levelIndex]: option }));
    setCampaignDetails((prevState) => {
      const newState = {
        ...prevState,
        levelsData: prevState.levelsData.map((levelDetail, idx) => {
          if (idx === levelIndex) {
            return {
              ...levelDetail,
              coins: option.value,
            };
          }
          return levelDetail;
        }),
      };
      return newState;
    });
  };

  const handleTermsChange = (value) => {
    setTermsAndConditions(value);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      termsAndConditions: value,
    }));
  };

  const handleSave = async () => {
    const {
      campaignTitle,
      NoOfLevels,
      Duration,
      roleId,
      rule,
      termsAndConditions,
      maxTejCoins,
      maxLakshmiCoins,
      levelsData,
      country,
      state,
      city,
    } = campaignDetails;

    let newErrors = {};
    let hasError = false;
    if (!campaignTitle) {
      newErrors.campaignTitle = "Campaign Title is required";
      hasError = true;
    }
    if (!NoOfLevels) {
      newErrors.NoOfLevels = "Number of Levels is required";
      hasError = true;
    }
    if (!Duration.startDate) {
      newErrors.startDate = "Start Date is required";
      hasError = true;
    }
    if (!Duration.endDate) {
      newErrors.endDate = "End Date is required";
      hasError = true;
    }
    if (!roleId) {
      newErrors.roleId = "Role is required";
      hasError = true;
    }

    if (!rule || rule.length === 0) {
      newErrors.rule = ["Rule is required"];
      hasError = true;
    } else {
      const ruleErrors = rule.map((r, index) =>
        !r ? `Rule ${index + 1} cannot be empty` : ""
      );
      if (ruleErrors.some((err) => err)) {
        newErrors.rule = ruleErrors.filter(Boolean);
        hasError = true;
      }
    }
    if (!maxTejCoins) {
      newErrors.maxTejCoins = "Tej Coins is required";
      hasError = true;
    }
    if (isNaN(maxTejCoins) || maxTejCoins < 0) {
      newErrors.maxTejCoins = "Max Tej coins must be a positive number";
      hasError = true;
    }
    if (!maxLakshmiCoins) {
      newErrors.maxLakshmiCoins = "Lakshmi Coins is required";
      hasError = true;
    }
    if (isNaN(maxLakshmiCoins) || maxLakshmiCoins < 0) {
      newErrors.maxLakshmiCoins = "Max Laxmi coins must be a positive number";
      hasError = true;
    }
    if (!termsAndConditions || termsAndConditions === "<p><br></p>") {
      newErrors.termsAndConditions = "Terms and Conditions is required";
      hasError = true;
    }
    switch (scope) {
      case "Local":
        if (!country || !state || !city) {
          newErrors.geoLocation =
            "Country, state, and city are required for Local scope.";
          hasError = true;
        }
        break;
      case "Regional":
        if (!country || !state || validationCity) {
          newErrors.geoLocation =
            "Only Country and state are required for Regional scope.";
          hasError = true;
        }
        break;
      case "National":
        if (!country || validationState || validationCity) {
          newErrors.geoLocation =
            "Only Country is required for National scope.";
          hasError = true;
        }
        break;

      case "International":
        if (!country || validationState || validationCity) {
          newErrors.geoLocation =
            "Only Country is required for International scope.";
          hasError = true;
        }
        break;
      default:
        break;
    }

    const levelsErrors = levelsData.map((level, levelIndex) => {
      const levelError = { tasks: [] };
      if (!level.levelName || level.levelName.trim() === "") {
        levelError.levelName = "Level name is required";
        hasError = true;
      }
      if (!level.maxCoins || isNaN(level.maxCoins) || level.maxCoins < 0) {
        levelError.maxCoins = "Max coins must be a positive number";
        hasError = true;
      }
      if (!level.coins) {
        levelError.coins = "Coins type is required";
        hasError = true;
      }
      level.tasksData.forEach((task) => {
        const taskError = {};
        if (!task.taskDescription || task.taskDescription.trim() === "") {
          taskError.taskDescription = "Task description is required";
          hasError = true;
        }
        if (!task.coinValue || isNaN(task.coinValue) || task.coinValue <= 0) {
          taskError.coinValue = "Coin value must be a positive number";
          hasError = true;
        }
        if (!task.taskName) {
          taskError.taskName = "task type is required";
          hasError = true;
        }
        levelError.tasks.push(taskError);
      });

      return levelError;
    });

    newErrors.levels = levelsErrors;
    const { startDate, endDate } = campaignDetails.Duration;
    if (new Date(endDate) <= new Date(startDate)) {
      newErrors.endDate = "End date must be greater than start date";
      hasError = true;
    }
    if (hasError) {
      setErrors(newErrors);
      return;
    }
    const updatedCampaignDetails = {
      ...campaignDetails,
    };

    try {
      let response = await userRequest.post(
        "/admin/svarna/addCampaign",
        updatedCampaignDetails
      );
      if (response?.status === 201) {
        navigate("/svarnamanagement");
        toast.success("Saved successfully!");
        setCampaignDetails(initialCampaignDetails);
        setErrors({}); // Clear errors after successful save
      }
    } catch (error) {
      console.log("Error saving campaign:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };

  const handleLevelInputChange = (index, field, value) => {
    setCampaignDetails((prevState) => {
      const newLevel = [...prevState.levelsData];
      if (newLevel[index]) {
        if (field === "levelName") {
          newLevel[index].levelName = value;
          newLevel[index].levelNumber = index + 1;
          newLevel[index].levelDescription = value;
        } else if (field === "totalTasks") {
          newLevel[index].totalTasks = parseInt(value) || 0;
        } else if (field === "maxCoins") {
          newLevel[index].maxCoins = parseInt(value) || 0;
        } else if (field === "coins") {
          newLevel[index].coins = value;
        }
        return {
          ...prevState,
          levelsData: newLevel,
        };
      } else {
        return prevState;
      }
    });
  };

  const handleTaskInputChange = (levelIndex, taskIndex, field, option) => {
    setSelectedTaskType((prev) => ({
      ...prev,
      [levelIndex]: {
        ...prev[levelIndex],
        [taskIndex]: option,
      },
    }));
    setCampaignDetails((prevState) => {
      const newLevelDetails = [...prevState.levelsData];
      const taskDetails =
        newLevelDetails[levelIndex].tasksData[taskIndex] || {};
      if (field === "taskName") {
        taskDetails.taskName = option.label;
        taskDetails.taskDescription = option.value;
        taskDetails.operationId = option.operationId;
        taskDetails.taskType = option.taskType;
      } else if (field === "coinValue") {
        taskDetails.coinValue = option;
      } else if (field === "taskDescription") {
        taskDetails.taskDescription = option.value;
      } else if (field === "taskCount") {
        taskDetails.taskCount = option;
      } else if (field === "numberOfDays") {
        taskDetails.numberOfDays = option;
      }
      newLevelDetails[levelIndex].tasksData[taskIndex] = taskDetails;
      return {
        ...prevState,
        levelsData: newLevelDetails,
      };
    });
  };

  const activeStep = campaignDetails?.levelsData?.length;
  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Svarna Management", path: "/svarnamanagement" },
          {
            label: "Create Campaign",
            path: "#",
          },
        ]}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={() => navigate("/svarnamanagement")}
        onSaveClick={handleSave}
      />

      <div className={styles.contentContainer}>
        <div className={styles.section}>
          <p>Campaign Details</p>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Campaign Title</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails.campaignTitle}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
                {errors.campaignTitle && (
                  <div className={styles.errorMessage}>
                    {errors.campaignTitle}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Duration</p>
              </div>
              <div className={styles.fieldInputTwo}>
                <div className={styles.dateInput}>
                  <input
                    type="date"
                    name="startDate"
                    onChange={(e) => handleDurationChange(e, "startDate")}
                    placeholder="Enter"
                    min={new Date().toISOString().split("T")[0]}
                  />
                  {errors.startDate && (
                    <div className={styles.errorMessage}>
                      {errors.startDate}
                    </div>
                  )}
                </div>
                <div className={styles.dateInput}>
                  <input
                    type="date"
                    name="endDate"
                    onChange={(e) => handleDurationChange(e, "endDate")}
                    value={endDate}
                    placeholder="Enter"
                    min={getMinEndDate()}
                  />
                  {errors.endDate && (
                    <div className={styles.errorMessage}>{errors.endDate}</div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Role</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={roleOptions}
                  onSelect={handleRoleSelect}
                />
                {errors.roleId && (
                  <div className={styles.errorMessage}>{errors.roleId}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Max Lakshmi Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxLakshmiCoins"
                  value={campaignDetails?.maxLakshmiCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                  min="0"
                />
                {errors.maxLakshmiCoins && (
                  <div className={styles.errorMessage}>
                    {errors.maxLakshmiCoins}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Max Tej Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="maxTejCoins"
                  value={campaignDetails.maxTejCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
                {errors.maxTejCoins && (
                  <div className={styles.errorMessage}>
                    {errors.maxTejCoins}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Add Rule </p>
              </div>
              <div className={`${styles.fieldInput} ${styles.fieldInputRule}`}>
                {rules.map((rule, index) => (
                  <div key={index}>
                    <div className={styles.rule}>
                      <textarea
                        value={rule}
                        onChange={(e) => handleRuleChange(index, e)}
                        placeholder="Enter"
                      />
                      {rules.length > 1 && (
                        <button
                          onClick={() => handleRemoveRule(index)}
                        ></button>
                      )}
                    </div>
                    {errors.rule?.[index] && (
                      <div className={styles.errorMessage}>
                        {errors.rule[index]}
                      </div>
                    )}
                    {index === rules.length - 1 && (
                      <button onClick={handleAddRule}>Add More</button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Add Terms & Conditions</p>
              </div>
              <div className={styles.fieldInput}>
                <RichTextEditor
                  value={termsAndConditions}
                  onChange={handleTermsChange}
                />
                {errors.termsAndConditions && (
                  <div className={styles.errorMessage}>
                    {errors.termsAndConditions}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Geographical Scope</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={geographicalScopeOptions}
                  onSelect={handleScopeSelect}
                />
                {errors.geographicalScope && (
                  <span className={styles.error}>
                    {errors.geographicalScope}
                  </span>
                )}
              </div>
            </div>
            {selectedGeographicalScope !== "Global" && (
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Geo Location</p>
                </div>
                <div className={styles.fieldInput}>
                  {selectedGeographicalScope !== "International" && (
                    <LocationSearchInput setInputValue={handleLocationChange} />
                  )}
                  {selectedGeographicalScope == "International" && (
                    <>
                      <LocationSearchInput
                        setInputValue={handleAddCountry}
                        selectedGeographicalScope={selectedGeographicalScope}
                      />
                      <div className={styles.selectedChips}>
                        {selectedCountries.map((item) => (
                          <span key={item.country} className={styles.chip}>
                            {item.country}
                            <button
                              type="button"
                              onClick={() => handleRemoveCountry(item.country)}
                              className={styles.removeChip}
                            >
                              ×
                            </button>
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                  {errors.geoLocation && (
                    <div className={styles.errorMessage}>
                      {errors.geoLocation}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.horizontalRow}></div>
        <div className={styles.levelDetailsMain}>
          <p className={styles.levelDetailsHeading}>Level Details</p>
          <button onClick={handleAddLevel}>Add More</button>
        </div>
        <Spin spinning={levelAdded || taskAdded} size="small">
          <Stepper
            defaultActiveStep={activeStep}
            strokeColor="#f68b21"
            fillStroke="#f68b21"
            activeColor="#f68b21"
            showProgressBar="true"
            activeProgressBorder="2px solid #f68b21"
            submitBtn={false}
            continueBtn={<button className={styles.stepperBtn}>Next</button>}
            backBtn={<button className={styles.stepperBtn}>Back</button>}
          >
            <div className={styles.pointsbx}>
              {campaignDetails.levelsData.map((level, levelIndex) => (
                <div
                  key={levelIndex}
                  className="card"
                  style={{ marginBottom: "5px" }}
                >
                  <div
                    className="card-header d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => toggleAccordion(levelIndex)}
                    style={{ padding: "0px 10px" }}
                  >
                    <span className="font-weight-bold">
                      Level {levelIndex + 1}
                    </span>
                    <button className="btn btn-link text-warning">
                      {activeIndex === levelIndex ? "△" : "▽"}
                    </button>
                  </div>
                  {/* Accordion Content */}
                  {activeIndex === levelIndex && (
                    <div className="card-body">
                      <div className={styles.stepperSubDiv}>
                        <button
                          className={styles.deleteItem}
                          onClick={() => handleRemoveLevel(levelIndex)}
                        ></button>
                        <div className={styles.section}>
                          <div className={styles.fieldContainer}>
                            <div className={styles.feildLeft}>
                              <div className={styles.fieldLabel}>
                                <p>Level {levelIndex + 1}</p>
                              </div>
                            </div>
                          </div>
                          <div className={styles.fieldContainer}>
                            <div className={styles.feildLeft}>
                              <div className={styles.fieldLabel}>
                                <p>Name of Level</p>
                              </div>
                              <div className={styles.fieldInput}>
                                <input
                                  type="text"
                                  value={
                                    campaignDetails.levelsData[levelIndex]
                                      ?.levelName
                                  }
                                  onChange={(e) =>
                                    handleLevelInputChange(
                                      levelIndex,
                                      "levelName",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter"
                                />
                                {errors.levels?.[levelIndex]?.levelName && (
                                  <div className={styles.errorMessage}>
                                    {errors.levels[levelIndex].levelName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={styles.fieldContainer}>
                            <div className={styles.feildLeft}>
                              <div className={styles.fieldLabel}>
                                <p>Max Coins</p>
                              </div>
                              <div className={styles.fieldInput}>
                                <input
                                  type="text"
                                  value={
                                    campaignDetails.levelsData[levelIndex]
                                      ?.maxCoins
                                  }
                                  onChange={(e) =>
                                    handleLevelInputChange(
                                      levelIndex,
                                      "maxCoins",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter"
                                />
                                {errors.levels?.[levelIndex]?.maxCoins && (
                                  <div className={styles.errorMessage}>
                                    {errors.levels[levelIndex].maxCoins}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={styles.feildRight}>
                              <div className={styles.fieldLabel}>
                                <p>Select Coins</p>
                              </div>
                              <div className={styles.fieldInput}>
                                <SelectComponent
                                  options={coinOptions}
                                  onSelect={(option) =>
                                    handleCoinSelect(option, levelIndex)
                                  }
                                  initialSelectedOption={
                                    selectedCoins[levelIndex]?.value
                                  }
                                />
                                {errors.levels?.[levelIndex]?.coins && (
                                  <div className={styles.errorMessage}>
                                    {errors.levels[levelIndex]?.coins}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={styles.section}>
                          <div className={styles.taskDetailsMain}>
                            <p>Task Details</p>
                            <button
                              className={styles.taskAdd}
                              onClick={() => handleAddTask(levelIndex)}
                              disabled={taskAdded}
                            >
                              Add Task
                            </button>
                          </div>

                          {level?.tasksData?.map((task, taskIndex) => (
                            <div
                              key={taskIndex}
                              className="card"
                              style={{ marginBottom: "5px" }}
                            >
                              <div
                                className="card-header d-flex justify-content-between align-items-center cursor-pointer"
                                onClick={() => toggleTaskAccordion(taskIndex)}
                                style={{ padding: "0px 10px" }}
                              >
                                <span className="font-weight-bold">
                                  Task {taskIndex + 1}
                                </span>
                                <button className="btn btn-link text-warning">
                                  {activeTaskIndex === taskIndex ? "△" : "▽"}
                                </button>
                              </div>
                              {/* Task Accordion Content */}
                              {activeTaskIndex === taskIndex && (
                                <div
                                  className={`${styles.fieldContainer} ${styles.fieldContainerTwo}`}
                                  key={taskIndex}
                                >
                                  <div className={styles.deleteDiv}>
                                    <button
                                      className={styles.deleteItemTwo}
                                      onClick={() =>
                                        handleRemoveTask(levelIndex, taskIndex)
                                      }
                                    ></button>
                                  </div>
                                  <div className={styles.feildLeft}>
                                    <div className={styles.fieldLabel}>
                                      <p>Coin Value</p>
                                    </div>
                                    <div className={styles.fieldInput}>
                                      <input
                                        type="number"
                                        value={
                                          campaignDetails.levelsData[levelIndex]
                                            ?.tasksData[taskIndex]?.coinValue
                                        }
                                        onChange={(e) =>
                                          handleTaskInputChange(
                                            levelIndex,
                                            taskIndex,
                                            "coinValue",
                                            e.target.value
                                          )
                                        }
                                        min={0}
                                        placeholder="Enter"
                                      />
                                      {errors.levels?.[levelIndex]?.tasks?.[
                                        taskIndex
                                      ]?.coinValue && (
                                        <div className={styles.errorMessage}>
                                          {
                                            errors.levels[levelIndex]?.tasks[
                                              taskIndex
                                            ]?.coinValue
                                          }
                                        </div>
                                      )}
                                    </div>

                                    <div className={styles.fieldLabel}>
                                      <p>Task Description</p>
                                    </div>
                                    <div className={styles.fieldInput}>
                                      <textarea
                                        value={
                                          campaignDetails.levelsData[levelIndex]
                                            ?.tasksData[taskIndex]
                                            ?.taskDescription
                                        }
                                        onChange={(e) =>
                                          handleTaskInputChange(
                                            levelIndex,
                                            taskIndex,
                                            "taskDescription",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Enter"
                                      />
                                      {errors.levels?.[levelIndex]?.tasks?.[
                                        taskIndex
                                      ]?.taskDescription && (
                                        <div className={styles.errorMessage}>
                                          {
                                            errors.levels[levelIndex].tasks[
                                              taskIndex
                                            ].taskDescription
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className={styles.feildRight}>
                                    <div className={styles.fieldLabel}>
                                      <p>Task Type</p>
                                    </div>
                                    <div className={styles.fieldInput}>
                                      <SelectComponent
                                        options={taskTypes.map((task) => ({
                                          label: task?.title,
                                          value: task?.description,
                                          operationId: task?._id,
                                          taskType: task?.taskType,
                                        }))}
                                        onSelect={(option) =>
                                          handleTaskInputChange(
                                            levelIndex,
                                            taskIndex,
                                            "taskName",
                                            option
                                          )
                                        }
                                        initialSelectedOption={
                                          selectedTaskType?.[levelIndex]?.[taskIndex]?.label || "" 
                                        }
                                      />
                                      {errors.levels?.[levelIndex]?.tasks?.[
                                        taskIndex
                                      ]?.taskName && (
                                        <div className={styles.errorMessage}>
                                          {
                                            errors.levels[levelIndex].tasks[
                                              taskIndex
                                            ].taskName
                                          }
                                        </div>
                                      )}
                                    </div>

                                    {campaignDetails?.levelsData[levelIndex]
                                      ?.tasksData[taskIndex]?.taskType ===
                                      "Continuous" && (
                                      <>
                                        <div className={styles.fieldLabel}>
                                          <p>Number Of Days</p>
                                        </div>
                                        <div className={styles.fieldInput}>
                                          <input
                                            type="text"
                                            value={
                                              campaignDetails.levelsData[
                                                levelIndex
                                              ]?.tasksData[taskIndex]
                                                ?.numberOfDays
                                            }
                                            onChange={(e) =>
                                              handleTaskInputChange(
                                                levelIndex,
                                                taskIndex,
                                                "numberOfDays",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Enter"
                                          />
                                        </div>
                                      </>
                                    )}
                                    {campaignDetails?.levelsData[levelIndex]
                                      ?.tasksData[taskIndex]?.taskType ===
                                      "Multiple" && (
                                      <>
                                        <div className={styles.fieldLabel}>
                                          <p>Task Count</p>
                                        </div>
                                        <div className={styles.fieldInput}>
                                          <input
                                            type="text"
                                            value={
                                              campaignDetails.levelsData[
                                                levelIndex
                                              ]?.tasksData[taskIndex]?.taskCount
                                            }
                                            onChange={(e) =>
                                              handleTaskInputChange(
                                                levelIndex,
                                                taskIndex,
                                                "taskCount",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Enter"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Stepper>
        </Spin>
      </div>
    </div>
  );
}

export default CreateCampaign;
