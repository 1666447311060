import React, { useEffect, useState } from "react";
import styles from "../CreateBanner/createBanner.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { userRequest } from "../../../requestMethod";
import FileUpload from "../../../components/FileUpload/FileUpload";
import SelectComponent from "../../../components/SelectComponent";
import PageHeaderTwo from "../../../components/PageHeader/PageHeaderTwo";
import LocationSearchInput from "../../../components/LocationSearch/LocationSearchInput";

function EditBanner() {
  const navigate = useNavigate();
  const location = useLocation();
  const banner = location.state && location.state.banner;
  const [isTyping, setIsTyping] = useState(false);
  const [validationCity, setValidationCity] = useState();
  const [validationState, setValidationState] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const initialFormData = {
    id: banner?._id || "",
    title: banner?.title || "",
    imageUrl: banner?.imageUrl || "",
    startDate: banner?.startDate || "",
    endDate: banner?.endDate || "",
    content: banner?.content || "",
    targetAudience: banner?.targetAudience || "",
    linkUrl: banner?.linkUrl || "",
    geographicalScope: banner?.geographicalScope || "",
    language: banner?.language || "",
    geolocation: banner?.geolocation || [],
    country: banner?.geolocation?.[0]?.country || "",
    state: banner?.geolocation?.[0]?.state || "",
    city: banner?.geolocation?.[0]?.city || "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const state =
    formData.geographicalScope === "National" ||
    formData.geographicalScope === "International"
      ? ""
      : location.state;
  const city = formData.geographicalScope === "Local" ? location.city : "";
  const [errors, setErrors] = useState({});
  const geographicalScopes = [
    "Local",
    "Regional",
    "National",
    "International",
    "Global",
  ];

  const targetAudiences = ["Existing customers", "New customers"];
  const languages = ["Hindi", "English", "Sanskrit"];

  const formatDate = (date) => {
    if (!date) return "";
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return ""; 
    return parsedDate.toISOString().split("T")[0];
  };

  const handleDateChange = (e, InputName) => {
    if (InputName == "startDate") {
      setFormData((prevData) => ({
        ...prevData,
        endDate: "",
      }));
      setStartDate(e.target.value);
      setEndDate("");
    } else {
      setEndDate(e.target.value);
    }
  };
  const getMinEndDate = () => {
    const baseDate = startDate ? new Date(startDate) : new Date();
    baseDate.setDate(baseDate.getDate() + 1);
    return baseDate.toISOString().split("T")[0];
  };
  const languageOptions = languages.map((language) => ({
    value: language,
    label: language,
  }));

  const geographicalScopeOptions = geographicalScopes.map((geo) => ({
    value: geo,
    label: geo,
  }));

  const targetAudienceOptions = targetAudiences.map((aud) => ({
    value: aud,
    label: aud,
  }));

  const handleLocationChange = (location) => {
    // const state =
    //   formData.geographicalScope === "National" ||
    //   formData.geographicalScope === "International"
    //     ? ""
    //     : location.state;
    // const city = formData.geographicalScope === "Local" ? location.city : "";
    const {
      country,
      state,
      city,
      coordinates,
      placeId,
      fullAddress,
      country_code,
      state_code,
    } = location;
    setValidationState(state);
    setValidationCity(city);
    const updatedGeoLocation = [
      // ...formData?.geoLocation,
      {
        fullAddress,
        country,
        state:
          formData.geographicalScope === "National" ||
          formData.geographicalScope === "International"
            ? ""
            : state,
        city: formData.geographicalScope === "Local" ? city : "",
        placeId,
        geolocation: {
          type: "Point",
          coordinates: [coordinates?.lng || 0, coordinates?.lat || 0],
        },
        country_code,
        state_code:
          formData.geographicalScope === "National" ||
          formData.geographicalScope === "International"
            ? ""
            : state_code,
      },
    ];

    setFormData((prevData) => ({
      ...prevData,
      country,
      state:
        formData.geographicalScope === "National" ||
        formData.geographicalScope === "International"
          ? ""
          : state,
      city: formData.geographicalScope === "Local" ? city : "",
      geolocation: updatedGeoLocation,
    }));
  };

  const [selectedCountries, setSelectedCountries] = useState([]);

  const handleAddCountry = (location) => {
    const { country, fullAddress, coordinates, country_code, city, state } =
      location;
    setValidationState(state);
    setValidationCity(city);
    if (!country) return;
    if (!selectedCountries.some((item) => item.country === country)) {
      const newCountry = {
        fullAddress,
        country,
        geolocation: {
          type: "Point",
          coordinates: [coordinates?.lng || 0, coordinates?.lat || 0],
        },
        country_code,
      };
      setSelectedCountries((prevSelected) => {
        const updatedCountries = [...prevSelected, newCountry];
        return updatedCountries;
      });
    }
  };

  useEffect(() => {
    if (formData.geographicalScope === "International") {
      setFormData((prevData) => ({
        ...prevData,
        country: selectedCountries[0]?.country,
        geolocation: selectedCountries,
      }));
    }
  }, [selectedCountries, formData.geographicalScope]);

  useEffect(() => {
    if (
      formData?.geographicalScope === "International" &&
      formData?.geolocation
    ) {
      setSelectedCountries(formData.geolocation);
    }
  }, []);

  const handleRemoveCountry = (countryToRemove) => {
    const updatedCountries = selectedCountries.filter(
      (item) => item.country !== countryToRemove
    );
    setSelectedCountries(updatedCountries);
    const updatedGeoLocation = formData.geolocation.filter(
      (item) => item.country !== countryToRemove
    );
    setFormData((prevData) => ({
      ...prevData,
      geolocation: updatedGeoLocation,
    }));
  };

  const selectedGeographicalScope = formData.geographicalScope;

  const validateForm = () => {
    let formErrors = {};
    if (!formData.title) formErrors.title = "Title is required";
    if (!formData.imageUrl) formErrors.imageUrl = "Image URL is required";
    if (!formData.startDate) formErrors.startDate = "Start Date is required";
    if (!formData.endDate) formErrors.endDate = "End Date is required";
    if (!formData.content) formErrors.content = "Content is required";
    if (!formData.targetAudience)
      formErrors.targetAudience = "Target Audience is required";
    if (!formData.geographicalScope)
      formErrors.geographicalScope = "Geographical Scope is required";
    if (!formData.language) formErrors.language = "Language is required";

    switch (formData.geographicalScope) {
      case "Local":
        if (!formData.country || !formData.state || !formData.city) {
          formErrors.geoLocation =
            "Country, state, and city are required for Local scope.";
        }
        break;
      case "Regional":
        if (!formData.country || !formData.state || validationCity) {
          formErrors.geoLocation =
            "Only Country and state are required for Regional scope.";
        }
        break;
      case "National":
        if (!formData.country || validationState || validationCity) {
          formErrors.geoLocation =
            "Only Country is required for National scope.";
        }
        break;

      case "International":
        if (!formData.country || validationState || city) {
          formErrors.geoLocation =
            "Only Country is required for International scope.";
        }
        break;
      default:
        break;
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSaveClick = async () => {
    if (validateForm()) {
      try {
        await userRequest.put(`master/updateMasterBanner`, formData);
        navigate("/bannerManagement");
        toast.success("Updated successfully!");
      } catch (error) {
        console.error("Error saving data:", error.message);
        toast.error("Error saving data");
      }
    }
  };

  const handleDiscard = () => {
    navigate("/bannermanagement");
  };

  const breadcrumb = [
    { label: "Banner Management", path: "/bannermanagement" },
    { label: "Update Banner", path: "/bannermanagement/edit" },
  ];

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={handleDiscard}
        onSaveClick={handleSaveClick}
      />

      <div className={styles.contentContainer}>
        <div className={styles.tableContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Banner Title</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => {
                    setFormData({ ...formData, title: e.target.value });
                    setErrors({ ...errors, title: "" });
                  }}
                  placeholder="Enter"
                />
                {errors.title && (
                  <span className={styles.error}>{errors.title}</span>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Image URL</p>
              </div>
              <div className={styles.fieldInput}>
                <FileUpload
                  label="Upload Image"
                  onChange={(fieldName, file) => {
                    setFormData({ ...formData, imageUrl: file });
                    setErrors({ ...errors, imageUrl: "" });
                  }}
                  fieldName="uploadImage"
                  name={formData.imageUrl}
                />
                {errors.imageUrl && (
                  <span className={styles.error}>{errors.imageUrl}</span>
                )}
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Content</p>
              </div>
              <div className={styles.fieldInput}>
                <textarea
                  value={formData.content}
                  onChange={(e) => {
                    setFormData({ ...formData, content: e.target.value });
                    setErrors({ ...errors, content: "" });
                  }}
                  placeholder="Enter"
                />
                {errors.content && (
                  <span className={styles.error}>{errors.content}</span>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Link URL</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  value={formData.linkUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, linkUrl: e.target.value })
                  }
                  placeholder="Enter"
                />
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Target Audience</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={targetAudienceOptions}
                  onSelect={(selectedOption) => {
                    setFormData({
                      ...formData,
                      targetAudience: selectedOption.value,
                    });
                    setErrors({ ...errors, targetAudience: "" });
                  }}
                  initialSelectedOption={formData.targetAudience}
                />
                {errors.targetAudience && (
                  <span className={styles.error}>{errors.targetAudience}</span>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Start Date</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="date"
                  value={formatDate(formData.startDate)}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      startDate: e.target.value,
                    });
                    handleDateChange(e, "startDate");
                    setErrors({ ...errors, startDate: "" });
                  }}
                  min={new Date().toISOString().split("T")[0]}
                />
                {errors.startDate && (
                  <span className={styles.error}>{errors.startDate}</span>
                )}
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Geographical Scope</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={geographicalScopeOptions}
                  onSelect={(selectedOption) => {
                    setFormData({
                      ...formData,
                      geographicalScope: selectedOption.value,
                    });
                    setErrors({ ...errors, geographicalScope: "" });
                  }}
                  initialSelectedOption={formData.geographicalScope}
                />
                {errors.geographicalScope && (
                  <span className={styles.error}>
                    {errors.geographicalScope}
                  </span>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>End Date</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="date"
                  value={formatDate(formData.endDate) || ""}
                  onChange={(e) => {
                    setFormData({ ...formData, endDate: e.target.value });
                    setErrors({ ...errors, endDate: "" });
                  }}
                  min={getMinEndDate()}
                />
                {errors.endDate && (
                  <span className={styles.error}>{errors.endDate}</span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Language</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={languageOptions}
                  onSelect={(selectedOption) => {
                    setFormData({
                      ...formData,
                      language: selectedOption.value,
                    });
                    setErrors({ ...errors, language: "" });
                  }}
                  initialSelectedOption={formData.language}
                />
                {errors.language && (
                  <span className={styles.error}>{errors.language}</span>
                )}
              </div>
            </div>
            {selectedGeographicalScope !== "Global" && (
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Geo Location</p>
                </div>
                <div className={styles.fieldInput}>
                  {selectedGeographicalScope !== "International" && (
                    <LocationSearchInput
                      setInputValue={handleLocationChange}
                      fullAddress={banner?.geolocation[0]?.fullAddress || ""}
                      setIsTyping={setIsTyping}
                    />
                  )}

                  {!isTyping &&
                    banner?.geolocation &&
                    selectedGeographicalScope !== "International" && (
                      <div>
                        <span>
                          Country: {banner?.geolocation[0]?.country || ""}
                        </span>
                        <br />
                        <span>
                          State: {banner?.geolocation[0]?.state || ""}
                        </span>
                        <br />
                        <span>City: {banner?.geolocation[0]?.city || ""}</span>
                      </div>
                    )}

                  {selectedGeographicalScope == "International" && (
                    <>
                      <LocationSearchInput
                        setInputValue={handleAddCountry}
                        selectedGeographicalScope={selectedGeographicalScope}
                      />
                      <div className={styles.selectedChips}>
                        {selectedCountries.map((item) => (
                          <span key={item.country} className={styles.chip}>
                            {item.country}
                            <button
                              type="button"
                              onClick={() => handleRemoveCountry(item.country)}
                              className={styles.removeChip}
                            >
                              ×
                            </button>
                          </span>
                        ))}
                      </div>
                    </>
                  )}

                  {errors.geoLocation && (
                    <p className={styles.error}>{errors.geoLocation}</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBanner;
