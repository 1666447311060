import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import PageHeaderTwo from "../../../components/PageHeader/PageHeaderTwo";
import SelectComponent from "../../../components/SelectComponent";
import FileUpload from "../../../components/FileUpload/FileUpload";
import styles from "./createnotification.module.css";
import { userRequest } from "../../../requestMethod";
import Select from "react-select";

const CreateNotification = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      message: "",
      type: "",
      target: {
        user_segments: [],
      },
      schedule: {
        type: "",
        send_at: "",
        recurrence: "",
      },
      userId: [],
      language: "",
      media: [],
    },
  });

  const targetUserOptions = [
    { value: "AllUser", label: "All Devotee" },
    { value: "AllPandit", label: "All Pandit" },
    { value: "Individual", label: "Individual" },
    { value: "devalaya", label: "Devalaya" },
  ];

  const userOptions = [
    { value: "Devotee", label: "Devotee" },
    { value: "Pandit", label: "Pandit" },
  ];
  const notificationType = [
    { value: "push", label: "Push" },
    { value: "email", label: "Email" },
    { value: "in-app", label: "in-app" },
  ];

  const recurrenceOptions = [
    { value: "once", label: "Once" },
    { value: "twice", label: "Twice" },
    { value: "daily", label: "Daily" },
  ];

  const navigate = useNavigate();
  const [languages, setLanguages] = useState([]);
  const [userType, setUserType] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [userData, setUserData] = useState([]);

  const handleUserTypeChange = (selectedOption) => {
    console.log("selectedOption", selectedOption);
    setUserType(selectedOption?.value || "");
    if (!selectedOption?.value) {
      setValue("target.user_segments", []);
    } else {
      setValue("target.user_segments", getValues("target.user_segments"));
    }
  };

  const fetchUsers = async (type) => {
    try {
      const response = await userRequest.get(
        `/userManagement/getUserManagementList?userType=${type}&search=${searchQuery}`
      );
      setUserData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (userType) {
      fetchUsers(userType);
    }
  }, [userType]);

  const fetchLanguages = async () => {
    try {
      let response = await userRequest.get("/master/getMasterLanguage");
      setLanguages(response?.data?.data);
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    }
  };

  const languageOptions = languages?.map((item) => ({
    value: item?.language,
    label: item?.language,
  }));

  useEffect(() => {
    fetchLanguages();
  }, []);

  const onSubmit = async (data) => {
    const notificationPayload = {
      title: data.title,
      message: data.message,
      type: data.type,
      target: {
        user_segments: data.target.user_segments || [],
      },
      schedule: {
        type: data.schedule.type,
        send_at: data.schedule.send_at || null,
        recurrence: data.schedule.recurrence || null,
      },
      userId: data.userId || [],
      language: data.language || "en",
      media: data.media.length ? data.media : [],
      status: data.schedule.type === "immediate" ? "sent" : "scheduled",
    };

    try {
      await userRequest.post(
        "/admin/notification/createNotification",
        notificationPayload
      );
      console.log("Submitting Payload:", notificationPayload);
      toast.success("Notification created successfully");
      navigate("/notificationmanagement");
    } catch (error) {
      console.error("Error creating notification:", error);
      toast.error("Failed to create notification");
    }
  };

  const scheduleType = watch("schedule.type");
  const targetType = watch("target.user_segments");

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Notification Management", path: "/notificationmanagement" },
          {
            label: "Create Notification",
            path: "/notificationmanagement/createnotification",
          },
        ]}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={() => navigate("/notificationmanagement")}
        onSaveClick={handleSubmit(onSubmit)}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.contentContainer}
      >
        <div className={styles.leftContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <label className={styles.fieldLabel}>Add Title</label>
              <div className={styles.fieldInput}>
                <input
                  {...register("title", { required: "Title is required" })}
                  placeholder="Enter title"
                />
              </div>
              {errors.title && (
                <p className={styles.error}>{errors.title.message}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.fieldLabel}>Write Message</label>
            <div className={styles.fieldInput}>
              <textarea
                {...register("message", { required: "Message is required" })}
                placeholder="Enter message"
              />
            </div>
            {errors.message && (
              <p className={styles.error}>{errors.message.message}</p>
            )}
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.fieldLabel}>Upload Media</label>
            <div className={styles.fieldInput}>
              <Controller
                name="media"
                control={control}
                render={({ field }) => (
                  <FileUpload
                    label="Upload Media"
                    onChange={(fieldName, url) =>
                      field.onChange([...field.value, url])
                    }
                    fieldName="uploadMedia"
                  />
                )}
              />
            </div>
            {errors.media && (
              <p className={styles.error}>{errors.media.message}</p>
            )}
          </div>

          {scheduleType === "scheduled" && (
            <div className={styles.fieldContainer}>
              <label className={styles.fieldLabel}>Recurrence</label>
              <div className={styles.fieldInput}>
                <Controller
                  name="schedule.recurrence"
                  control={control}
                  render={({ field }) => (
                    <SelectComponent
                      options={recurrenceOptions}
                      onSelect={(selected) => field.onChange(selected.value)}
                    />
                  )}
                />
              </div>
              {errors.schedule?.recurrence && (
                <p className={styles.error}>
                  {errors.schedule.recurrence.message}
                </p>
              )}
            </div>
          )}
        </div>

        <div className={styles.rightContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <label className={styles.fieldLabel}>Type</label>
              <div className={styles.fieldInput}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <SelectComponent
                      options={notificationType}
                      onSelect={(selected) => {
                        field.onChange(selected.value);
                      }}
                    />
                  )}
                />
              </div>
              {errors.title && (
                <p className={styles.error}>{errors.title.message}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.fieldLabel}>User Segments</label>
            <div className={styles.fieldInput}>
              <Controller
                name="target.user_segments"
                control={control}
                render={({ field }) => (
                  <SelectComponent
                    options={targetUserOptions}
                    onSelect={(selected) => {
                      field.onChange([selected.value]);
                    }}
                  />
                )}
              />
            </div>
          </div>

          {targetType?.includes("Individual") && (
            <div className={styles.fieldUserContainer}>
              <div className={styles.fieldLabel}>
                <p>Select User Type</p>
              </div>
              <div className={styles.fieldInput}>
                <Controller
                  name="target.user_type"
                  control={control}
                  render={({ field }) => (
                    <SelectComponent
                      options={userOptions}
                      onSelect={(selectedOption) => {
                        handleUserTypeChange(selectedOption); // Update state and form value
                        field.onChange(selectedOption.value); // Set form value
                      }}
                      value={userOptions.find(
                        (option) => option.value === field.value
                      )}
                      isClearable
                    />
                  )}
                />
              </div>
            </div>
          )}

          {userType && targetType?.includes("Individual") && (
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <label>Select Users</label>
              </div>
              <div className={styles.fieldInput}>
                <Controller
                  name="userId"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={userData?.map((user) => ({
                        value: user._id,
                        label: `${user.firstName} ${user.lastName}`,
                      }))}
                      onChange={(selected) => {
                        const userIds = selected.map((option) => option.value);
                        field.onChange(userIds);
                      }}
                      isMulti
                      value={field.value.map((id) => ({
                        value: id,
                        label:
                          userData.find((user) => user._id === id)?.firstName ||
                          "",
                      }))}
                      isSearchable
                      placeholder="Search and select users"
                    />
                  )}
                />
              </div>
            </div>
          )}

          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Language</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="language"
                control={control}
                rules={{ required: "Language is required." }}
                render={({ field }) => (
                  <SelectComponent
                    options={languageOptions}
                    onSelect={(selectedOption) =>
                      field.onChange(selectedOption.value)
                    }
                    initialSelectedOption={
                      languageOptions.find(
                        (option) => option.value === field.value
                      )?.label
                    }
                  />
                )}
              />
              {errors.language && (
                <p className={styles.error}>{errors.language.message}</p>
              )}
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.feildRight}>
              <label className={styles.fieldLabel}>Schedule</label>
              <div className={styles.fieldInput}>
                <Controller
                  name="schedule.type"
                  control={control}
                  rules={{ required: "Schedule type is required" }}
                  render={({ field }) => (
                    <SelectComponent
                      options={[
                        { value: "immediate", label: "Immediate" },
                        { value: "scheduled", label: "Scheduled" },
                      ]}
                      onSelect={(selected) => field.onChange(selected.value)}
                      selectedValue={field.value}
                    />
                  )}
                />
                {errors.schedule?.type && (
                  <p className={styles.error}>{errors.schedule.type.message}</p>
                )}
              </div>
            </div>
          </div>

          {scheduleType === "scheduled" && (
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <label className={styles.fieldLabel}>Send At</label>
                <div className={styles.fieldInput}>
                  <input
                    type="date"
                    {...register("schedule.send_at", {
                      required: "Send Date is required",
                    })}
                    className={styles.fieldInput}
                  />
                </div>
                {errors.schedule?.send_at && (
                  <p className={styles.error}>
                    {errors.schedule.send_at.message}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateNotification;
