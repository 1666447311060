import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { adminForgotPassword } from "../../../features/actions/adminAction";
import { resetAdminStatus } from "../../../features/slices/adminSlice";
import "./ForgotPassword.css";
import logo from "../../../assets/images/Layer_1.png";
import CarouselComponent from "../../../components/carousel/carouselComponent";


function ForgotPassword() {
  const[loader , setLoader] = useState(false)
  const dispatch = useDispatch();
  const { isSuccess , isLoading } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
     
    },
  });

  const onSubmit = (data) => {
    console.log("data", data);
    let payload = data;
    dispatch(adminForgotPassword({ payload }));
  };

  useEffect(() => {
    console.log("isSuccess", isSuccess);
    if (isSuccess === true) {
      navigate("/forgotPassword");
      dispatch(resetAdminStatus(false));
    }
    if(isLoading ===true){
      setLoader(true)
    }
    else{
      setLoader(false)
    }
  }, [isSuccess, isLoading]);
  return (
    <div className="container-fluid">
        {loader === true? (<>
      <div className="overlay">
            <div className="loader"></div>
          
</div>

    </>):null}
      <div className="row">
        <div className="col-sm-6 d-flex justify-content-center  formColbg hlo" >
          <div className="">
            <div className="col-sm-6 w-100 d-flex justify-content-center ">

             <img className="login-logo " src={logo} />
            </div>
            <div className="col-sm-6 w-100 textDiv">

            <h2 className="login-form-heading d-flex justify-content-center mt-2">
              <strong>Forgot Password</strong>
            </h2>
            

            <p className="login-form-paragragh d-flex justify-content-center">Lorem Ipsum has been the industry's </p>
            <p className="login-form-paragragh d-flex justify-content-center">Lorem Ipsum Ipsum is simply   </p>
          
            
            </div>
            <div className="col-sm-6 my-2 loginWidth"  >
            <form className="login-form " onSubmit={handleSubmit(onSubmit)}>
              <div class="mb-3 ">
                <div>
                  <label for="formGroupExampleInput" className="label">
                    Email
                  </label>
                </div>
                <div className="email-input-div">
                  <input
                    type="text"
                    className="inputField"
                    id="formGroupExampleInput"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Email is required",
                      },
                    })}
                    placeholder="Email"
                  />
                  {errors?.email && (
                    <p style={{ color: "red", textAlign: "left" }}>
                      {errors?.email?.message}
                    </p>
                  )}
                </div>
              </div>
            
              <div>
                <button
                 
                  type="submit"
                  className="login-button my-4"
                >
                  <strong>Continue</strong>
                </button>
              </div>
            </form>
            </div>
           
          </div>
        </div>
     
        <div className="col-sm-6 d-flex align-items-center justify-content-center ">

         <CarouselComponent/>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;