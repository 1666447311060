import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";

const ViewRaasi = ({ item }) => {
  const formFields = [{ name: "name", label: "Name", type: "text" }];

  return <ViewItem formFields={formFields} title="View Raasi" item={item} />;
};

export default ViewRaasi;
