import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useForm, Controller } from "react-hook-form";
import { toast } from "sonner";
import { userRequest } from "../../../../../requestMethod";
import SelectComponent from "../../../../../components/SelectComponent";
import styles from "./addTask.module.css";

const AddTask = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [taskTypeOptions, setTaskTypeOptions] = useState([
    { value: "Single", label: "Single" },
    { value: "Multiple", label: "Multiple" },
    { value: "Continuous", label: "Continuous" },
  ]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      taskType: "",
      roleId: "",
      category: "",
      apiEndpoint: "",
      method: "",
      screenFlow: "",
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const fetchUserRoles = async () => {
    try {
      const response = await userRequest.get("/userRoles/getAllUserRoles");
      setRoles(
        response.data.data.map((role) => ({
          value: role?._id,
          label: role?.roleName,
        }))
      );
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  useEffect(() => {
    fetchUserRoles();
  }, []);

  const onSubmit = async (data) => {
    try {
      await userRequest.post(`/master/createMasterTaskType`, data);
      toast.success("Task created successfully");
      reset();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error creating Task:", error);
      toast.error("Failed to create Task");
    }
  };

  const handleCancel = () => {
    reset();
    setIsModalOpen(false);
  };

  return (
    <>
      <button className={styles.addNewBtn} onClick={showModal}>
        Add Task
      </button>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Add Task</p>
        </div>
        <form
          className={styles.contentMain}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {/* Title Field */}
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Title *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="title"
                control={control}
                rules={{ required: "Title is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.title && (
                <span className={styles.error}>{errors.title.message}</span>
              )}
            </div>
          </div>

          {/* Description Field */}
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Description *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="description"
                control={control}
                rules={{ required: "Description is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.description && (
                <span className={styles.error}>
                  {errors.description.message}
                </span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Api Endpoint *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="apiEndpoint"
                control={control}
                rules={{ required: "Api Endpoint is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.apiEndpoint && (
                <span className={styles.error}>{errors.apiEndpoint.message}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Method *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="method"
                control={control}
                rules={{ required: "Method is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.method && (
                <span className={styles.error}>{errors.method.message}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>screenFlow *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="screenFlow"
                control={control}
                rules={{ required: "ScreenFlow is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.screenFlow && (
                <span className={styles.error}>{errors.screenFlow.message}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Category *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="category"
                control={control}
                rules={{ required: "category is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.category && (
                <span className={styles.error}>{errors.category.message}</span>
              )}
            </div>
          </div>
          {/* Task Type Field */}
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Task Type *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="taskType"
                control={control}
                rules={{ required: "Task type is required." }}
                render={({ field }) => (
                  <SelectComponent
                    options={taskTypeOptions}
                    onSelect={(selectedOption) =>
                      field.onChange(selectedOption.value)
                    }
                  />
                )}
              />
              {errors.taskType && (
                <span className={styles.error}>{errors.taskType.message}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Role Type *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="roleId"
                control={control}
                rules={{ required: "Role type is required." }}
                render={({ field }) => (
                  <SelectComponent
                    options={roles}
                    onSelect={(selectedOption) =>
                      field.onChange(selectedOption.value)
                    }
                  />
                )}
              />
              {errors.roleId && (
                <span className={styles.error}>{errors.roleId.message}</span>
              )}
            </div>
          </div>
          <div className={styles.modalButton}>
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit">Add</button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddTask;
