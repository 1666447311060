import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
// import {BASE_URL} from '../../requestMethod.js'
import { getConfig } from '../../utils/getConfig.js';

const { BASE_URL, TOKEN_KEY  } = getConfig();

export const adminLogin = createAsyncThunk("/admin/adminLogin/",
    async ({ payload }, { rejectWithValue }) => {
        try {
            console.log("BASE_URL" , BASE_URL)
            const { data } = await axios.post(`${BASE_URL}/admin/login`, payload);
            localStorage.setItem(TOKEN_KEY, data?.token); // Store the token using the environment-specific key
            // localStorage.setItem("USER_DETAILS", JSON.stringify(data?.userDetails)); // Store the token using the environment-specific key
            // console.log("data@" , data)
            return data;
        } catch (err) {
            console.log("error**", err?.response?.data?.message);
            return rejectWithValue(err?.response?.data?.message);
        }
    }
  );

  export const adminForgotPassword = createAsyncThunk("/admin/adminForgotPassword/",
    async ({ payload }, { rejectWithValue }) => {
        try {
            const { data } = await axios.post(`${BASE_URL}/admin/forgotPassword`, payload);
            return data;
        } catch (err) {
            console.log("error**", err?.response?.data?.message);
            return rejectWithValue(err?.response?.data?.message);
        }
    }
  );

  export const adminResetPassword = createAsyncThunk("/admin/adminResetPassword/",
    async ({ payload, token }, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            };
            
            const { data } = await axios.post(`${BASE_URL}/admin/resetPassword`, payload, config);
            return data;
        } catch (err) {
            console.log("error**", err?.response?.data?.message);
            return rejectWithValue(err?.response?.data?.message);
        }
    }
  );