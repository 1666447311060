import React, { useState } from 'react';
import { Modal } from 'antd';
import styles from './style.module.css';
import viewIcon from '../../../assets/images/viewIcons.png';

const ViewItem = ({ item, initialFormData, title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={viewIcon} alt="View" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>{title}</p>
        </div>
        <div className={styles.contentMain}>
          {initialFormData && Object.entries(initialFormData)?.map(([key, field]) => (
            <div className={styles.fieldContainer} key={key}>
              <div className={styles.fieldLabel}>
                <p>{field?.label}</p>
              </div>
              <div className={styles.fieldInput}>
                {field?.type === 'text' && (
                  <input
                    type="text"
                    name={key}
                    value={field?.value || ''}
                    disabled
                  />
                )}
              </div>
            </div>
          ))}
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Ok</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewItem;
