import React, { useState } from "react";
import styles from "./FaqManagement.module.css";
import FaqCategory from "./FaqCategory/FaqCategory";
import Faq from "./Faq/Faq";


function FaqManagement() {
  const menuItems = [
    { label: "Faq", state: "Faq" },
      { label: "Faq Category", state: "FaqCategory" },

  ];

  const [activeMenuItem, setActiveMenuItem] = useState("Faq");

  const handlePoliciesMenu = (menu) => {
    setActiveMenuItem(menu);
  };

  return (
    <div className={styles.main}>
  
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={
                activeMenuItem === item.state
                  ? `${styles.singleMenu} ${styles.activeMenu}`
                  : styles.singleMenu
              }
              onClick={() => handlePoliciesMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.activePage}>
          {activeMenuItem === "Faq" && <Faq />}
          {activeMenuItem === "FaqCategory" && <FaqCategory />}
        </div>
      </div>

 
    </div>
  );
}

export default FaqManagement;
