import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./reason.module.css";
import { userRequest } from "../../../requestMethod";
import SelectComponent from "../../../components/SelectComponent";


const SelectDeactivateReason = ({selectedReason,setSelectedReason,isModalOpen,setIsModalOpen,id,userType,fetchData}) => {

  const reasonOption = [
    { value: "Privacy Concerns", label: "Privacy Concerns" },
    { value: "Security Issues", label: "Security Issues" },
    { value: "Duplication of Accounts", label: "Duplication of Accounts" },
    { value: "Lack of Engagement", label: "Lack of Engagement" },
    { value: "Change in Interests", label: "Change in Interests" },
  ];

  const handleDiscard = () => {
    setSelectedReason("");
    setIsModalOpen(false);
  };


  const handleReasonSelect = async (selectedOption) => {
    try {
      setSelectedReason(selectedOption.value);
      setIsModalOpen(false);
  
      // Deactivate user with reason
      await userRequest.patch(`/userManagement/updatePanditOrDevoteeStatus`, {
        userId: id,
        userType: userType,
        activeStatus: false,
        deactivationReason: selectedOption.value,
      });
      fetchData();
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };
  

  return (
    <>
      {/* <p onClick={showModal} className={styles.reasonButton}>
       Reject
      </p> */}
      <Modal
        visible={isModalOpen}
        closable={false}
        footer={null}
        width={"29vw"}
        style={{ top: "35vh" }}
      >
        <div className={styles.lableName}>
          <p>Select Reason</p>
        </div>
        <div className={styles.reasonTwo}>
          <SelectComponent
            options={reasonOption}
            onSelect={handleReasonSelect}
            initialSelectedOption={selectedReason}
          />
        </div>
        <div className={styles.action}>
          <button onClick={handleDiscard}>Back</button>
        </div>
      </Modal>
    </>
  );
};

export default SelectDeactivateReason;
