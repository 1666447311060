import React, { useEffect, useState } from "react";
import styles from "./committeeType.module.css";
import { useNavigate } from "react-router-dom";
import { Popconfirm, Spin, Switch } from "antd";
import { userRequest } from "../../../../requestMethod.js";
import deleteIcon from "../../../../assets/images/deleteIcon.png";
import EditCommitteeType from "./EditCommitteType/EditCommitteeType.jsx";
import AddCommitteeType from "./AddCommitteeType/AddCommitteeType.jsx";
import { toast } from "sonner";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent.jsx";
import useDebounce from "../../../../utils/useDebounce.js";
import { formatDate } from "../../../../utils/DateFormat.js";
import { handleExportDoc } from "../../../../utils/exportDoc.js";


function CommitteeType() {
  const [devalayaTypeData, setDevalayaTypeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await userRequest.get(
        `/master/getMasterCommitteeType?search=${debouncedSearchQuery}&page=${page}`
      );
      setDevalayaTypeData(response?.data?.committeeType);
      setTotalPages(response?.data?.pagination?.totalPages);
      setPage(response?.data?.pagination?.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.delete(`/master/deleteMasterCommitteeType/${id}`);
      toast.success("Committee type deleted successfully");
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    handleExportDoc("/master/exportMasterCommitteeType", "MasterCommitteeType.xlsx");
  };

  return (
    <div className={styles.main}>
      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
        addComponent={() => <AddCommitteeType fetchData={fetchData} />}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <Spin spinning={loading} size="small">
            <div className={styles.tableContainerCommittee}>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Committee Type</th>
                    <th>Description</th>
                    <th>Last Update</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {devalayaTypeData.length > 0 ? (
                    devalayaTypeData.map((data, index) => (
                      <tr key={data?._id}>
                        <td>{(page - 1) * 10 + (index + 1)}</td>
                        <td>{data?.committeeType}</td>
                        <td>{data?.description}</td>

                        <td>
                          {/* {new Date(data?.updatedAt).toLocaleDateString()} */}
                          {formatDate(data?.updatedAt)}
                        </td>
                        <td className={styles.actionTd}>
                          <EditCommitteeType
                            fetchData={fetchData}
                            item={data}
                          />
                        {/*  <Popconfirm
                            title={`Are you sure to delete ${data?.committeeType}?`}
                            onConfirm={() => handleDelete(data?._id)}
                            okText="Yes"
                            cancelText="No"
                            overlayClassName={styles.largePopconfirm}
                          >
                            <img src={deleteIcon} alt="" />
                          </Popconfirm>*/}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
              {devalayaTypeData.length > 0 && (
                <div className={styles.footer}>
                  <div
                    className={styles.action}
                    style={{
                      visibility: totalPages === 1 ? "hidden" : "visible",
                    }}
                  >
                    <button
                      className={`${styles.actionButton} ${
                        page === 1 ? styles.disabled : styles.activeButton
                      }`}
                      onClick={handlePrevious}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                    <button
                      className={`${styles.actionButton} ${
                        page === totalPages
                          ? styles.disabled
                          : styles.activeButton
                      }`}
                      onClick={handleNext}
                      disabled={page === totalPages}
                    >
                      Next
                    </button>
                  </div>
                  <div className={styles.page}>
                    <span>{page}</span> <span>of</span>{" "}
                    <span>{totalPages}</span>
                  </div>
                </div>
              )}
       
          </Spin>
        </div>
        .
      </div>
    </div>
  );
}

export default CommitteeType;
