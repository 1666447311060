// import {configureStore} from '@reduxjs/toolkit';
// import adminReducer from '../features/slices/adminSlice';
// const reducer = {
//     admin : adminReducer
// }

// const store = configureStore({
//     reducer : reducer
// })
// export default store






// import { configureStore } from '@reduxjs/toolkit'
// import adminReducer from '../features/slices/adminSlice';
// import storage from 'redux-persist/lib/storage'

// import { combineReducers } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
// const rootReducer = combineReducers({
//     admin : adminReducer

// });

// const persistConfig = {
//   key: 'Tej',
//   version: 1,
//   storage
// };


// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
// });

// export const persistor = persistStore(store);
// export default store;





import { configureStore } from '@reduxjs/toolkit';
import adminReducer from '../features/slices/adminSlice';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { getConfig } from '../utils/getConfig';



const { STORAGE_KEY } = getConfig(); 

console.log(STORAGE_KEY)

const rootReducer = combineReducers({
    admin: adminReducer
});

const persistConfig = {
  key: STORAGE_KEY,
  version: 1,
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
export default store;
