import React, { useEffect, useState } from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";
import { userRequest } from "../../../../../requestMethod";

const ViewTask = ({ item, fetchData }) => {
  const [roles, setRoles] = useState([]);
  const rolesType = item?.roleId
    ? roles.find((option) => option?.value === item.roleId)?.label
    : undefined;

  const initialFormData = {
    title: { label: "Title", type: "text", value: item?.title || "" },
    description: { label: "Description", type: "text", value: item?.description || "" },
    taskType: { label: "Task Type", type: "text", value: item?.taskType || "" },
    apiEndpoint: { label: "API Endpoint", type: "text", value: item?.apiEndpoint || "" },
    method: { label: "Method", type: "text", value: item?.method || "" },
    screenFlow: { label: "ScreenFlow", type: "text", value: item?.screenFlow || "" },
    category: { label: "Category", type: "text", value: item?.category || "" },
    roleId: { label: "Role", type: "text", value: rolesType || "" },
  };

  const fetchUserRoles = async () => {
    try {
      const response = await userRequest.get("/userRoles/getAllUserRoles");
      setRoles(
        response.data.data.map((role) => ({
          value: role?._id,
          label: role?.roleName,
        }))
      );
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  useEffect(() => {
    fetchUserRoles();
  }, [item]);

  return (
    <ViewItem
      fetchData={fetchData}
      initialFormData={initialFormData}
      title="View Task"
      item={item}
    />
  );
};

export default ViewTask;
