import React, { useEffect, useState } from "react";
import styles from "./PoojaBooking.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../../requestMethod";
import { Spin } from "antd";
import SelectComponent from "../../../components/SelectComponent";
import { toast } from "sonner";
import useDebounce from "../../../utils/useDebounce";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { handleExportDoc } from "../../../utils/exportDoc";

function PoojaBooking({ bookedType }) {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedTemple, setSelectedTemple] = useState("");
  const [selectedPandit, setSelectedPandit] = useState("");
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [panditData, setPanditData] = useState([]);
  const [templeData, setTempleData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([{ label: "-", value: "-" }]); // Initial state
  const [formData, setFormData] = useState({
    country: "",
    state: "",
  });

  const statusOption = [
    { label: "REQUESTED", value: "REQUESTED" },
    { label: "SUCCESS", value: "SUCCESS" },
    { label: "PENDING", value: "PENDING" },
  ];

  const toggleFilterVisibility = () => {
    setFilterVisible(!isFilterVisible);
  };
  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handlePanditChange = (selectedStatus) => {
    setSelectedPandit(selectedStatus.value);
  };
  const handleTempleChange = (selectedStatus) => {
    setSelectedTemple(selectedStatus.value);
  };

  useEffect(() => {
    fetchData();
    panditList();
    templeList();
  }, []);

  useEffect(() => {
    fetchData();
  }, [
    debouncedSearchQuery,
    page,
    selectedPandit,
    selectedTemple,
    // selectedRegion,
    // selectedCountry,
    formData,
    bookedType,
  ]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/poojaManagement/getAdminListPoojaBooking?type=${bookedType}&search=${debouncedSearchQuery}&country=${formData?.country}&state=${formData?.state}&temple=${selectedTemple}&pandit=${selectedPandit}&page=${page}`
      );
      setData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setCurrentPage(response?.data?.pagination?.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const panditList = async () => {
    try {
      const response = await userRequest.get("/poojaManagement/getPanditList");
      const formattedData = response?.data?.data?.map((pandit) => ({
        value: pandit?.firstName,
        label: `${pandit?.firstName} ${pandit?.lastName}`,
      }));
      setPanditData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const templeList = async () => {
    try {
      const response = await userRequest.get("/poojaManagement/getTempleList");
      const formattedData = response?.data?.data?.map((temple) => ({
        value: temple?.templeName,
        label: `${temple?.templeName} `,
      }));
      setTempleData(formattedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = () => {
    handleExportDoc("/poojaManagement/exportPoojaBookingToExcel", "PoojaManagement.xlsx");
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = async () => {
    try {
      let response = await userRequest.get("/master/getMasterCountry");
      if (response?.status === 200) {
        let data = response?.data?.data;
        const options = data?.map((item) => ({
          label: item?.country,
          value: item?._id,
        }));
        setCountryData(options);
      }
    } catch (error) {
      console.log("Error country:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchState();
  }, []);
  const fetchState = async (countryId) => {
    if (!countryId) {
      console.error("countryId is undefined");
      setStateData([{ label: "-", value: "-" }]); // Show dash if no country is selected
      return;
    }
    try {
      let response = await userRequest.get(
        `/master/getMasterState?countryId=${countryId}`
      );
      if (response.status === 200) {
        let data = response?.data?.data;
        const options = data?.map((item) => ({
          label: item?.state,
          value: item?._id,
        }));
        setStateData(options);
      }
    } catch (error) {
      console.log("Error state:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };

  const handleCountrySelect = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      country: val.label,
      state: "", // Reset state when country changes
    }));
    fetchState(val.value); // Fetch states for the selected country
  };

  const handleStateSelect = (val) => {
    setFormData((prevState) => ({
      ...prevState,
      state: val.label,
    }));
  };

  return (
    <Spin spinning={loading} size="small">
      <div className={styles.tableMain}>
        <div
          className={styles.selectContainer}
          style={{ display: isFilterVisible ? "flex" : "none" }}
        >
          <div className={styles.singleSelect}>
            <p>Country</p>
            {/* <SelectComponent /> */}
            {/* <CountryDropdown
              value={selectedCountry}
              onChange={(val) => setSelectedCountry(val)}
            /> */}
            <SelectComponent
              options={countryData}
              onSelect={handleCountrySelect}
              initialSelectedOption={formData.country}
            />
          </div>
          <div className={styles.singleSelect}>
            <p>State</p>
            {/* <SelectComponent /> */}
            {/* <RegionDropdown
              country={selectedCountry}
              value={selectedRegion}
              onChange={(val) => setSelectedRegion(val)}
            /> */}
            <SelectComponent
              options={stateData}
              onSelect={handleStateSelect}
              initialSelectedOption={formData.state}
            />
          </div>
          <div className={styles.singleSelect}>
            <p>Pandit</p>

            <SelectComponent
              options={panditData}
              onSelect={handlePanditChange}
            />
          </div>
          <div className={styles.singleSelect}>
            <p>Temple</p>
            <SelectComponent
              options={templeData}
              onSelect={handleTempleChange}
            />
          </div>
        </div>
        <div className={styles.filterExport}>
          <p>All</p>
          <div className={styles.search}>
            <input
              type="text"
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search..."
            />
          </div>

          <div className={styles.export}>
            <p onClick={handleExport}>Export as CSV, PDF, Excel doc</p>
          </div>
          <div className={styles.filterDiv}>
            <p onClick={toggleFilterVisibility}>Filter</p>
          </div>
        </div>
        <div className={styles.poojaTableMain}>
          <table>
            <thead>
              <tr>
                <th>Booked Id</th>
                <th>Pooja Category</th>
                <th>Pooja Type</th>
                <th>Country</th>
                <th>State</th>
                <th>Temple</th>
                <th>Pandit</th>
                <th>Date</th>
                <th>Details</th>
                {bookedType === "Completed" ? <th>Status</th> : null}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <tr key={item._id}>
                    {/* <td>{(page - 1) * 10 + index + 1}</td> */}
                    <td>{item?._id}</td>

                    <td>{item?.pooja?.poojaCategory?.category}</td>
                    <td>{item?.pooja?.poojaName}</td>
                    <td>{item?.address?.country}</td>
                    <td>{item?.address?.state}</td>

                    <td>{item?.templeId?.templeName}</td>
                    <td>
                      {item?.panditId?.firstName} {item?.panditId?.lastName}
                    </td>
                    <td>{new Date(item?.createdAt).toLocaleDateString()}</td>

                    <td
                      className={styles.viewDetails}
                      onClick={() =>
                        navigate(`/poojabookingmanagement/details/${item._id}`)
                      }
                    >
                      View
                    </td>
                    {bookedType === "Completed" ? (
                      <td>
                        <p className={styles.completedStyle}>Completed</p>
                      </td>
                    ) : null}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {data.length > 0 && (
          <div className={styles.footer}>
            <div
              className={styles.action}
              style={{ visibility: totalPages === 1 ? "hidden" : "visible" }}
            >
              <button
                className={`${styles.actionButton} ${
                  page === 1 ? styles.disabled : styles.activeButton
                }`}
                onClick={handlePrevious}
                disabled={page === 1}
              >
                Previous
              </button>
              <button
                className={`${styles.actionButton} ${
                  page === totalPages ? styles.disabled : styles.activeButton
                }`}
                onClick={handleNext}
                disabled={page === totalPages}
              >
                Next
              </button>
            </div>
            <div className={styles.page}>
              <span>{currentPage}</span> <span>of</span>{" "}
              <span>{totalPages}</span>
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
}
export default PoojaBooking;
