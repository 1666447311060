import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./viewJobCategory.module.css";
import viewIcon from "../../../../../assets/images/viewIcons.png";

const ViewJobCategory = ({ item, selectedOption }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={viewIcon} alt="" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>{`View ${
            selectedOption === "workingSector"
              ? "Working Sector"
              : "Job Category"
          }`}</p>
        </div>
        <div className={styles.contentMain}>
        {selectedOption === "jobCategory" && (
            <>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>
              Working Sector
              </p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="workingSector"
                value={item?.workingSectorType?.workingSector}
                disabled
              />
            </div>
          </div>
            
            </>
          )
                  
                  }
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>
                {selectedOption === "workingSector"
                  ? "Working Sector"
                  : "Job Category"}
              </p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                // name="workingSector"
                value={
                  selectedOption === "workingSector"
                    ? item.workingSector
                    : item.jobTitle
                }
                disabled
              />
            </div>
          </div>
          {selectedOption === "jobCategory" && (
            <>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>
                Job code
              </p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="jobTitleCode"
                value={item.jobTitleCode}
                disabled
              />
            </div>
          </div>
            
            </>
          )
                  
                  }

          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Ok</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewJobCategory;
