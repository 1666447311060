// import React, { useState } from "react";
// import { Modal } from "antd";
// import styles from "./addCommitteeType.module.css";
// import { toast } from "sonner";
// import FileUpload from "../../../../../components/FileUpload/FileUpload";
// import { userRequest } from "../../../../../requestMethod";

// const AddCommitteeType = ({fetchData}) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const initialFormData = {
//     committeeType: '',
//     description:''
//   };
//   const [formData, setFormData] = useState(initialFormData);
//   const [resetKey, setResetKey] = useState(0);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };


//   const handleOk = async () => {
//     setIsModalOpen(false);
//     const { committeeType , description } = formData;

//     if (!committeeType.trim()) {
//       toast.error("Type is required and cannot be empty");
//       resetForm();
//       await fetchData()
//       return;
//     }

//     if (/\d/.test(committeeType)) {
//       toast.error("Type should not contain numbers");
//       resetForm();
//       await fetchData()
//       return;
//     }
//     if (!description.trim()) {
//       toast.error("Description is required and cannot be empty");
//       resetForm();
//       await fetchData()
//       return;
//     }
//     try {
//      await userRequest.post(`/master/createMasterCommitteeType`, formData);    
//       toast.success("Committee Type added successfully");
//       resetForm();
//       await fetchData()
//     } catch (error) {
//       console.log("Error creating Committee Type:", error);
//       // Show error message
//       toast.error("Failed to create Committee Type");
//     }
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//     resetForm();
//   };

//   const resetForm = () => {
//     setFormData(initialFormData);
//     setIsModalOpen(false);
//     setResetKey((prevKey) => prevKey + 1);
//   };

//   return (
//     <>
//     <button className={styles.addNewBtn} onClick={showModal}>Add New</button>
//       <Modal
//         visible={isModalOpen}
//         onCancel={handleCancel}
//         onOk={handleOk}
       
//         className={styles.modal}
//       >
//         <div className={styles.modalTitle}>
//           <p>Add Committee Type</p>
//         </div>
//         <div className={styles.contentMain}>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Committee Type</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="committeeType"
//                 value={formData?.committeeType}
//                 onChange={handleChange}
//                 key={`type-${resetKey}`}
//               />
//             </div>
//           </div>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Description</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="description"
//                 value={formData?.description}
//                 onChange={handleChange}
//                 key={`type-${resetKey}`}
//               />
//             </div>
//           </div>
//           <div className={styles.modalButton}>
//             <button onClick={handleCancel}>Cancel</button>
//             <button onClick={handleOk}>Add</button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default AddCommitteeType;


import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";

const AddCommitteeType = ({ fetchData }) => {
  const initialFormData = {
    committeeType: '',
    description:''
  };

  const apiEndpoint = "/master/createMasterCommitteeType";
  const formFields = [{ name: "committeeType", label: "Committee Type", type: "text" } , { name: "description", label: "Description", type: "text" }];
  


  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Committee Type"
    />
  );
};

export default AddCommitteeType;