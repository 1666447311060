import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";
import { userRequest } from "../../../../../requestMethod";

const AddAbhiruchi = ({ fetchData, selectedOption }) => {
  const initialFormData =
    selectedOption === "abhiruchiType"
      ? {
          type: "",
        }
      : {
          abhiruchiType: "",
          name: "",
          region: "",
          description: "",
        };

  const fetchAbhiruchiType = async () => {
    try {
      const response = await userRequest.get(`/master/getMasterAbhiruchiType`);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const apiEndpoint =
    selectedOption === "abhiruchiType"
      ? "/master/createMasterAbhiruchiType"
      : "/master/createMasterAbhiruchi";

  const formFields =
    selectedOption === "abhiruchiType"
      ? [{ name: "type", label: "Type", type: "text" }]
      : [
          {
            name: "abhiruchiType",
            label: "Abhiruchi Type",
            type: "select",
            optionsKey: "abhiruchiType",
            optionValueKey: "_id",
            optionLabelKey: "type",
          },
          { name: "name", label: "Name", type: "text" },
          { name: "region", label: "Region", type: "text" },
          { name: "description", label: "Description", type: "text" },
        ];

  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title={
        selectedOption === "abhiruchiType"
          ? "Abhiruchi Type"
          : "Abhiruchi"
      }
      fetchDataOptions={{
        abhiruchiType: fetchAbhiruchiType,
      }}
    />
  );
};

export default AddAbhiruchi;
