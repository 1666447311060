import React, { useEffect, useState } from "react";
import styles from "./Article.module.css";
import { userRequest } from "../../../../requestMethod";
import useDebounce from "../../../../utils/useDebounce";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import { useNavigate } from "react-router-dom";
import viewIcon from "../../../../assets/images/viewIcons.png";
import editIcon from "../../../../assets/images/edit.png";
import deleteIcon from "../../../../assets/images/delete.png";
import { Popconfirm, Spin, Switch } from "antd";
import PageHeader from "../../../../components/PageHeader/PageHeader";

function Articles() {
  const limit = 10;
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page]);

  const fetchData = async () => {
    try {
      const response = await userRequest.get(
        `/admin/static/getAllGuideArticles?search=${debouncedSearchQuery}&page=${page}&limit=${limit}`
      );
      setData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setCurrentPage(response?.data?.pagination?.currentPage);
      setPage(response?.data?.pagination?.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleView = (id, item) => {
    navigate(`/staticcontents/guidedarticles/viewArticle/${id}`, {
      state: { item },
    });
  };
  
  const handleEdit = (id, item) => {
    navigate(`/staticcontents/guidedarticles/editArticle/${id}`, {
      state: { item },
    });
  };

  const handleDelete = async (id) => {
    try {
      await userRequest.delete(`/admin/static/deleteGuideArticle/${id}`, {
        deleted: true,
      });
      fetchData();
    } catch (error) {
      console.error("Error deleting article:", error);
    }
  };

  const columns = [
    {
      title: "S.No.",
      key: "serialNumber",
      render: (item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Title",
      key: "title",
      render: (item) => `${item?.title?.slice(0, 10)}...`,
    },
    {
      title: "Content",
      key: "content",
      render: (item) => `${item?.content?.slice(0, 10)}...`,
    },
    {
      title: "Category",
      key: "category",
      render: (item) => `${item?.category?.name}`,
    },
    {
      title: "Tags",
      key: "tags",
      // render: (item) => `${item?.tags?.map((ele)=>{ele})}`,
      render: (item) => `${item?.tags?.map((ele) => ele).join(", ")}...`,
    },
    {
      title: "Language",
      key: "language_code",
      // render: (item) => `${item?.tags?.map((ele)=>{ele})}`,
      render: (item) => `${item?.language_code}`,
    },
  ];

  const customWidths = {
    serialNumber: "8vw",
    question: "20vw",
    answer: "20vw",
    category: "20vw",
    tags: "30vw",
  };

  const handleAddNew = () => {
    navigate("/staticcontents/guidedarticles/createArticle");
  };

  return (
    <>
      <PageHeader
        //  breadcrumbs={[
        //   {
        //     label:"Articles",
        //     path:"/staticcontents/faq/createFaq"
        //   },
        // ]}
        buttonLabel="Add New"
        onButtonClick={() => handleAddNew()}
        backgroundWhite={true}
      />
      <div className={styles.contentContainer}>
        <div className={styles.faqSearch}>
          <input
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>

        {/* <SearchFilterComponent setSearchQuery={setSearchQuery}  /> */}

        <TableComponent
          columns={columns}
          data={data}
          loading={loading}
          onDelete={() => {}}
          currentPage={currentPage}
          totalPages={totalPages}
          onNext={handleNext}
          onPrevious={handlePrevious}
          customWidths={customWidths}
          actions={[
            (props) => (
              <img
                className={styles.viewDetails}
                src={viewIcon}
                alt="view"
                onClick={() => handleView(props?.item?._id, props.item)}
              />
            ),
            (props) => (
              <img
                className={styles.editDetails}
                src={editIcon}
                alt="edit"
                onClick={() => handleEdit(props?.item?._id, props.item)}
              />
            ),
            (props) => (
              <Popconfirm
                title={`Are you sure to delete ${props?.item?.title}?`}
                onConfirm={() => handleDelete(props?.item?._id)}
                okText="Yes"
                cancelText="No"
                overlayClassName={styles.largePopconfirm}
              >
                <img src={deleteIcon} alt="delete" />
              </Popconfirm>
            ),
          ]}
        />
      </div>
    </>
  );
}

export default Articles;
