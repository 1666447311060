import React from "react";
import styles from "./masterDevotee.module.css";
import MenuItems from "../../../components/MenuItems/MenuItems";
import Raasi from "./Raasi/Raasi";
import Nakshatram from "./Nakshatram/Nakshatram";
import Abhiruchi from "./Abhiruchi/Abhiruchi";
import Relationship from "./RelationShip/Relationship";
import RelationshipStatus from "./RelationShipStatus/RelationshipStatus";
import EducationType from "./EducationType/EducationType";
import JobCategory from "./JobCategory/JobCategory";
import PageHeader from "../../../components/PageHeader/PageHeader";


function MasterDevotee() {
  const menuItems = [
    { label: "Raasi", state: "Raasi" },
    { label: "Nakshatram", state: "Nakshatram" },
    { label: "Abhiruchi", state: "Abhiruchi" },
    { label: "Relationship", state: "Relationship" },
    { label: "Relationship Status", state: "RelationshipStatus" },
    { label: "Education Type", state: "EducationType" },
    { label: "Job Category", state: "JobCategory" },
  ];
  const contentMap = {
    Raasi: <Raasi />,
    Nakshatram: <Nakshatram />,
    Abhiruchi: <Abhiruchi />,
    Relationship: <Relationship />,
    RelationshipStatus: <RelationshipStatus />,
    EducationType: <EducationType />,
    JobCategory: <JobCategory />,
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Master Data", path: "#" },
          { label: "Devotee", path: "#" },
        ]}
      />

      <MenuItems menuItems={menuItems} contentMap={contentMap} />
    </div>
  );
}

export default MasterDevotee;
