import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";

const AddSamhitaCategories = ({ fetchData }) => {
  const initialFormData = {
    category: "",
  };

  const apiEndpoint = "/master/createMasterSamhitaCategory";
  const formFields = [{ name: "category", label: "Category", type: "text" }];
  


  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Samhita Type"
    />
  );
};

export default AddSamhitaCategories;