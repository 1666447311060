import React, { useEffect, useState } from "react";
import styles from "./createTejPoints.module.css";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../../components/SelectComponent";
import PageHeader from "../../../../components/PageHeader/PageHeader";
import { useForm, Controller } from "react-hook-form";
import LocationSearchInput from "../../../../components/LocationSearch/LocationSearchInput";

function CreateTejPoints() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      roleId: "",
      loginPoint: "",
      templePoint: "",
      referPoint: "",
      utsavPoint: "",
      bookingPoint: "",
      completeKycPoint: "",
      geographicalScope: "",
    },
  });
  const [inputValue, setInputValue] = useState({});
  const [selectedCountries, setSelectedCountries] = useState([]);
  const geographicalScopes = [
    "Local",
    "Regional",
    "National",
    "International",
    "Global",
  ];
  const selectedGeographicalScope = watch("geographicalScope");

  console.log(selectedGeographicalScope, "selectedGeographicalScope");

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await userRequest.get("/userRoles/getAllUserRoles");
        setRoles(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);

  const roleOptions = roles.map((role) => ({
    label: role.roleName,
    value: role._id,
  }));

  const saveData = async (data) => {
    try {
      await userRequest.post("/tejPoints/createTejPoints", data);
      reset();
      toast.success("Saved successfully!");
      navigate("/svarnamanagement");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to save data. Please try again later.";
      toast.error(errorMessage);
      console.error("Error saving data:", error);
    }
  };

  useEffect(() => {
    if (
      selectedGeographicalScope === "International" &&
      Array.isArray(selectedCountries)
    ) {
      const firstCountry = selectedCountries[0]?.country || "";
      setValue("geolocation", selectedCountries);
      setValue("country", firstCountry);
    }
  }, [selectedGeographicalScope, selectedCountries, setValue]);

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Svarna Management", path: "/svarnamanagement" },
          { label: "Create Tej Points", path: "#" },
        ]}
      />
      <div className={styles.contentContainer}>
        <form onSubmit={handleSubmit(saveData)}>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Role</p>
              </div>
              <div className={styles.fieldInput}>
                <Controller
                  name="roleId"
                  control={control}
                  rules={{ required: "Role is required" }}
                  render={({ field }) => (
                    <SelectComponent
                      options={roleOptions}
                      onSelect={(option) => field.onChange(option.value)}
                    />
                  )}
                />
                {errors.roleId && (
                  <p className={styles.error}>{errors.roleId.message}</p>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Add Temple Point</p>
              </div>
              <div className={styles.fieldInput}>
                <Controller
                  name="templePoint"
                  control={control}
                  rules={{
                    required: "Temple point is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Enter a valid number",
                    },
                  }}
                  render={({ field }) => (
                    <input {...field} placeholder="Enter" />
                  )}
                />
                {errors.templePoint && (
                  <p className={styles.error}>{errors.templePoint.message}</p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Login Point</p>
              </div>
              <div className={styles.fieldInput}>
                <Controller
                  name="loginPoint"
                  control={control}
                  rules={{
                    required: "Login point is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Enter a valid number",
                    },
                  }}
                  render={({ field }) => (
                    <input {...field} placeholder="Enter" />
                  )}
                />
                {errors.loginPoint && (
                  <p className={styles.error}>{errors.loginPoint.message}</p>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Create Utsav Point</p>
              </div>
              <div className={styles.fieldInput}>
                <Controller
                  name="utsavPoint"
                  control={control}
                  rules={{
                    required: "Utsav point is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Enter a valid number",
                    },
                  }}
                  render={({ field }) => (
                    <input {...field} placeholder="Enter" />
                  )}
                />
                {errors.utsavPoint && (
                  <p className={styles.error}>{errors.utsavPoint.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Refer Point</p>
              </div>
              <div className={styles.fieldInput}>
                <Controller
                  name="referPoint"
                  control={control}
                  rules={{
                    required: "Refer point is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Enter a valid number",
                    },
                  }}
                  render={({ field }) => (
                    <input {...field} placeholder="Enter" />
                  )}
                />
                {errors.referPoint && (
                  <p className={styles.error}>{errors.referPoint.message}</p>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Complete KYC Point</p>
              </div>
              <div className={styles.fieldInput}>
                <Controller
                  name="completeKycPoint"
                  control={control}
                  rules={{
                    required: "Complete KYC point is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Enter a valid number",
                    },
                  }}
                  render={({ field }) => (
                    <input {...field} placeholder="Enter" />
                  )}
                />
                {errors.completeKycPoint && (
                  <p className={styles.error}>
                    {errors.completeKycPoint.message}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Booking Point</p>
              </div>
              <div className={styles.fieldInput}>
                <Controller
                  name="bookingPoint"
                  control={control}
                  rules={{
                    required: "Booking point is required",
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Enter a valid number",
                    },
                  }}
                  render={({ field }) => (
                    <input {...field} placeholder="Enter" />
                  )}
                />
                {errors.bookingPoint && (
                  <p className={styles.error}>{errors.bookingPoint.message}</p>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <label className={styles.fieldLabel}>Geographical Scope</label>
              <div className={styles.fieldInput}>
                <Controller
                  name="geographicalScope"
                  control={control}
                  rules={{ required: "Geographical Scope is required" }}
                  render={({ field }) => (
                    <SelectComponent
                      options={geographicalScopes.map((geo) => ({
                        value: geo,
                        label: geo,
                      }))}
                      onSelect={(option) => field.onChange(option.value)}
                    />
                  )}
                />
                {errors.geographicalScope && (
                  <p className={styles.error}>
                    {errors.geographicalScope.message}
                  </p>
                )}
              </div>
            </div>
          </div>

          {selectedGeographicalScope !== "Global" && (
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}></div>
              <div className={styles.feildRight}>
                <label className={styles.fieldLabel}>Geo Location *</label>
                <Controller
                  name="geolocation"
                  control={control}
                  rules={{
                    validate: () => {
                      const country = watch("country");
                      const state = watch("state");
                      const city = watch("city");

                      switch (selectedGeographicalScope) {
                        case "Local":
                          if (!country || !state || !city) {
                            return "Country, state, and city are required for Local scope.";
                          }
                          return true;
                        case "Regional":
                          if (!country || !state || city) {
                            return "Only country and state are required for Regional scope.";
                          }
                          return true;

                        case "National":
                          if (!country || state || city) {
                            return "Only Country is required for National scope.";
                          }
                          return true;
                        case "International":
                          if (!country || state || city) {
                            return "Only Country is required for International scope.";
                          }
                          return true;
                        default:
                          return true;
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => {
                    const handleAddCountry = (location) => {
                      const {
                        country,
                        fullAddress,
                        coordinates,
                        country_code,
                      } = location;

                      if (!country) return;
                      if (
                        !selectedCountries.some(
                          (item) => item.country === country
                        )
                      ) {
                        const newCountry = {
                          fullAddress,
                          country,
                          geolocation: {
                            type: "Point",
                            coordinates: [
                              coordinates?.lng || 0,
                              coordinates?.lat || 0,
                            ],
                          },
                          country_code,
                        };
                        setSelectedCountries((prevSelected) => {
                          const updatedCountries = [
                            ...prevSelected,
                            newCountry,
                          ];
                          return updatedCountries;
                        });
                      }
                      field.onChange(location);
                    };

                    const handleRemoveCountry = (countryToRemove) => {
                      const updatedCountries = selectedCountries.filter(
                        (item) => item.country !== countryToRemove
                      );
                      setSelectedCountries(updatedCountries);
                      setValue("geoLocation", updatedCountries);
                    };

                    return (
                      <div className={styles.fieldInput}>
                        {selectedGeographicalScope !== "International" && (
                          <LocationSearchInput
                            setInputValue={(location) => {
                              const {
                                country,
                                state,
                                city,
                                placeId,
                                coordinates,
                                fullAddress,
                                country_code,
                                state_code,
                              } = location;

                              const geolocation = [
                                {
                                  fullAddress,
                                  country,
                                  state,
                                  city,
                                  placeId,
                                  geolocation: {
                                    type: "Point",
                                    coordinates: [
                                      coordinates?.lng || 0,
                                      coordinates?.lat || 0,
                                    ],
                                  },
                                  country_code,
                                  state_code,
                                },
                              ];
                              setValue("country", country || "");
                              setValue("state", state || "");
                              setValue("city", city || "");
                              setValue("geolocation", geolocation);
                              field.onChange(location);
                            }}
                          />
                        )}

                        {selectedGeographicalScope == "International" && (
                          <>
                            <LocationSearchInput
                              setInputValue={handleAddCountry}
                              selectedGeographicalScope={
                                selectedGeographicalScope
                              }
                            />
                            <div className={styles.selectedChips}>
                              {selectedCountries.map((item) => (
                                <span
                                  key={item.country}
                                  className={styles.chip}
                                >
                                  {item.country}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveCountry(item.country)
                                    }
                                    className={styles.removeChip}
                                  >
                                    ×
                                  </button>
                                </span>
                              ))}
                            </div>
                          </>
                        )}
                        {error && (
                          <p className={styles.error}>{error.message}</p>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          )}
          <div className={styles.footer}>
            <button type="button" onClick={() => reset()}>
              Discard
            </button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateTejPoints;
