import React, { useState } from "react";
import styles from "./ViewKYCDetails.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { formatDate } from "../../../utils/DateFormat";
import { userRequest } from "../../../requestMethod";
import { toast } from "sonner";
import PageHeader from "../../../components/PageHeader/PageHeader";
import ImageZoom from "../../../components/Modals/ImageZoom/ImageZoom";

function ViewChildKYCDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { child } = location.state || {};
  const isLoading = false;

  const handleVerifying = async (status) => {
    try {
      if (child?.status === "Requested") {
        await userRequest.put(
          `/childKyc/verifiyChildKycByAdmin/${child?._id}`,
          { status: status }
        );
        navigate("/childkycmanagement");
        toast.success(`Verified successfully`);
      }
    } catch (error) {
      console.error(`Error `, error);
      toast.error(`Failed to ${child?.status}`);
    }
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Child KYC Management", path: "/childkycmanagement" },
          {
            label: "Child KYC Details",
            path: "/childkycmanagement/viewchildkycdetails",
          },
        ]}
        buttonLabel={child?.status === "Verified" ? "Verified" : "Verify"}
        onButtonClick={() => handleVerifying("Verified")}
        disabled={child?.status === "Verified" ? true : false}
      />
      <Spin spinning={isLoading} size="small">
        <div className={styles.detailMainContainer}>
          <div className={styles.rowContainer}>
            <div className={styles.detailContainer}>
              <div className={styles.detailsHeading}>
                <ul>
                  <li>Child Basic Details</li>
                </ul>
              </div>
              <div className={styles.details}>
                <div className={styles.profileImage}>
                  <div className={styles.profileImageCircle}>
                    <img src={child?.childPhoto} alt="childPhoto" />
                  </div>
                </div>
                <div className={styles.detailsContent}>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Full Name</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>
                        {child?.childDetails?.firstName}{" "}
                        {child?.childDetails?.lastName}
                      </p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Username</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p> {child?.childDetails?.userName}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>KYC Date</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{formatDate(child?.createdAt)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.detailContainer} style={{ marginTop: "3%" }}>
              <div className={styles.details}>
                <div className={styles.detailsContent}>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Age</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>
                        {calculateAge(child?.childDetails?.dob) ||
                          "Dob not available"}
                      </p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Gender</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{child?.childDetails?.gender}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Supervised by</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>
                        {child?.parentDetails?.firstName}{" "}
                        {child?.parentDetails?.lastName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rowContainer} style={{ marginTop: "3%" }}>
            <div className={styles.detailContainer}>
              <div className={styles.detailsHeading}>
                <ul>
                  <li>Documents</li>
                </ul>
              </div>
              <div className={styles.details}>
                <div className={styles.detailsContent}>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Document Type</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{child?.documents?.type}</p>
                    </div>
                  </div>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Aadhar Number</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{child?.documents?.aadharNumber}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.detailContainer} style={{ marginTop: "3%" }}>
              <div className={styles.details}>
                <div className={styles.detailsContent}>
                  <div className={styles.singleDetail}>
                    <div className={styles.singleDetailLeft}>
                      <p>Issuing Country</p>
                    </div>
                    <div className={styles.singleDetailRight}>
                      <p>{child?.documents?.issuingCountry}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.rowContainer} style={{ marginTop: "3%" }}>
            <div className={styles.documentImageContainer}>
              <div className={styles.detailsHeading}>
                <ul>
                  <li>Document Images</li>
                </ul>
              </div>
              <div className={styles.singleDocumentContainer}>
                <div className={styles.documentSide}>
                  <p>Front Side</p>
                </div>
                <div className={styles.documentImage}>
                  <ImageZoom imageSrc={child?.documentImages?.front} />
                </div>
              </div>
              <div
                className={styles.singleDocumentContainer}
                style={{ marginTop: "3%" }}
              >
                <div className={styles.documentSide}>
                  <p>Back Side</p>
                </div>
                <div className={styles.documentImage}>
                  <ImageZoom imageSrc={child?.documentImages?.back} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default ViewChildKYCDetails;

function calculateAge(dob) {
  const dobDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - dobDate.getFullYear();
  const monthDiff = today.getMonth() - dobDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < dobDate.getDate())
  ) {
    age--;
  }

  return age;
}
