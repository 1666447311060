import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./reason.module.css";
import SelectComponent from "../../../../components/SelectComponent";

const SelectReason = ({selectedReason,setSelectedReason,isModalOpen,setIsModalOpen,setPanditDetailsStatus}) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const reasonOption = [
    { value: "Visual Not Clear", label: "Visual Not Clear" },
    { value: "Photo Not Clear", label: "Photo Not Clear" },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleDiscard = () => {
    setSelectedReason("");
    setIsModalOpen(false);
    setPanditDetailsStatus("")
  };

  const handleReasonSelect = (selectedOption) => {
    setSelectedReason(selectedOption.value);
    setIsModalOpen(false);
  };

  return (
    <>
      {/* <p onClick={showModal} className={styles.reasonButton}>
       Reject
      </p> */}
      <Modal
        visible={isModalOpen}
        closable={false}
        footer={null}
        width={"29vw"}
        style={{ top: "35vh" }}
      >
        <div className={styles.lableName}>
          <p>Select Reason</p>
        </div>
        <div className={styles.reasonTwo}>
          <SelectComponent
            options={reasonOption}
            onSelect={handleReasonSelect}
            initialSelectedOption={selectedReason}
          />
        </div>
        <div className={styles.action}>
          <button onClick={handleDiscard}>Back</button>
        </div>
      </Modal>
    </>
  );
};

export default SelectReason;
