import React, { useEffect, useState } from "react";
import styles from "./ViewArticle.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeaderTwo from "../../../../../components/PageHeader/PageHeaderTwo";

function ViewArticle() {
  const navigate = useNavigate();
  const location = useLocation();
  const item = location?.state?.item;
  let tagValue = item?.tags?.map((ele) => ele).join(",");
  const initialFormData = {
    title: item?.title || "",
    content: item?.content || "",
    category: item?.category || "",
    tagValue: tagValue || "",
    seo_title: item?.seo?.seo_title || "",
    seo_description: item?.seo?.seo_description || "",
    slug: item?.seo?.slug || "",
    language_code: item?.language_code || "",
    is_published: item?.is_published || "",
    history: item?.history || "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleDiscard = () => {
    navigate("/staticcontents/guidedarticles");
  };

  const breadcrumb = [
    { 
      label: "Article",
      path: "/staticcontents/guidedarticles"
    },
    { 
      label: "View Article",
      path: "#"
    }
  ];

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
        onDiscardClick={handleDiscard}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.createContainer}>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Title</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Content</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="content"
                    value={formData.content}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Category</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="category"
                    value={formData.category?.name}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Tags</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="tags"
                    value={formData.tagValue}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Seo Title</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="seo_title"
                    value={formData.seo_title}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Seo Description</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="seo_description"
                    value={formData.seo_description}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Seo Slug</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="slug"
                    value={formData.slug}
                    disabled
                  />
                </div>
              </div>
              <div className={styles.feildRight}>
                <div className={styles.fieldLabel}>
                  <p>Language</p>
                </div>
                <div className={styles.fieldInput}>
                  <input
                    type="text"
                    name="language_code"
                    value={formData.language_code}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer}>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Is Published</p>
                </div>
                <div className={styles.fieldInput}>
                  {formData?.is_published ? "Published":"Not Published"}
                </div>
              </div>
            </div>
            <div className={styles.footer}></div>
          </div>
        </div>

        {/* <div style={{ marginTop: "1rem" }}>
          <label style={{ color: "black" }}>History</label>
        </div> */}
      </div>
    </div>
  );
}

export default ViewArticle;
