import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import styles from "./createBanner.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import SelectComponent from "../../../components/SelectComponent";
import { userRequest } from "../../../requestMethod";
import FileUpload from "../../../components/FileUpload/FileUpload";
import PageHeaderTwo from "../../../components/PageHeader/PageHeaderTwo";
import LocationSearchInput from "../../../components/LocationSearch/LocationSearchInput";

function CreateBanner() {
  const geographicalScopes = [
    "Local",
    "Regional",
    "National",
    "International",
    "Global",
  ];

  const navigate = useNavigate();
  const targetAudiences = ["Existing customers", "New customers"];
  const languages = ["Hindi", "English", "Sanskrit"];
  const [inputValue, setInputValue] = useState({});
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      imageUrl: "",
      startDate: "",
      endDate: "",
      content: "",
      linkUrl: "",
      targetAudience: "",
      geographicalScope: "",
      language: "",
      // geolocation: [],
    },
  });

  useEffect(() => {
    setValue("geolocation", selectedCountries);
    setValue("country", selectedCountries[0]?.country || "");
  }, [selectedCountries, setValue]);

  const onSubmit = async (data) => {
    try {
      await userRequest.post("master/createMasterBanner", data);
      toast.success("Saved successfully!");
      reset();
      navigate("/bannerManagement");
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    }
  };

  const handleDiscard = () => {
    navigate("/bannermanagement");
  };
  const handleDateChange = (e, InputName) => {
    if (InputName == "startDate") {
      setStartDate(e.target.value);
      setEndDate("");
    } else {
      setEndDate(e.target.value);
    }
  };
  const getMinEndDate = () => {
    const baseDate = startDate ? new Date(startDate) : new Date();
    baseDate.setDate(baseDate.getDate() + 1);
    return baseDate.toISOString().split("T")[0];
  };
  const breadcrumb = [
    { label: "Banner Management", path: "/bannermanagement" },
    { label: "Create Banner", path: "/bannermanagement/create" },
  ];
  const selectedGeographicalScope = watch("geographicalScope");

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={breadcrumb}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={handleDiscard}
        onSaveClick={handleSubmit(onSubmit)}
      />
      <div className={styles.contentContainer}>
        <div className={styles.createContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <label className={styles.fieldLabel}>Banner Title *</label>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  {...register("title", {
                    required: "Title is required",
                    pattern: {
                      value: /^[A-Za-z\s]*$/,
                      message: "Please enter only letters",
                    },
                  })}
                  placeholder="Enter"
                />
              </div>
              {errors.title && (
                <p className={styles.error}>{errors.title.message}</p>
              )}
            </div>
            <div className={styles.feildRight}>
              <label className={styles.fieldLabel}>Image URL *</label>
              <div className={styles.fieldInput}>
                <Controller
                  name="imageUrl"
                  control={control}
                  rules={{ required: "Image URL is required" }}
                  render={({ field }) => (
                    <FileUpload
                      label="Upload Image"
                      onChange={(fieldName, file) => field.onChange(file)}
                    />
                  )}
                />
              </div>
              {errors.imageUrl && (
                <p className={styles.error}>{errors.imageUrl.message}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <label className={styles.fieldLabel}>Content *</label>
              <div className={styles.fieldInput}>
                <textarea
                  {...register("content", {
                    required: "Content is required",
                  })}
                  placeholder="Enter"
                />
              </div>
              {errors.content && (
                <p className={styles.error}>{errors.content.message}</p>
              )}
            </div>
            <div className={styles.feildRight}>
              <label className={styles.fieldLabel}>Link URL *</label>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  {...register("linkUrl", {
                    required: "Link URL is required",
                  })}
                  placeholder="Enter"
                />
              </div>
              {errors.linkUrl && (
                <p className={styles.error}>{errors.linkUrl.message}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <label className={styles.fieldLabel}>Target Audience *</label>
              <div className={styles.fieldInput}>
                <Controller
                  name="targetAudience"
                  control={control}
                  rules={{ required: "Target audience is required" }}
                  render={({ field }) => (
                    <SelectComponent
                      options={targetAudiences.map((aud) => ({
                        value: aud,
                        label: aud,
                      }))}
                      onSelect={(selectedOption) =>
                        field.onChange(selectedOption.value)
                      }
                    />
                  )}
                />
              </div>
              {errors.targetAudience && (
                <p className={styles.error}>{errors.targetAudience.message}</p>
              )}
            </div>
            <div className={styles.feildRight}>
              <label className={styles.fieldLabel}>Start Date *</label>
              <div className={styles.fieldInput}>
                <input
                  type="date"
                  {...register("startDate", {
                    required: "Start Date is required",
                  })}
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(e) => handleDateChange(e, "startDate")}
                />
              </div>
              {errors.startDate && (
                <p className={styles.error}>{errors.startDate.message}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <label className={styles.fieldLabel}>Geographical Scope *</label>
              <div className={styles.fieldInput}>
                <Controller
                  name="geographicalScope"
                  control={control}
                  rules={{ required: "Geographical Scope is required" }}
                  render={({ field }) => (
                    <SelectComponent
                      options={geographicalScopes.map((geo) => ({
                        value: geo,
                        label: geo,
                      }))}
                      onSelect={(selectedOption) => {
                        field.onChange(selectedOption.value);
                        setInputValue({});
                      }}
                    />
                  )}
                />
              </div>
              {errors.geographicalScope && (
                <p className={styles.error}>
                  {errors.geographicalScope.message}
                </p>
              )}
            </div>
            <div className={styles.feildRight}>
              <label className={styles.fieldLabel}>End Date *</label>
              <div className={styles.fieldInput}>
                <input
                  type="date"
                  {...register("endDate", {
                    required: "End Date is required",
                  })}
                  value={endDate}
                  onChange={(e) => handleDateChange(e, "endDate")}
                  min={getMinEndDate()}
                />
              </div>
              {errors.endDate && (
                <p className={styles.error}>{errors.endDate.message}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <label className={styles.fieldLabel}>Language *</label>
              <div className={styles.fieldInput}>
                <Controller
                  name="language"
                  control={control}
                  rules={{ required: "Language is required" }}
                  render={({ field }) => (
                    <SelectComponent
                      options={languages.map((lang) => ({
                        value: lang,
                        label: lang,
                        key: lang,
                      }))}
                      onSelect={(selectedOption) =>
                        field.onChange(selectedOption.value)
                      }
                    />
                  )}
                />
              </div>
              {errors.language && (
                <p className={styles.error}>{errors.language.message}</p>
              )}
            </div>

            {selectedGeographicalScope !== "Global" && (
              <div className={styles.feildRight}>
                <label className={styles.fieldLabel}>Geo Location *</label>
                <Controller
                  name="geoLocation"
                  control={control}
                  rules={{
                    validate: () => {
                      const country = watch("country");
                      const state = watch("state");
                      const city = watch("city");
                      switch (selectedGeographicalScope) {
                        case "Local":
                          if (!country || !state || !city) {
                            return "Country, state, and city are required for Local scope.";
                          }
                          return true;
                        case "Regional":
                          if (!country || !state || city) {
                            return "Only country and state are required for Regional scope.";
                          }
                          return true;

                        case "National":
                          if (!country || state || city) {
                            return "Only Country is required for National scope.";
                          }
                          return true;
                        case "International":
                          if (!country || state || city) {
                            return "Only Country is required for International scope.";
                          }
                          return true;
                        default:
                          return true;
                      }
                    },
                  }}
                  render={({ field, fieldState: { error } }) => {
                    const handleAddCountry = (location) => {
                      const {
                        country,
                        fullAddress,
                        coordinates,
                        country_code,
                      } = location;

                      if (!country) return;
                      if (
                        !selectedCountries.some(
                          (item) => item.country === country
                        )
                      ) {
                        const newCountry = {
                          fullAddress,
                          country,
                          geolocation: {
                            type: "Point",
                            coordinates: [
                              coordinates?.lng || 0,
                              coordinates?.lat || 0,
                            ],
                          },
                          country_code,
                        };
                        setSelectedCountries((prevSelected) => {
                          const updatedCountries = [
                            ...prevSelected,
                            newCountry,
                          ];
                          return updatedCountries;
                        });
                      }
                      field.onChange(location);
                    };

                    const handleRemoveCountry = (countryToRemove) => {
                      const updatedCountries = selectedCountries.filter(
                        (item) => item.country !== countryToRemove
                      );
                      setSelectedCountries(updatedCountries);
                      setValue("geoLocation", updatedCountries);
                    };

                    return (
                      <div className={styles.fieldInput}>
                        {selectedGeographicalScope !== "International" && (
                          <LocationSearchInput
                            setInputValue={(location) => {
                              const {
                                country,
                                state,
                                city,
                                placeId,
                                coordinates,
                                fullAddress,
                                country_code,
                                state_code,
                              } = location;
                              setValue("country", country || "");
                              setValue("state", state || "");
                              setValue("city", city || "");
                              setValue("geolocation", [
                                {
                                  fullAddress,
                                  country,
                                  state,
                                  city,
                                  placeId,
                                  geolocation: {
                                    type: "Point",
                                    coordinates: [
                                      coordinates?.lng || 0,
                                      coordinates?.lat || 0,
                                    ],
                                  },
                                  country_code,
                                  state_code,
                                },
                              ]);

                              field.onChange(location);
                            }}
                          />
                        )}

                        {selectedGeographicalScope == "International" && (
                          <>
                            <LocationSearchInput
                              setInputValue={handleAddCountry}
                              selectedGeographicalScope={
                                selectedGeographicalScope
                              }
                            />
                            <div className={styles.selectedChips}>
                              {selectedCountries.map((item) => (
                                <span
                                  key={item.country}
                                  className={styles.chip}
                                >
                                  {item.country}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleRemoveCountry(item.country)
                                    }
                                    className={styles.removeChip}
                                  >
                                    ×
                                  </button>
                                </span>
                              ))}
                            </div>
                          </>
                        )}
                        {error && (
                          <p className={styles.error}>{error.message}</p>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateBanner;
