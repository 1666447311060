import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";
import { userRequest } from "../../../../../requestMethod";

const EditCity = ({ item, fetchData }) => {
  const initialFormData = {
    cityId: item?.cityId,
    stateId: item?.stateId?._id,
    city: item?.city,
    latitude: item?.latitude,
    longitude: item?.longitude,
  };


  const apiEndpoint = "/master/updateMasterCities";
  const formFields = [
    { name: "cityId", label: "City Id", type: "text" },
    { name: "city", label: "City Name", type: "text" },
    {
      name: "stateId",
      label: "State Name",
      type: "select",
      optionsKey: "stateId",
      optionValueKey: "_id",
      optionLabelKey: "state",
    },
    { name: "latitude", label: "latitude", type: "text" },
    { name: "longitude", label: "longitude", type: "text" },
  ];

  const fetchMasterStates = async () => {
    try {
      const response = await userRequest.get(`/master/getMasterState`);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="City"
      item={item}
      fetchDataOptions={{
        stateId: fetchMasterStates,
      }}
    />
  );
};

export default EditCity;
