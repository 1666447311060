import React, { useEffect, useState } from "react";
import styles from "./CreateRoles.module.css";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../../components/SelectComponent";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";

function CreateRoles() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const initialFormData = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    roleId: "",
    designation: "",
    permissions: [],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [permissions, setPermissions] = useState([]);

  // Fetch roles and permissions on component mount
  useEffect(() => {
    async function fetchData() {
      try {
        const permissionsResponse = await userRequest.get(
          "/permissions/getAllPermissions"
        );
        setPermissions(permissionsResponse.data.data);

        const adminRoles = await userRequest.get(
          "/adminRoles/getAllAdminRoles"
        );
        setRoles(adminRoles?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        alert("Failed to fetch data. Please try again later.");
      }
    }
    fetchData();
  }, []);

  // Handle checkbox change for permissions
  const handleCheckboxChange = (permissionsId) => {
    const isPermissionChecked = formData.permissions.some(
      (p) => p.permissionsId === permissionsId
    );
    let updatedPermissions;

    if (isPermissionChecked) {
      updatedPermissions = formData.permissions.filter(
        (p) => p.permissionsId !== permissionsId
      );
    } else {
      updatedPermissions = [...formData.permissions, { permissionsId }];
    }

    setFormData({ ...formData, permissions: updatedPermissions });
  };

  // Handle input changes and validate fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    // Remove error if the input becomes valid
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (name === "name" && value) {
        delete updatedErrors.name;
      }

      if (name === "email") {
        if (!value) {
          updatedErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          updatedErrors.email = "Email is invalid";
        } else {
          delete updatedErrors.email;
        }
      }

      if (name === "password") {
        if (value.length < 8) {
          updatedErrors.password = "Password must be at least 8 characters";
        } else if (!/[A-Z]/.test(value)) {
          updatedErrors.password =
            "Password must contain at least one uppercase letter";
        } else if (!/[a-z]/.test(value)) {
          updatedErrors.password =
            "Password must contain at least one lowercase letter";
        } else if (!/[0-9]/.test(value)) {
          updatedErrors.password = "Password must contain at least one number";
        } else if (!/[!@#$%^&*]/.test(value)) {
          updatedErrors.password =
            "Password must contain at least one special character";
        } else {
          delete updatedErrors.password;
        }
      }

      if (name === "confirmPassword") {
        if (value !== formData.password) {
          updatedErrors.confirmPassword = "Passwords do not match";
        } else {
          delete updatedErrors.confirmPassword;
        }
      }

      if (name === "designation" && value) {
        delete updatedErrors.designation;
      }

      return updatedErrors;
    });
  };

  // Handle select change
  const handleSelectChange = (selectedOption) => {
    setFormData({ ...formData, roleId: selectedOption.value });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (selectedOption.value) {
        delete updatedErrors.roleId;
      }
      return updatedErrors;
    });
  };

  // Validate all fields before submitting
  const validateForm = () => {
    const validationErrors = {};
    if (!formData.name) validationErrors.name = "Name is required";
    if (!formData.email) validationErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      validationErrors.email = "Email is invalid";
    if (!formData.password) {
      validationErrors.password = "Password is required";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters";
    } else if (!/[A-Z]/.test(formData.password)) {
      validationErrors.password =
        "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(formData.password)) {
      validationErrors.password =
        "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one number";
    } else if (!/[!@#$%^&*]/.test(formData.password)) {
      validationErrors.password =
        "Password must contain at least one special character";
    }
    if (formData.password !== formData.confirmPassword)
      validationErrors.confirmPassword = "Passwords do not match";
    if (!formData.roleId) validationErrors.roleId = "User Type is required";
    if (!formData.designation)
      validationErrors.designation = "Designation is required";
    if (formData.permissions.length === 0)
      validationErrors.permissions = "At least one permission must be selected";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  // Save the form data
  const saveData = async () => {
    if (validateForm()) {
      try {
        await userRequest.post(
          "/adminUserPermission/createUserForAdmin",
          formData
        );
        setFormData(initialFormData);
        toast.success("Saved successfully!");
        navigate("/rolesandpermissions");
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const getSelectedLabel = (value, options) => {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : "Select";
  };

  const options = roles?.map((role) => ({
    value: role?._id,
    label: role?.roleName,
  }));

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          {
            label: "Roles and Permissions Management",
            path: "/rolesandpermissions",
          },
          {
            label: "Add Role and Permissions",
            path: "/rolesandpermissions/createroles",
          },
        ]}
        discardLabel={"Discard"}
        saveLabel={"Save"}
        onDiscardClick={() => navigate("/rolesandpermissions")}
        onSaveClick={saveData}
      />

      <div className={styles.contentContainer}>
        <h4>Basics Details</h4>

        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>Name *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter"
              />
              {errors.name && <p className={styles.error}>{errors.name}</p>}
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Email Id *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter"
              />
              {errors.email && <p className={styles.error}>{errors.email}</p>}
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Password *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Enter"
              />
              {errors.password && (
                <p className={styles.error}>{errors.password}</p>
              )}
            </div>
          </div>
        </div>

        <div className={styles.fieldContainer}>
          <div className={styles.feildLeft}>
            <div className={styles.fieldLabel}>
              <p>User Type *</p>
            </div>
            <div className={styles.fieldInput}>
              <SelectComponent
                options={options}
                onSelect={handleSelectChange}
                initialSelectedOption={getSelectedLabel(
                  formData?.roleId,
                  options
                )}
              />
              {errors.roleId && <p className={styles.error}>{errors.roleId}</p>}
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Designation *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
                placeholder="Enter"
              />
              {errors.designation && (
                <p className={styles.error}>{errors.designation}</p>
              )}
            </div>
          </div>
          <div className={styles.feildRight}>
            <div className={styles.fieldLabel}>
              <p>Confirm Password *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                placeholder="Confirm Password"
              />
              {errors.confirmPassword && (
                <p className={styles.error}>{errors.confirmPassword}</p>
              )}
            </div>
          </div>
        </div>

        <div className={styles.permissionDiv}>
          <h5>Permissions *</h5>
          {errors.permissions && <p className={styles.error}>{errors.permissions}</p>}
          <div className={styles.rolesTable}>
            <table>
              <tbody>
                {permissions.map((permission) => (
                  <tr className={styles.firstRow} key={permission._id}>
                    <td>
                      <div className={styles.permissionName}>
                        <input
                          type="checkbox"
                          checked={formData.permissions.some(
                            (p) => p.permissionsId === permission._id
                          )}
                          onChange={() => handleCheckboxChange(permission._id)}
                        />
                        <p className={styles.permissionNamePara}>
                          {permission.permissionName}
                        </p>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateRoles;
