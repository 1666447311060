import React from "react";
import { Popconfirm, Switch } from "antd";

const StatusToggleAction = ({ item, onStatusChange }) => (
  <Popconfirm
    title={`Are you sure to ${
      item.active === true ? "deactivate" : "activate"
    }`}
    onConfirm={() => onStatusChange(item?._id, item.active)}
    okText="Yes"
    cancelText="No"
  >
    <Switch size="small" checked={item?.active} />
  </Popconfirm>
);

export default StatusToggleAction;
