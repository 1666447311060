import React from "react";
import ViewItem from "../../../../../components/Modals/MasterModals/ViewItem";


const ViewState = ({ item,fetchData }) => {

    const initialFormData = {
    stateId: item?.stateId,
    state: item?.state,
    country: item?.country,
  };

  const formFields = [
    { name: "stateId", label: "State Id", type: "text" },
    { name: "state", label: "State Name", type: "text" },
    { name: "country", label: "Country Name", type: "text" },
  ];

  return (
    <ViewItem
      formFields={formFields}
      title="View State"
      item={initialFormData}
    />
  );
};

export default ViewState;


