import React from "react";
import styles from "./address.module.css";

function Address({ data }) {

  console.log(data)

  const currentAddress =data[0]?.addresses[0].currentAddress;
  const permanentAddress =data[0]?.addresses[0].permanentAddress;
  const IsPermanentSameAsCurrent =data[0]?.IsPermanentSameAsCurrent

  return (
    <div className={styles.main}>
      <div className={styles.singleDetailContainer}>
        <div className={styles.detailContainer}>
          <div className={styles.detailsHeading}>
            <ul>
              <li>Address</li>
            </ul>
            <p>Current Address</p>
          </div>
          <div className={styles.details}>
            <div className={styles.detailsContent}>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Nationality</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{currentAddress?.country}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Street address 1</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{currentAddress?.address1}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Street address 2</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{currentAddress?.address2}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>City</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{currentAddress?.city}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Pincode</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{currentAddress?.pinCode}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>State</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{currentAddress?.state}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.detailContainer}>
          <div className={styles.detailsHeading}>
            <div className={styles.checkbox}>
              <input type="checkbox" checked={IsPermanentSameAsCurrent}/>
              <span>Current address is same as permanent address</span>
            </div>
            <p>Permanent Address</p>
          </div>
          <div className={styles.details} style={{ paddingLeft: "5%" }}>
            <div className={styles.detailsContent}>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Nationality</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{permanentAddress?.country}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Street address 1</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{permanentAddress?.address1}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Street address 2</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{permanentAddress?.address2}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>City</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{permanentAddress?.city}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Pincode</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{permanentAddress?.pinCode}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>State</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{permanentAddress?.state}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;
