import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "./style.module.css";
import { toast } from "sonner";
import { userRequest } from "../../../requestMethod";
import SelectComponent from "../../SelectComponent";
import FileUpload from "../../FileUpload/FileUpload";
import editIcon from "../../../assets/images/edit.png";

const EditItem = ({
  fetchData,
  item,
  apiEndpoint,
  initialFormData,
  formFields,
  title,
  uploadFieldName,
  fetchDataOptions = {},
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [dataOptions, setDataOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    setFormData({ ...initialFormData });    
  }, [item]);

  useEffect(() => {
    const loadOptions = async () => {
      setLoading(true);
      setError("");
      try {
        const optionsPromises = Object.keys(fetchDataOptions).map((key) =>
          fetchDataOptions[key]()
        );
        const results = await Promise.all(optionsPromises);
        const optionsData = results.reduce((acc, result, index) => {
          const key = Object.keys(fetchDataOptions)[index];
          acc[key] = result;
          return acc;
        }, {});

        if (JSON.stringify(dataOptions) !== JSON.stringify(optionsData)) {
          setDataOptions(optionsData);
        }
      } catch (error) {
        setError("Error fetching data options");
        console.error("Error fetching data options:", error);
      } finally {
        setLoading(false);
      }
    };

    loadOptions();
  }, [fetchDataOptions]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: '' }); // Clear specific validation error on change
  };


  const validateFormData = () => {
    let errors = {};
    formFields.forEach((field) => {
      const value = formData[field.name];
      if (!value || (typeof value === 'string' && value.trim() === '')) {
        errors[field.name] = `${field.label} is required and cannot be empty`;
      }
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const handleOk = async () => {
    // Trim all string values in formData
    const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
      const value = formData[key];
      acc[key] = typeof value === 'string' ? value.trim() : value;
      return acc;
    }, {});

    setFormData(trimmedFormData);

    if (!validateFormData()) {
      toast.error('Required');
      return;
    }

    setIsModalOpen(false);

    try {
      await userRequest.put(`${apiEndpoint}/${item?._id}`, trimmedFormData);
      toast.success(`${title} updated successfully`);
      await fetchData();
    } catch (error) {
      console.error(`Error updating ${title.toLowerCase()}:`, error);
      toast.error(`Failed to update ${title.toLowerCase()}`);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setValidationErrors({});
  };

  const getSelectedOptionLabel = (field) => {
    if (!dataOptions[field.optionsKey]) return '';
    const selectedOption = dataOptions[field.optionsKey].find(
      (option) => option[field.optionValueKey] === formData[field.name]
    );
    return selectedOption ? selectedOption[field.optionLabelKey] : '';
  };

  return (
    <>
      <img src={editIcon} alt="" onClick={showModal} />

      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Edit {title}</p>
        </div>
        <div className={styles.contentMain}>
          {loading && <p>Loading options...</p>}
          {error && <p className={styles.error}>{error}</p>}
          {!loading &&
            !error &&
            formFields.map((field) => (
              <div className={styles.fieldContainer} key={field.name}>
                <div className={styles.fieldLabel}>
                  <p>{field.label} *</p>
                </div>
                <div className={styles.fieldInput}>
             
                  {field.type === "text" && (
                    <input
                      type="text"
                      name={field.name}
                      value={formData[field.name] || ""}
                      onChange={handleChange}
                      placeholder="Enter"
                    />
                  )}
                   {field.type === "textarea" && (
                    <textarea
                      name={field.name}
                      value={formData[field.name] || ""}
                      onChange={handleChange}
                      placeholder="Enter"
                    />
                  )}
                  {field.type === "select" && dataOptions[field.optionsKey] && (
                    <SelectComponent
                      options={dataOptions[field.optionsKey].map((option) => ({
                        value: option[field.optionValueKey],
                        label: option[field.optionLabelKey],
                      }))}
                      onSelect={(selectedOption) => {
                        setFormData({
                          ...formData,
                          [field.name]: selectedOption.value,
                        });
                        setValidationErrors({
                          ...validationErrors,
                          [field.name]: '', // Clear the error when an option is selected
                        });
                      }}
                      initialSelectedOption={getSelectedOptionLabel(field)}
                    />
                  )}
                  {field.type === "file" && (
                    <FileUpload
                    label={field.uploadLabel}
                    onChange={(fieldName, file) => {
                      setFormData({ ...formData, [fieldName]: file });
                      setValidationErrors({
                        ...validationErrors,
                        [fieldName]:'',
                      });
                    }}
                    fieldName={uploadFieldName}
                    name={formData?.[field.name]}
                  />
                  )}
                  {validationErrors[field.name] && (
                    <p className={styles.error}>{validationErrors[field.name]}</p>
                  )}
                </div>
              </div>
            ))}
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Update</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditItem;
