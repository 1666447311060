// import React, { useEffect, useState } from "react";
// import { Modal } from "antd";
// import styles from "./editCommitteeType.module.css";
// import { toast } from "sonner";
// import editIcon from "../../../../../assets/images/edit.png";
// import { userRequest } from "../../../../../requestMethod";

// const EditCommitteeType = ({ fetchData, data }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [formData, setFormData] = useState({
//     committeeType: data?.committeeType,
//     description: data?.description,
//   });

//   useEffect(() => {
//     setFormData({
//       committeeType: data?.committeeType,
//       description: data?.description,
//     });
//   }, [data]);

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   console.log(data);

//   const handleOk = async () => {
//     setIsModalOpen(false);
//     const { committeeType , description } = formData;

//     if (!committeeType.trim()) {
//       toast.error("Type is required and cannot be empty");
//       await fetchData()
//       setFormData({
//         committeeType: data?.committeeType,
//         description: data?.description,
//       });
//       return;
//     }

//     if (/\d/.test(committeeType)) {
//       toast.error("Type should not contain numbers");
//       await fetchData()
//       setFormData({
//         committeeType: data?.committeeType,
//         description: data?.description,
//       });
//       return;
//     }
//     if (!description.trim()) {
//       toast.error("Description is required and cannot be empty");
//       await fetchData()
//       setFormData({
//         committeeType: data?.committeeType,
//         description: data?.description,
//       });
//       return;
//     }
//     try {
//       await userRequest.put(
//         `/master/updateMasterCommitteeType/${data?._id}`,
//         formData
//       );
//       toast.success("Committee type updated successfully");
//       await fetchData();
//       setFormData({
//         type: data?.type,
//       });
//     } catch (error) {
//       console.log("Error updating Committee type:", error);
//       // Show error message
//       toast.error("Failed to update Committee type");
//     }
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <>
//       <img src={editIcon} alt="" onClick={showModal} />

//       <Modal
//         visible={isModalOpen}
//         onCancel={handleCancel}
//         onOk={handleOk}
//         className={styles.modal}
//       >
//         <div className={styles.modalTitle}>
//           <p>Edit Committee Type</p>
//         </div>
//         <div className={styles.contentMain}>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Committee Type</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="committeeType"
//                 value={formData?.committeeType}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className={styles.fieldContainer}>
//             <div className={styles.fieldLabel}>
//               <p>Description</p>
//             </div>
//             <div className={styles.fieldInput}>
//               <input
//                 type="text"
//                 name="description"
//                 value={formData?.description}
//                 onChange={handleChange}
//               />
//             </div>
//           </div>
//           <div className={styles.modalButton}>
//             <button onClick={handleCancel}>Cancel</button>
//             <button onClick={handleOk}>Update</button>
//           </div>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default EditCommitteeType;


import React from "react";
import EditItem from "../../../../../components/Modals/MasterModals/EditItem";

const EditDevalayaType = ({ fetchData, item }) => {
  const initialFormData = {
    committeeType: item?.committeeType,
    description:item?.description
  };

  const apiEndpoint = "/master/updateMasterCommitteeType";
  const formFields = [{ name: "committeeType", label: "Committee Type", type: "text" } , { name: "description", label: "Description", type: "text" }];


  return (
    <EditItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Committee Type"
      item={item}
    />
  );
};

export default EditDevalayaType;