import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { useForm, Controller } from "react-hook-form";
import { toast } from "sonner";
import { useLocation, useNavigate } from "react-router-dom";
import { userRequest } from "../../../../../requestMethod";
import SelectComponent from "../../../../../components/SelectComponent";
import styles from "../AddTask/addTask.module.css";
import editIcon from "../../../../../assets/images/edit.png";

const EditTask = ({ fetchData, item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [taskTypeOptions, setTaskTypeOptions] = useState([
    { value: "Single", label: "Single" },
    { value: "Multiple", label: "Multiple" },
    { value: "Continuous", label: "Continuous" },
  ]);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: item?.title || "",
      description: item?.description || "",
      taskType: item?.taskType || "",
      roleId: item?.roleId || "",
      category: item?.category || "",
      apiEndpoint: item?.apiEndpoint || "",
      method: item?.method || "",
      screenFlow: item?.screenFlow || "",
    },
  });

  const fetchUserRoles = async () => {
    try {
      const response = await userRequest.get("/userRoles/getAllUserRoles");
      setRoles(
        response.data.data.map((role) => ({
          value: role?._id,
          label: role?.roleName,
        }))
      );
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  useEffect(() => {
    fetchUserRoles();
    reset(item);
  }, [item]);

  const onSubmit = async (data) => {
    try {
      await userRequest.put(`/master/updateMasterTaskType/${item._id}`, data);
      toast.success("Task updated successfully");
      fetchData();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating Task:", error);
      toast.error("Failed to update Task");
    }
  };

  const handleCancel = () => {
    reset();
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={editIcon} alt="" onClick={() => setIsModalOpen(true)} />
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Edit Task</p>
        </div>
        <form
          className={styles.contentMain}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          {/* Title Field */}
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Title *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="title"
                control={control}
                rules={{ required: "Title is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.title && (
                <span className={styles.error}>{errors.title.message}</span>
              )}
            </div>
          </div>

          {/* Description Field */}
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Description *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="description"
                control={control}
                rules={{ required: "Description is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.description && (
                <span className={styles.error}>
                  {errors.description.message}
                </span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Method *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="method"
                control={control}
                rules={{ required: "Method is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.method && (
                <span className={styles.error}>{errors.method.message}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>screenFlow *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="screenFlow"
                control={control}
                rules={{ required: "ScreenFlow is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.screenFlow && (
                <span className={styles.error}>
                  {errors.screenFlow.message}
                </span>
              )}
            </div>
          </div>

          {/* Task Type Field */}
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Task Type *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="taskType"
                control={control}
                rules={{ required: "Task type is required." }}
                render={({ field }) => (
                  <SelectComponent
                    options={taskTypeOptions}
                    initialSelectedOption={
                      taskTypeOptions.find(
                        (option) => option.value === field.value
                      )?.label
                    }
                    onSelect={(selectedOption) =>
                      field.onChange(selectedOption.value)
                    }
                  />
                )}
              />
              {errors.taskType && (
                <span className={styles.error}>{errors.taskType.message}</span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Api Endpoint *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="apiEndpoint"
                control={control}
                rules={{ required: "Api Endpoint is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.apiEndpoint && (
                <span className={styles.error}>
                  {errors.apiEndpoint.message}
                </span>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Category *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="category"
                control={control}
                rules={{ required: "category is required." }}
                render={({ field }) => (
                  <input type="text" placeholder="Enter" {...field} />
                )}
              />
              {errors.category && (
                <span className={styles.error}>{errors.category.message}</span>
              )}
            </div>
          </div>
          {/* Role Type Field */}
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Role Type *</p>
            </div>
            <div className={styles.fieldInput}>
              <Controller
                name="roleId"
                control={control}
                rules={{ required: "Role type is required." }}
                render={({ field }) => (
                  <SelectComponent
                    options={roles}
                    onSelect={(selectedOption) =>
                      field.onChange(selectedOption.value)
                    }
                    initialSelectedOption={
                      roles.find((option) => option.value === field.value)
                        ?.label
                    }
                  />
                )}
              />
              {errors.roleId && (
                <span className={styles.error}>{errors.roleId.message}</span>
              )}
            </div>
          </div>
          <div className={styles.modalButton}>
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit">Update</button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EditTask;
