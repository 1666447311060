import React, { useState } from "react";
import styles from "./viewKYCPrice.module.css";
import { useNavigate } from "react-router-dom";
import KYCAmount from "./KYCAmount";
import Coupon from "./Coupon";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import PageHeader from "../../../../components/PageHeader/PageHeader";

function ViewKYCPrice() {
  const menuItems = [
    { label: "KYC Amount", state: "KYC Amount" },
    { label: "Coupon", state: "Coupon" },
  ];

  const [kycData, setKycData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("");
  const selectCountry = (val) => {
    setSelectedCountry(val);
    setSelectedState("");
  };

  const selectState = (val) => {
    setSelectedState(val);
  };
  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("KYC Amount");

  const handlePoliciesMenu = (menu) => {
    setActiveMenuItem(menu);
  };
  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Pandit KYC Management", path: "/panditkycmanagement" },
          {
            label: "View KYC Price",
            path: "/panditkycmanagement/viewkycprice",
          },
        ]}
        buttonLabel={"Set KYC Price"}
        onButtonClick={() => navigate("/panditkycmanagement/setprice")}
      />
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={
                activeMenuItem === item.state
                  ? `${styles.singleMenu} ${styles.activeMenu}`
                  : styles.singleMenu
              }
              onClick={() => handlePoliciesMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.selectContainer}>
          <div className={styles.singleSelect}>
            <CountryDropdown
              value={selectedCountry}
              onChange={(val) => selectCountry(val)}
            />
          </div>
          <div className={styles.singleSelect}>
            <RegionDropdown
              country={selectedCountry}
              value={selectedState}
              onChange={(val) => selectState(val)}
              placeholder="Select State"
            />
          </div>
        </div>
        <div className={styles.activePage}>
          {activeMenuItem === "KYC Amount" && (
            <KYCAmount
              kycData={kycData}
              setKycData={setKycData}
              country={selectedCountry}
              state={selectedState}
            />
          )}
          {activeMenuItem === "Coupon" && <Coupon />}
        </div>
      </div>
    </div>
  );
}

export default ViewKYCPrice;
