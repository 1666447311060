import React, { useState } from "react";
import styles from "./GuidedArticles.module.css";
import FaqCategory from "./FaqCategory/FaqCategory";
import Articles from "./Articles/Articles";


function GuidedArticles() {
  const menuItems = [
    { label: "Articles", state: "Article" },
      // { label: "Category", state: "Category" },

  ];

  const [activeMenuItem, setActiveMenuItem] = useState("Article");

  const handlePoliciesMenu = (menu) => {
    setActiveMenuItem(menu);
  };

  return (
    <div className={styles.main}>
  
      <div className={styles.contentContainer}>
        <div className={styles.menuContainer}>
          {menuItems.map((item) => (
            <div
              key={item.state}
              className={
                activeMenuItem === item.state
                  ? `${styles.singleMenu} ${styles.activeMenu}`
                  : styles.singleMenu
              }
              onClick={() => handlePoliciesMenu(item.state)}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
        <div className={styles.activePage}>
          {activeMenuItem === "Article" && <Articles />}
          {activeMenuItem === "Category" && <FaqCategory />}
        </div>
      </div>

 
    </div>
  );
}

export default GuidedArticles;
