import React, { useState } from "react";
import styles from "./panditPhoto.module.css";
import verified from "../../../../assets/images/verified.png";
import { Form } from "react-bootstrap";
import SeeReason from "../Reason/SeeReason";
import SelectReason from "../Reason/SelectReason";
import ImageZoom from "../../../../components/Modals/ImageZoom/ImageZoom";

function PanditPhoto({
  data,
  panditDetailsStatus,
  setPanditDetailsStatus,
  selectedPhotoReason,
  setSelectedPhotoReason,
}) {
  const panditDetails = data[0]?.PanditDetails[0];
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleStatusChange = (e) => {
    const selectedValue = e.target.value;
    setPanditDetailsStatus(selectedValue);
    // Open modal only if "Reject" is selected
    if (selectedValue === "Rejected") {
      setSelectedPhotoReason(""); // Reset reason when status changes
      setIsModalOpen(true); // Open modal
    }
  };

  return (
    <>
      <div className={styles.singleDetailContainer}>
        <div className={styles.detailContainer}>
          <div className={styles.detailsHeading}>
            <ul>
              <li>Pandit' Photo</li>
            </ul>
          </div>
          <div className={styles.details}>
            <div className={styles.profileImage}>
              <div className={styles.profileImageCircle}>
                {/* <img src={panditDetails?.profilePic} alt="" /> */}
                <ImageZoom imageSrc={panditDetails?.profilePic}/>
              </div>
            </div>
            <div className={styles.detailsContent}>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Full Name</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>
                    {panditDetails?.firstName} {panditDetails?.lastName}
                  </p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Join Date</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{formatDate(panditDetails?.createdAt)}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Mobile No.</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{panditDetails?.mobile}</p>
                  <span>
                    <img src={verified} alt="" />
                  </span>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Email</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{panditDetails?.email}</p>
                  {panditDetails?.emailVerified === true ? (
                    <span>
                      <img src={verified} alt="" />
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.detailContainer}
          style={{ paddingLeft: "2%", paddingTop: "3.6%" }}
        >
          <div className={styles.selectAction}>
            <SelectReason
              selectedReason={selectedPhotoReason}
              setSelectedReason={setSelectedPhotoReason}
              setIsModalOpen={setIsModalOpen}
              isModalOpen={isModalOpen}
              setPanditDetailsStatus={setPanditDetailsStatus}
            />
            {panditDetailsStatus === "Rejected" ? (
              <SeeReason reason={selectedPhotoReason} />
            ) : null}
            <Form.Select
              size="sm"
              value={panditDetailsStatus}
              // onChange={(e) => setPanditDetailsStatus(e.target.value)}
              onChange={handleStatusChange}
              style={{
                width: "20%",
                height: "4.6vh",
                borderRadius: "8px",
              }}
              // disabled={panditDetailsStatus === "Verified" ? true : false}
            >
              <option value="">Select</option>
              <option value="Verified">Verified</option>
              <option value="Rejected">Reject</option>
            </Form.Select>
          </div>
          <div className={styles.details}>
            <div className={styles.detailsContent}>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Age</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{panditDetails?.age} Years Old</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Gender</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{panditDetails?.gender}</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Experience</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>12 Years</p>
                </div>
              </div>
              <div className={styles.singleDetail}>
                <div className={styles.singleDetailLeft}>
                  <p>Language</p>
                </div>
                <div className={styles.singleDetailRight}>
                  <p>{panditDetails?.languages?.motherTongue?.language}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PanditPhoto;

function formatDate(dateString) {
  // Convert dateString to Date object
  const date = new Date(dateString);

  // Format the date using toLocaleDateString method
  const formattedDate = date.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  return formattedDate;
}
