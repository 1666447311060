// import { useState } from "react";
// import {
//   format,
//   startOfWeek,
//   addDays,
//   isSameDay,
// } from "date-fns";
// import styles from "./calender.module.css";

// const Calendar = () => {
//   const [currentMonth, setCurrentMonth] = useState(new Date());
//   const [selectedDate, setSelectedDate] = useState(new Date());

//   const onDateClickHandle = (day, dayStr) => {
//     setSelectedDate(day);
//   };

//   const renderDays = () => {
//     const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
//     const days = daysOfWeek.map((day, index) => (
//       <div className={`${styles.col} ${styles.colCenter}`} key={index}>
//         {day}
//       </div>
//     ));
//     return <div className={`${styles.days} row`}>{days}</div>;
//   };
  

//   const renderCells = () => {
//     const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
//     const dateFormat = "d";
//     const rows = [];
//     let days = [];
//     for (let i = 0; i < 7; i++) { // Loop over all 7 days of the week
//       const day = addDays(startDate, i);
//       const formattedDate = format(day, dateFormat);
//       const cloneDay = day;
//       days.push(
//         <div
//           className={`${styles.col} ${styles.cell}`}
//           key={day}
//           onClick={() => {
//             const dayStr = format(cloneDay, "ccc dd MMM yy");
//             onDateClickHandle(cloneDay, dayStr);
//           }}
//         >
//           <span 
//             className={`${styles.number} 
//             ${isSameDay(day, new Date()) ? styles.today : ""}
//             ${isSameDay(day, selectedDate) ? styles.selected : ""}`}
//           >
//             {formattedDate}
//           </span>
//         </div>
//       );
//     }
//     rows.push(
//       <div className={`${styles.row}`} key={startDate}>
//         {days}
//       </div>
//     );
//     return <div className={`${styles.body}`}>{rows}</div>;
//   };
  

  

//   return (
//     <div className={`${styles.calendar}`}>
//       {renderDays()}
//       {renderCells()}
//     </div>
//   );
// };

// export default Calendar;




import React, { useState } from "react";
import { format, startOfWeek, addDays, isSameDay } from "date-fns";
import styles from "./calender.module.css";

const Calendar = ({ setSelectedDate }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [localSelectedDate, setLocalSelectedDate] = useState(new Date());

  const onDateClickHandle = (day) => {
    setLocalSelectedDate(day);
    setSelectedDate(format(day, "yyyy-MM-dd")); // Pass formatted date to parent
  };

  const renderDays = () => {
    const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
    return (
      <div className={`${styles.days} row`}>
        {daysOfWeek.map((day, index) => (
          <div className={`${styles.col} ${styles.colCenter}`} key={index}>
            {day}
          </div>
        ))}
      </div>
    );
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "d";
    const rows = [];
    let days = [];
    for (let i = 0; i < 7; i++) { // Loop over all 7 days of the week
      const day = addDays(startDate, i);
      const formattedDate = format(day, dateFormat);
      const cloneDay = day;
      days.push(
        <div
          className={`${styles.col} ${styles.cell}`}
          key={day}
          onClick={() => onDateClickHandle(cloneDay)}
        >
          <span 
            className={`${styles.number} 
            ${isSameDay(day, new Date()) ? styles.today : ""}
            ${isSameDay(day, localSelectedDate) ? styles.selected : ""}`}
          >
            {formattedDate}
          </span>
        </div>
      );
    }
    rows.push(
      <div className={`${styles.row}`} key={startDate}>
        {days}
      </div>
    );
    return <div className={`${styles.body}`}>{rows}</div>;
  };

  return (
    <div className={`${styles.calendar}`}>
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;

