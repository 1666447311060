export const getConfig = () => {
    const env = process.env.REACT_APP_ENV || 'development'; // default to 'development' if not set
    const configs = {
        local: {
            BASE_URL: process.env.REACT_APP_BASE_URL,
            STORAGE_KEY: process.env.REACT_APP_STORAGE_KEY,
            TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY

        },
        development: {
            BASE_URL: process.env.REACT_APP_BASE_URL,
            STORAGE_KEY: process.env.REACT_APP_STORAGE_KEY,
            TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY
            
        },
        qa: {
            BASE_URL: process.env.REACT_APP_BASE_URL,
            STORAGE_KEY: process.env.REACT_APP_STORAGE_KEY,
            TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY
        },
        production: {
            BASE_URL: process.env.REACT_APP_BASE_URL,
            STORAGE_KEY: process.env.REACT_APP_STORAGE_KEY,
            TOKEN_KEY: process.env.REACT_APP_TOKEN_KEY
        }
    };
    return configs[env];
};
