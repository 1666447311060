import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./AddKaryakramamType.module.css";
import { toast } from "sonner";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import { userRequest } from "../../../../../requestMethod";

const AddKaryakramamType = ({ fetchData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    icon: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    icon: "",
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the error for the changed field
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: "", icon: "" };

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    } else if (/\d/.test(formData.name)) {
      newErrors.name = "Name cannot contain numbers";
      valid = false;
    }
    if (!formData.icon) {
      newErrors.icon = "Icon is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleOk = async () => {
    if (!validateForm()) {
      return;
    }
    setIsModalOpen(false);

    try {
      await userRequest.post(
        `/master/createAdminMasterKaryakramamType`,
        formData
      );
      toast.success("Karyakramam added successfully");
      await fetchData();
      setFormData({
        name: "",
        icon: "",
      });
      setErrors({ name: "", icon: "" });
    } catch (error) {
      console.log("Error creating Karyakramam:", error);
      toast.error("Failed to create Karyakramam");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <button className={styles.addNewBtn} onClick={showModal}>
        Add New
      </button>
      <Modal
        visible={isModalOpen}
        onCancel={handleCancel}
        onOk={handleOk}
        className={styles.modal}
      >
        <div className={styles.modalTitle}>
          <p>Add Karyakramam Type</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter"
              />
              {errors.name && <p className={styles.error}>{errors.name}</p>}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media *</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) => {
                  setFormData({ ...formData, icon: file });
                  setErrors({ ...errors, icon: "" });
                }}
                fieldName="uploadMedia"
              />
              {errors.icon && <p className={styles.error}>{errors.icon}</p>}
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Add</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddKaryakramamType;
