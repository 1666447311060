import React, { useEffect, useState } from "react";
import styles from "./JobCategory.module.css";
import { Popconfirm, Spin, Switch } from "antd";
import { userRequest } from "../../../../requestMethod.js";
import deleteIcon from "../../../../assets/images/deleteIcon.png";
import SearchFilterComponent from "../../../../components/SearchFilter/SearchFilterComponent.jsx";
import { handleExportDoc } from "../../../../utils/exportDoc.js";
import useDebounce from "../../../../utils/useDebounce.js";

import ViewJobCategory from "./ViewJobCategory/ViewJobCategory.jsx";
import EditJobCategory from "./EditJobCategory/EditJobCategory.jsx";
import AddJobCategory from "./AddJobCategory/AddJobCategory.jsx";

function JobCategory() {
  const [selectedOption, setSelectedOption] = useState("workingSector");
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [workingSectorData , setWorkingSectorData] = useState([])

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, selectedOption]);

  useEffect(() => {
    setSearchQuery("");
  }, [selectedOption]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let response;
      if (selectedOption === "workingSector") {
        response = await userRequest.get(
          `/master/getMasterWorkingSector?search=${debouncedSearchQuery}&page=${page}`
        );
      }
      if (selectedOption === "jobCategory") {
        response = await userRequest.get(
          `/master/getMasterJobCategory?search=${debouncedSearchQuery}&page=${page}`
        );
      }
      const workingSector = await userRequest.get(`/master/getMasterWorkingSector`);
      setWorkingSectorData(workingSector?.data?.data);
      setData(response?.data?.data);
      setTotalPages(response?.data?.pagination?.totalPages);
      setPage(response?.data?.pagination?.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (selectedOption === "jobCategory") {
        await userRequest.put(`/master/updateMasterJobCategory/${id}`, {
          deleted: true,
        });
      }
      if (selectedOption === "workingSector") {
        await userRequest.put(`/master/updateMasterWorkingSector/${id}`, {
          deleted: true,
        });
      }
      fetchData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (id, activeStatus) => {
    try {
      const newActiveStatus = !activeStatus;
      if (selectedOption === "jobCategory") {
        await userRequest.put(`/master/updateMasterJobCategory/${id}`, {
          active: newActiveStatus,
        });
      }
      if (selectedOption === "workingSector") {
        await userRequest.put(`/master/updateMasterWorkingSector/${id}`, {
          active: newActiveStatus,
        });
      }
      fetchData();
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handleExport = () => {
    if (selectedOption === "jobCategory") {
      handleExportDoc("/master/exportMasterJobCategory", "JobCategory.xlsx");
    }
    if (selectedOption === "workingSector") {
      handleExportDoc(
        "/master/exportMasterWorkingSector",
        "workingSector.xlsx"
      );
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className={styles.main}>
      <div className={styles.radioItems}>
        <div className={styles.radioDiv}>
          <input
            type="radio"
            id="workingSector"
            name="workingSector"
            value="workingSector"
            checked={selectedOption === "workingSector"}
            onChange={handleOptionChange}
            className={
              selectedOption === "workingSector" ? styles.radioStyle : ""
            }
          />
          <label
            htmlFor="workingSector"
            className={
              selectedOption === "workingSector" ? styles.activeRadio : ""
            }
          >
            Working Sector
          </label>
        </div>
        <div className={styles.radioDiv}>
          <input
            type="radio"
            id="jobCategory"
            name="jobCategory"
            value="jobCategory"
            checked={selectedOption === "jobCategory"}
            onChange={handleOptionChange}
            className={
              selectedOption === "jobCategory" ? styles.radioStyle : ""
            }
          />
          <label
            htmlFor="jobCategory"
            className={
              selectedOption === "jobCategory" ? styles.activeRadio : ""
            }
          >
            Job Category
          </label>
        </div>
      </div>
      <SearchFilterComponent
        setSearchQuery={setSearchQuery}
        onExportClick={handleExport}
        addComponent={() => (
          <AddJobCategory
            fetchData={fetchData}
            selectedOption={selectedOption}
            workingSectorData={workingSectorData}
          />
        )}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <Spin spinning={loading} size="small">
            <div className={styles.tableContainerTask}>
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    {selectedOption === "jobCategory" && (
                      <>
                        <th>Working Sector</th>
                      </>
                    )}
                    <th>
                      {selectedOption === "workingSector"
                        ? "Working Sector"
                        : "Job Title"}
                    </th>
                    {selectedOption === "jobCategory" && (
                      <>
                        <th>Job Code</th>
                      </>
                    )}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={item?._id}>
                        {/* <td>{index + 1}</td> */}
                        <td>{(page - 1) * 10 + index + 1}</td>
                        {selectedOption === "jobCategory" && (
                          <>
                            <td>{item?.workingSectorType?.workingSector}</td>
                          </>
                        )}
                        <td>
                          {selectedOption === "workingSector"
                            ? item?.workingSector
                            : item?.jobTitle}
                        </td>
                        {selectedOption === "jobCategory" && (
                          <>
                            <td>{item?.jobTitleCode}</td>
                          </>
                        )}

                        <td className={styles.actionTd}>
                          <ViewJobCategory
                            item={item}
                            selectedOption={selectedOption}
                          />
                          <EditJobCategory
                            fetchData={fetchData}
                            item={item}
                            selectedOption={selectedOption}
                            workingSectorData={workingSectorData}
                          />
                          {/* <Popconfirm
                            title={`Are you sure to delete ${
                              selectedOption === "workingSector"
                                ? item?.workingSector
                                : item?.category
                            }?`}
                            onConfirm={() => handleDelete(item?._id)}
                            okText="Yes"
                            cancelText="No"
                            overlayClassName={styles.largePopconfirm}
                          >
                            <img src={deleteIcon} alt="" />
                          </Popconfirm> */}
                          <Popconfirm
                            title={`Are you sure to ${
                              item.active === true ? "deactivate" : "activate"
                            } ${
                              selectedOption === "workingSector"
                                ? item?.workingSector
                                : item?.category
                            }`}
                            onConfirm={() =>
                              handleChange(item?._id, item.active)
                            }
                            okText="Yes"
                            cancelText="No"
                            overlayClassName={styles.largePopconfirm}
                          >
                            <Switch size="small" checked={item?.active} />
                          </Popconfirm>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8">No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {data.length > 0 && (
                <div className={styles.footer}>
                  <div
                    className={styles.action}
                    style={{
                      visibility: totalPages === 1 ? "hidden" : "visible",
                    }}
                  >
                    <button
                      className={`${styles.actionButton} ${
                        page === 1 ? styles.disabled : styles.activeButton
                      }`}
                      onClick={handlePrevious}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                    <button
                      className={`${styles.actionButton} ${
                        page === totalPages
                          ? styles.disabled
                          : styles.activeButton
                      }`}
                      onClick={handleNext}
                      disabled={page === totalPages}
                    >
                      Next
                    </button>
                  </div>
                  <div className={styles.page}>
                    <span>{page}</span> <span>of</span>{" "}
                    <span>{totalPages}</span>
                  </div>
                </div>
              )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
}

export default JobCategory;
