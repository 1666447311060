import React, { useEffect, useState } from "react";
import styles from "./EditArticle.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import PageHeaderTwo from "../../../../../components/PageHeader/PageHeaderTwo";
import { userRequest } from "../../../../../requestMethod";
import SelectComponent from "../../../../../components/SelectComponent";
import { useForm, Controller } from "react-hook-form";

function EditArticle() {
  const navigate = useNavigate();
  const [articleCategories, setArticleCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [languages, setLanguages] = useState([]);
  const { id } = useParams();
  
  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      title: "",
      content: "",
      category: "",
      tags: [],
      language_code: "",
      seo: {
        seo_title: "",
        seo_description: "",
        slug: "",
      },
      is_published: "false",
    },
  });

  const fetchArticleCategories = async () => {
    try {
      let response = await userRequest.get("/admin/static/getAllCategories");
      setArticleCategories(response?.data?.data);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
      toast.error("Error fetching categories");
    }
  };

  const fetchLanguages = async () => {
    try {
      let response = await userRequest.get("/master/getMasterLanguage");
      setLanguages(response?.data?.data);
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    }
  };
  
  const fetchArticleData = async () => {
    try {
      const response = await userRequest.get(`/admin/static/getGuideArticleById?id=${id}`);
   
      const articleData = response.data?.data;
      setTags(articleData.tags || []);
      reset(articleData);
      if (articleData.category) {
        setValue("category", articleData.category._id); 
      }
      if (articleData.language) {
        setValue("language_code", articleData.language_code); 
      }
      setValue("is_published", articleData.is_published ? "true" : "false");
    } catch (error) {
      console.error("Error fetching article data:", error.message);
      toast.error("Error loading article data");
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  useEffect(() => {
    fetchArticleCategories();
  }, []);
  
  useEffect(() => {
    fetchArticleData();
  }, []);

  const categoriesOptions = articleCategories.map((item) => ({
    value: item._id,
    label: item.name,
  }));
  const languageOptions = languages?.map((item) => ({
    value: item?.language,
    label: item?.language,
  }));

  const onSubmit = async (data) => {
    data['id'] = id;
    if (tags.length === 0) {
      toast.error("At least one tag is required.");
      return;
    }
    try {
      const dataToSave = { ...data, tags };
      await userRequest.put("/admin/static/updateGuideArticle", dataToSave);
      reset();
      setTags([]);
      navigate("/staticcontents/guidedarticles");
      toast.success("Saved successfully!");
    } catch (error) {
      console.error("Error saving data:", error.message);
      toast.error("Error saving data");
    }
  };

  const handleAddTag = () => {
    if (inputValue.trim() !== "" && !tags.includes(inputValue)) {
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAddTag();
    }
  };

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Article", path: "/staticcontents/guidedarticles" },
          {
            label: "Edit Article",
          },
        ]}
        
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={() => navigate("/staticcontents/guidedarticles")}
        onSaveClick={handleSubmit(onSubmit)}
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <div className={styles.createContainer}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.fieldContainer}>
                <div className={styles.feildLeft}>
                  <div className={styles.fieldLabel}>
                    <p>Title</p>
                  </div>
                  <div className={styles.fieldInput}>
                    <input
                      type="text"
                      {...register("title", { required: "Title is required." })}
                      placeholder="Enter title"
                    />
                    {errors.title && (
                      <p className={styles.error}>{errors.title.message}</p>
                    )}
                  </div>
                </div>
                <div className={styles.feildRight}>
                  <div className={styles.fieldLabel}>
                    <p>Content</p>
                  </div>
                  <div className={styles.fieldInput}>
                    <input
                      type="text"
                      {...register("content", {
                        required: "Content is required.",
                      })}
                      placeholder="Enter content"
                    />
                    {errors.content && (
                      <p className={styles.error}>{errors.content.message}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className={styles.fieldContainer}>
                <div className={styles.feildLeft}>
                  <div className={styles.fieldLabel}>
                    <p>Category</p>
                  </div>
                  <div className={styles.fieldInputForTagAndCategory}>
                    <Controller
                      name="category"
                      control={control}
                      rules={{ required: "Category is required." }}
                      render={({ field }) => (
                        <SelectComponent
                          options={categoriesOptions}
                          onSelect={(selectedOption) =>
                            field.onChange(selectedOption.value)
                          }
                          initialSelectedOption={
                            categoriesOptions.find(
                              (option) => option.value === field.value
                            )?.label
                          }
                        />
                      )}
                    />
                    {errors.category && (
                      <p className={styles.error}>{errors.category.message}</p>
                    )}
                  </div>
                </div>

                <div className={styles.feildRight}>
                  <div className={styles.fieldLabel}>
                    <p>Tags</p>
                  </div>
                  <div className={styles.fieldInputForTagAndCategory}>
                    <div className={styles.mainTagDiv}>
                      {tags.map((tag, index) => (
                        <div key={index} className={styles.tagDiv}>
                          {tag}
                          <button
                            type="button"
                            onClick={() => handleRemoveTag(tag)}
                            className={styles.removeTagButton}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                      <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Type a tag and press Enter"
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* SEO Section */}
              <div className={styles.fieldContainer}>
                <div className={styles.feildLeft}>
                  <div className={styles.fieldLabel}>
                    <p>SEO Title</p>
                  </div>
                  <div className={styles.fieldInput}>
                    <input
                      type="text"
                      {...register("seo.seo_title", {
                        required: "SEO Title is required.",
                      })}
                      placeholder="Enter SEO title"
                    />
                    {errors.seo?.seo_title && (
                      <p className={styles.error}>
                        {errors.seo.seo_title.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.feildRight}>
                  <div className={styles.fieldLabel}>
                    <p>SEO Description</p>
                  </div>
                  <div className={styles.fieldInput}>
                    <input
                      type="text"
                      {...register("seo.seo_description", {
                        required: "SEO Description is required.",
                      })}
                      placeholder="Enter SEO description"
                    />
                    {errors.seo?.seo_description && (
                      <p className={styles.error}>
                        {errors.seo.seo_description.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.fieldContainer}>
                <div className={styles.feildLeft}>
                  <div className={styles.fieldLabel}>
                    <p>Slug</p>
                  </div>
                  <div className={styles.fieldInput}>
                    <input
                      type="text"
                      {...register("seo.slug", {
                        required: "Slug is required.",
                      })}
                      placeholder="Enter slug"
                    />
                    {errors.seo?.slug && (
                      <p className={styles.error}>{errors.seo.slug.message}</p>
                    )}
                  </div>
                </div>

                <div className={styles.feildRight}>
                  <div className={styles.fieldLabel}>
                    <p>Language</p>
                  </div>
                  <div className={styles.fieldInputForTagAndCategory}>
                    <Controller
                      name="language_code"
                      control={control}
                      rules={{ required: "Langauge is required." }}
                      render={({ field }) => (
                        <SelectComponent
                          options={languageOptions}
                          onSelect={(selectedOption) =>
                            field.onChange(selectedOption.value)
                          }
                          initialSelectedOption={
                            languageOptions.find(
                              (option) => option.value === field.value
                            )?.label
                          }
                        />
                      )}
                    />
                    {errors.language_code && (
                      <p className={styles.error}>
                        {errors.language_code.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.feildLeft}>
                <div className={styles.fieldLabel}>
                  <p>Published</p>
                </div>
                {/* <div className={styles.fieldInput}> */}
                <Controller
                  name="is_published"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="checkbox"
                      checked={field.value === "true"}
                      onChange={(e) =>
                        field.onChange(e.target.checked ? "true" : "false")
                      }
                    />
                  )}
                />
                <label>Is Published</label>
                {/* </div> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditArticle;
