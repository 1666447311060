import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styles from "../AddDosDonts/AddIsthaDhaiva.module.css";
import { toast } from "sonner";
import FileUpload from "../../../../../components/FileUpload/FileUpload";
import editIcon from "../../../../../assets/images/edit.png";
import { userRequest } from "../../../../../requestMethod";

const EditDosDonts = ({ fetchData, task }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: task?.name || "",
    subText: task?.subText || "",
    icon: task?.icon || "",
  });

  const [errors, setErrors] = useState({}); 

  useEffect(() => {
    if (task) {
      setFormData({
        name: task?.name || "",
        subText: task?.subText || "",
        icon: task?.icon || "",
      });
    }
  }, [task]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]:""
    })
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else if (/\d/.test(formData.name)) {
      errors.name = "Name cannot contain numbers";
    }

    if (!formData.icon) {
      errors.icon = "Icon is required";
    }

    if (!formData.subText.trim()) {
      errors.subText = "Sub Text is required";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleOk = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      await userRequest.patch(`/master/editAdminMasterDosDont/${task?._id}`, formData);

      toast.success("Dos/Donts updated successfully");
      await fetchData();
      setIsModalOpen(false); 
    } catch (error) {
      console.log("Error updating Dos/Donts:", error);
      toast.error("Failed to update Dos/Donts");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <img src={editIcon} alt="" onClick={showModal} />

      <Modal visible={isModalOpen} onCancel={handleCancel} onOk={handleOk} className={styles.modal}>
        <div className={styles.modalTitle}>
          <p>Edit Do's Don`ts</p>
        </div>
        <div className={styles.contentMain}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Name *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter"
              />
              {errors.name && <p className={styles.error}>{errors.name}</p>}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Sub Text *</p>
            </div>
            <div className={styles.fieldInput}>
              <input
                type="text"
                name="subText"
                value={formData.subText}
                onChange={handleChange}
                placeholder="Enter"
              />
              {errors.subText && <p className={styles.error}>{errors.subText}</p>}
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldLabel}>
              <p>Upload Media *</p>
            </div>
            <div className={styles.fieldInput}>
              <FileUpload
                label="Upload Media"
                onChange={(fieldName, file) =>{
                  setFormData({ ...formData, icon: file })
                  setErrors({ ...errors, icon: "" })
                }
                }
                fieldName="uploadMedia"
                name={formData.icon}
              />
              {errors.icon && <p className={styles.error}>{errors.icon}</p>}
            </div>
          </div>
          <div className={styles.modalButton}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={handleOk}>Update</button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditDosDonts;
