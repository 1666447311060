import React, { useState } from "react";
import { Modal } from "antd";
import styles from "../../components/Modals/MasterModals/style.module.css";
import { toast } from "sonner";

const BannerFilter = ({ isModalOpen, handleOk, handleCancel }) => {
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFilter = () => {
    if (formData?.startDate && formData?.endDate && new Date(formData?.startDate) > new Date(formData?.endDate)) {
      toast.error("Start Date cannot be greater than End Date");
      return; // Exit function without proceeding
    }
    handleOk(formData); // Pass formData back to parent component
  };

  return (
    <Modal
      visible={isModalOpen}
      onCancel={handleCancel}
      onOk={handleFilter}
      width={"29vw"}
      height={"100dvh"}
      className={styles.modal}
    >
      <div className={styles.modalTitle}>
        <p>Filter Banners</p>
      </div>
      <div className={styles.contentMain}>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Start Date</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>End Date</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.modalButton}>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleFilter}>Filter</button>
        </div>
      </div>
    </Modal>
  );
};

export default BannerFilter;
