import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { getConfig } from "./utils/getConfig";

const { BASE_URL, TOKEN_KEY } = getConfig();

const token = localStorage.getItem(TOKEN_KEY);

// Decode the token safely
const decodeToken = (token) => {
  try {
    if (token) {
      return jwtDecode(token);
    }
    return null;
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
};

export const decodedToken = decodeToken(token);

// Axios instance for public requests (no authentication required)
export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

// Axios instance for user requests (authentication required)
// export const userRequest = axios.create({
//   baseURL: BASE_URL,
//   headers: {
//     "Content-Type": "application/json",
//     // Add the Bearer token if available
//     Authorization: token ? `Bearer ${token}` : '',
//   },
// });

export const userRequest = axios.create({
  baseURL: BASE_URL,
});

userRequest.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios instance for file uploads
export const fileRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    // Basic auth encoded as Base64 - ensure these are environment-specific or secure
    Authorization: `Basic ${btoa("TEZPANDIT:TSF121YXPO")}`,
  },
});

export const userRequestTwo = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // Basic auth encoded as Base64 - ensure these are environment-specific or secure
    Authorization: `Basic ${btoa("TEZPANDIT:TSF121YXPO")}`,
  },
});
