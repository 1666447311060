import React from "react";
import AddItem from "../../../../../components/Modals/MasterModals/AddItem";
import { userRequest } from "../../../../../requestMethod";

const AddState = ({ fetchData }) => {
  const initialFormData = {
    stateId: "",
    state: "",
    countryId: "",
  };

  const fetchCountriesData = async () => {
    try {
      const response = await userRequest.get(`/master/getMasterCountry`);
      return response.data.data;   
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const apiEndpoint = "/master/createMasterState";
  const formFields = [
    { name: "stateId", label: "State Id", type: "text" },
    { name: "state", label: "State Name", type: "text" },
    {
      name: "countryId",
      label: "Country Name",
      type: "select",
      optionsKey: "countryId",
      optionValueKey: "_id",
      optionLabelKey: "country",
    },
  ];

  return (
    <AddItem
      fetchData={fetchData}
      apiEndpoint={apiEndpoint}
      initialFormData={initialFormData}
      formFields={formFields}
      title="Add State"
      fetchDataOptions={{
        countryId: fetchCountriesData,
      }}
    />
  );
};

export default AddState;
