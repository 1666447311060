import React, { useEffect, useState } from "react";
import styles from "../CreateCampaign/CreateCampaign.module.css";
import { useNavigate, useParams } from "react-router-dom";
import SelectComponent from "../../../../components/SelectComponent";
import RichTextEditor from "../../../../components/RichTextEditor/RichTextEditor";
import { userRequest } from "../../../../requestMethod";
import { toast } from "sonner";
import Stepper from "awesome-react-stepper";
import { Spin } from "antd";
import PageHeaderTwo from "../../../../components/PageHeader/PageHeaderTwo";
import LocationSearchInput from "../../../../components/LocationSearch/LocationSearchInput";

function EditCampaign() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rules, setRules] = useState([""]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [levelAdded, setLevelAdded] = useState(false);
  const [taskAdded, setTaskAdded] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [loading, setLoading] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [rolesType, setRolesType] = useState([]);
  const [resetKey, setResetKey] = useState(0);
  const [errors, setErrors] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeTaskIndex, setActiveTaskIndex] = useState(null);
  const [validationCity, setValidationCity] = useState();
  const [validationState, setValidationState] = useState();
  const [scope, setScope] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const toggleTaskAccordion = (index) => {
    setActiveTaskIndex(activeTaskIndex === index ? null : index);
  };
  useEffect(() => {
    const fetchCampaignDetails = async () => {
      try {
        setLoading(true);
        const response = await userRequest.get(
          `/admin/svarna/getIndividualCampaignDetails/${id}`
        );

        const data = response.data.data;
        setRules(data.rule);
        setTermsAndConditions(data.termsAndConditions || "");
        const roleData = rolesType?.filter(
          (item) => item?._id === data?.roleId
        );
        setCampaignDetails({
          campaignTitle: data?.campaignTitle,
          NoOfLevels: data?.NoOfLevels,
          Duration: {
            startDate: data?.Duration?.startDate,
            endDate: data?.Duration?.endDate,
          },
          roleId: data?.roleId,
          country: data?.geolocation[0]?.country,
          state: data?.geolocation[0]?.state,
          city: data?.geolocation[0]?.city,
          rule: data?.rule,
          termsAndConditions: data?.termsAndConditions,
          status: data?.status,
          maxLakshmiCoins: data?.maxLakshmiCoins || 0,
          maxTejCoins: data?.maxTejCoins || 0,
          geographicalScope: data?.geographicalScope,
          geolocation: data?.geolocation,
          levelsData: data?.levelDetails?.map((level) => ({
            levelId: level?._id,
            levelNumber: level.levelNumber,
            levelName: level.levelName,
            levelDescription: level.levelDescription,
            totalTasks: level.totalTasks,
            maxCoins: level.maxCoins,
            coins: level.coins,
            tasksData: level.taskDetails?.map((task) => ({
              taskId: task?._id,
              taskName: task.operationId?.title,
              taskDescription: task.operationId?.description,
              coinValue: task.coinValue,
              taskCount: task.taskCount,
              operationId: task.operationId?._id,
              taskType: task.operationId?.taskType,
              numberOfDays: task.numberOfDays,
            })),
          })),
        });
      } catch (error) {
        console.error("Error fetching campaign details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignDetails();
  }, []);
  console.log(campaignDetails, "geolocation");
  useEffect(() => {
    // Fetch task types from the API
    const fetchRoles = async () => {
      try {
        const response = await userRequest.get("/userRoles/getAllUserRoles");
        setRolesType(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchRoles();
  }, []);

  // const formatDate = (isoDate) => {
  //   if (!isoDate) return ""; // Return empty string if isoDate is falsy
  //   const date = new Date(isoDate);
  //   const year = date.getFullYear();
  //   let month = (date.getMonth() + 1).toString();
  //   let day = date.getDate().toString();

  //   // Ensure month and day are two digits
  //   if (month.length === 1) {
  //     month = "0" + month;
  //   }
  //   if (day.length === 1) {
  //     day = "0" + day;
  //   }

  //   return `${year}-${month}-${day}`;
  // };
  const formatDate = (date) => {
    if (!date) return "";
    const parsedDate = new Date(date);
    if (isNaN(parsedDate)) return ""; 
    return parsedDate.toISOString().split("T")[0];
  };
  useEffect(() => {
    const fetchTaskTypes = async () => {
      try {
        const response = await userRequest.get("/master/getMasterTaskType");
        setTaskTypes(response?.data?.data);
      } catch (error) {
        console.error("Error fetching task types:", error);
      }
    };
    fetchTaskTypes();
  }, []);

  const handleAddRule = () => {
    setRules([...rules, ""]);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      rule: [...prevDetails.rule, ""],
    }));
  };

  const handleRemoveRule = (index) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList.splice(index, 1);
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleRuleChange = (index, event) => {
    const newRules = [...rules];
    newRules[index] = event.target.value;
    setRules(newRules);
    setCampaignDetails((prevDetails) => {
      const newRuleList = [...prevDetails.rule];
      newRuleList[index] = event.target.value;
      return {
        ...prevDetails,
        rule: newRuleList,
      };
    });
  };

  const handleAddLevel = () => {
    setLevelAdded(true);
    setCampaignDetails((prevState) => ({
      ...prevState,
      NoOfLevels: prevState.NoOfLevels + 1,
      levelsData: [
        ...prevState.levelsData,
        {
          levelId: "",
          levelName: "",
          levelDescription: "",
          totalTasks: 0,
          maxCoins: 0,
          coins: "",
          tasksData: [],
        },
      ],
    }));
    setTimeout(() => {
      setLevelAdded(false);
    }, 1000);
  };

  const handleRemoveLevel = (index) => {
    setCampaignDetails((prevState) => {
      const updatedLevelsData = prevState.levelsData.filter(
        (_, i) => i !== index
      );
      return {
        ...prevState,
        levelsData: updatedLevelsData,
        NoOfLevels: updatedLevelsData.length,
      };
    });
  };

  const handleAddTask = (levelIndex) => {
    setTaskAdded(true);
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].tasksData.push({
        taskId: "",
        taskName: "",
        taskDescription: "",
        coinValue: 0,
        taskCount: 1,
        operationId: "",
        taskType: "",
        numberOfDays: 0,
      });
      return {
        ...prevState,
        totalTasks: prevState.totalTasks + 1,
        levelsData: newLevelsData,
      };
    });
    setTimeout(() => {
      setTaskAdded(false);
    }, 1000);
  };

  const handleRemoveTask = (levelIndex, taskIndex) => {
    setCampaignDetails((prevState) => {
      const newLevelsData = [...prevState.levelsData];
      newLevelsData[levelIndex].tasksData.splice(taskIndex, 1);
      return {
        ...prevState,
        numberOfTask: prevState.totalTasks + 1,
        levelsData: newLevelsData,
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCampaignDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getMinEndDate = () => {
    const baseDate = startDate ? new Date(startDate) : new Date();
    baseDate.setDate(baseDate.getDate() + 1);
    return baseDate.toISOString().split("T")[0];
  };
  const handleDurationChange = (event, InputName) => {
    if (InputName == "startDate") {
      setStartDate(event.target.value);
      setEndDate("");
    }
    if (InputName == "endDate") {
      setEndDate(event.target.value);
    }
    const { name, value } = event.target;
    setCampaignDetails((prevState) => ({
      ...prevState,
      Duration: {
        ...prevState.Duration,
        [name]: value,
      },
    }));
  };

  const roleOptions = rolesType.map((role) => ({
    label: role.roleName,
    value: role._id,
  }));
  const coinOptions = [
    { value: "Tej", label: "Tej" },
    { value: "Lakshmi", label: "Lakshmi" },
  ];
  const [selectedCountries, setSelectedCountries] = useState([]);
  const geographicalScopes = [
    "Local",
    "Regional",
    "National",
    "International",
    "Global",
  ];
  const geographicalScopeOptions = geographicalScopes.map((geo) => ({
    value: geo,
    label: geo,
  }));

  const handleScopeSelect = (option) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      geographicalScope: option.value,
    }));
    setScope(option.value);
  };

  const selectedGeographicalScope = scope;

  const handleLocationChange = (location) => {
    const {
      country,
      state,
      city,
      coordinates,
      placeId,
      fullAddress,
      country_code,
      state_code,
    } = location;
    setValidationState(state);
    setValidationCity(city);
    const updatedGeoLocation = [
      // ...formData?.geoLocation,
      {
        fullAddress,
        country,
        state: state,
        city: city,
        placeId,
        geolocation: {
          type: "Point",
          coordinates: [coordinates?.lng || 0, coordinates?.lat || 0],
        },
        country_code,
        state_code:
          scope.geographicalScope === "National" ||
          scope.geographicalScope === "International"
            ? ""
            : state_code,
      },
    ];

    setCampaignDetails((prevData) => ({
      ...prevData,
      country,
      state: state,
      city: city,
      geolocation: updatedGeoLocation,
    }));
  };

  useEffect(() => {
    if (campaignDetails?.geographicalScope === "International") {
      setCampaignDetails((prevData) => ({
        ...prevData,
        country: selectedCountries[0]?.country,
        geolocation: selectedCountries,
      }));
    }
  }, [selectedCountries, scope]);

  useEffect(() => {
    if (campaignDetails?.geographicalScope === "International") {
      setSelectedCountries(campaignDetails?.geolocation);
    }
  }, [campaignDetails?.geographicalScope, scope]);

  const handleAddCountry = (location) => {
    const { country, fullAddress, coordinates, country_code, city, state } =
      location;
    setValidationState(state);
    setValidationCity(city);
    if (!country) return;
    if (!selectedCountries.some((item) => item.country === country)) {
      const newCountry = {
        fullAddress,
        country,
        geolocation: {
          type: "Point",
          coordinates: [coordinates?.lng || 0, coordinates?.lat || 0],
        },
        country_code,
      };
      setSelectedCountries((prevSelected) => {
        const updatedCountries = [...prevSelected, newCountry];
        return updatedCountries;
      });
    }
  };

  const handleRemoveCountry = (countryToRemove) => {
    const updatedCountries = selectedCountries.filter(
      (item) => item.country !== countryToRemove
    );
    setSelectedCountries(updatedCountries);
    const updatedGeoLocation = campaignDetails.geolocation.filter(
      (item) => item.country !== countryToRemove
    );
    setCampaignDetails((prevData) => ({
      ...prevData,
      geolocation: updatedGeoLocation,
    }));
  };
  const handleRoleSelect = (option) => {
    setCampaignDetails((prevState) => ({
      ...prevState,
      roleId: option.value,
    }));
  };

  const handleCoinSelect = (option, levelIndex) => {
    setCampaignDetails((prevState) => {
      const newState = {
        ...prevState,
        levelsData: prevState.levelsData.map((levelDetail, idx) => {
          if (idx === levelIndex) {
            return {
              ...levelDetail,
              coins: option.value,
            };
          }
          return levelDetail;
        }),
      };
      return newState;
    });
  };

  const handleTermsChange = (value) => {
    setTermsAndConditions(value);
    setCampaignDetails((prevDetails) => ({
      ...prevDetails,
      termsAndConditions: value,
    }));
  };

  const handleSave = async () => {
    const {
      campaignTitle,
      NoOfLevels,
      Duration,
      roleId,
      country,
      state,
      city,
      rule,
      termsAndConditions,
      maxTejCoins,
      maxLakshmiCoins,
      levelsData,
    } = campaignDetails;
    setValidationState(state);
    setValidationState(city);
    console.log(country, "country");
    console.log(validationState, "state");
    console.log(validationCity, "city");
    let newErrors = {};
    let hasError = false;

    if (!campaignTitle) {
      newErrors.campaignTitle = "Campaign Title is required";
      hasError = true;
    }
    if (!NoOfLevels) {
      newErrors.NoOfLevels = "Number of Levels is required";
      hasError = true;
    }
    if (!Duration.startDate) {
      newErrors.startDate = "Start Date is required";
      hasError = true;
    }
    if (!Duration.endDate) {
      newErrors.endDate = "End Date is required";
      hasError = true;
    }
    if (!roleId) {
      newErrors.roleId = "Role is required";
      hasError = true;
    }
    if (!rule || rule.length === 0) {
      newErrors.rule = ["Rule is required"];
      hasError = true;
    } else {
      const ruleErrors = rule.map((r, index) =>
        !r ? `Rule ${index + 1} cannot be empty` : ""
      );
      if (ruleErrors.some((err) => err)) {
        newErrors.rule = ruleErrors.filter(Boolean);
        hasError = true;
      }
    }
    if (maxTejCoins === undefined || maxTejCoins === null) {
      newErrors.maxTejCoins = "Tej Coins is required";
      hasError = true;
    }
    if (!maxLakshmiCoins && !maxTejCoins) {
      newErrors.maxLakshmiCoins = "Lakshmi Coins is required";
      hasError = true;
    }
    if (!termsAndConditions || termsAndConditions === "<p><br></p>") {
      newErrors.termsAndConditions = "Terms and Conditions is required";
      hasError = true;
    }
    switch (scope || campaignDetails?.geographicalScope) {
      case "Local":
        if (!country || !state || !city) {
          newErrors.geoLocation =
            "Country, state, and city are required for Local scope.";
          hasError = true;
        }
        break;
      case "Regional":
        if (!country || !state || validationCity) {
          newErrors.geoLocation =
            "Only Country and state are required for Regional scope.";
          hasError = true;
        }
        break;
      case "National":
        if (!country || state || city) {
          newErrors.geoLocation =
            "Only Country is required for National scope.";
          hasError = true;
        }
        break;

      case "International":
        if (!country || validationState || validationCity) {
          newErrors.geoLocation =
            "Only Country is required for International scope.";
          hasError = true;
        }
        break;
      default:
        break;
    }
    // Validate levels and tasks within levels
    const levelsErrors = levelsData.map((level, levelIndex) => {
      const levelError = { tasks: [] };

      if (!level.levelName || level.levelName.trim() === "") {
        levelError.levelName = "Level name is required";
        hasError = true;
      }
      if (!level.maxCoins || isNaN(level.maxCoins) || level.maxCoins < 0) {
        levelError.maxCoins = "Max coins must be a positive number";
        hasError = true;
      }
      if (!level.coins) {
        levelError.coins = "Coins type is required";
        hasError = true;
      }

      // Validate each task within the level
      level.tasksData.forEach((task) => {
        const taskError = {};
        if (!task.taskDescription || task.taskDescription.trim() === "") {
          taskError.taskDescription = "Task description is required";
          hasError = true;
        }
        if (!task.coinValue || isNaN(task.coinValue) || task.coinValue <= 0) {
          taskError.coinValue = "Coin value must be a positive number";
          hasError = true;
        }
        if (!task.taskName) {
          taskError.taskName = "task type is required";
          hasError = true;
        }
        levelError.tasks.push(taskError);
      });

      return levelError;
    });

    newErrors.levels = levelsErrors;

    // Validate start and end date
    const { startDate, endDate } = campaignDetails.Duration;
    if (new Date(endDate) <= new Date(startDate)) {
      newErrors.endDate = "End date must be greater than start date";
      hasError = true;
    }

    // If there are errors, set them and return early
    if (hasError) {
      setErrors(newErrors);
      return;
    }

    try {
      let response = await userRequest.put(
        `/admin/svarna/editCampaign/${id}`,
        campaignDetails
      );
      toast.success("Saved successfully!");
      navigate("/svarnamanagement");
      setErrors({});
    } catch (error) {
      console.log("Error saving campaign:", error);
      let err = error?.response?.data?.error;
      toast.error(err);
    }
  };

  const handleLevelInputChange = (index, field, value) => {
    setCampaignDetails((prevState) => {
      const newLevel = [...prevState.levelsData];
      if (newLevel[index]) {
        if (field === "levelName") {
          newLevel[index].levelName = value;
          newLevel[index].levelNumber = index + 1;
          newLevel[index].levelDescription = value;
        } else if (field === "totalTasks") {
          newLevel[index].totalTasks = parseInt(value) || 0;
        } else if (field === "maxCoins") {
          newLevel[index].maxCoins = parseInt(value) || 0;
        } else if (field === "coins") {
          newLevel[index].coins = value;
        }
        return {
          ...prevState,
          levelsData: newLevel,
        };
      } else {
        // If newLevel[index] is undefined, return prevState as it is
        return prevState;
      }
    });
  };

  const handleTaskInputChange = (levelIndex, taskIndex, field, option) => {
    setCampaignDetails((prevState) => {
      const newLevelDetails = [...prevState.levelsData];
      const taskDetails =
        newLevelDetails[levelIndex].tasksData[taskIndex] || {};
      if (field === "taskName") {
        taskDetails.taskName = option.label;
        taskDetails.taskDescription = option.value;
        taskDetails.operationId = option.operationId;
        taskDetails.taskType = option.taskType;
      } else if (field === "coinValue") {
        taskDetails.coinValue = option;
      } else if (field === "taskDescription") {
        taskDetails.taskDescription = option.value;
      } else if (field === "taskCount") {
        taskDetails.taskCount = option;
      } else if (field === "numberOfDays") {
        taskDetails.numberOfDays = option;
      }
      newLevelDetails[levelIndex].tasksData[taskIndex] = taskDetails;
      return {
        ...prevState,
        levelsData: newLevelDetails,
      };
    });
  };

  const getSelectedLabel = (value, options) => {
    const selectedOption =
      options &&
      options.length > 0 &&
      options.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : "Select";
  };

  return (
    <div className={styles.main}>
      <PageHeaderTwo
        breadcrumb={[
          { label: "Svarna Management", path: "/svarnamanagement" },
          {
            label: "Edit Campaign",
            path: "#",
          },
        ]}
        discardLabel="Discard"
        saveLabel="Save"
        onDiscardClick={() => navigate("/svarnamanagement")}
        onSaveClick={handleSave}
      />
      <div className={styles.contentContainer}>
        <div className={styles.section}>
          <p>Campaign Details</p>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Campaign Title</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="text"
                  name="campaignTitle"
                  value={campaignDetails?.campaignTitle}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
                {errors.campaignTitle && (
                  <div className={styles.errorMessage}>
                    {errors.campaignTitle}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Role</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={roleOptions}
                  onSelect={handleRoleSelect}
                  initialSelectedOption={getSelectedLabel(
                    campaignDetails?.roleId?._id,
                    roleOptions
                  )}
                />
                {errors.roleId && (
                  <div className={styles.errorMessage}>{errors.roleId}</div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Duration</p>
              </div>
              <div className={styles.fieldInputTwo}>
                <div className={styles.dateInput}>
                  <input
                    type="date"
                    name="startDate"
                    onChange={(e) => handleDurationChange(e, "startDate")}
                    placeholder="Enter"
                    value={formatDate(campaignDetails?.Duration?.startDate)}
                    min={new Date().toISOString().split("T")[0]}
                  />
                  {errors.startDate && (
                    <div className={styles.errorMessage}>
                      {errors.startDate}
                    </div>
                  )}
                </div>
                <div className={styles.dateInput}>
                  <input
                    type="date"
                    name="endDate"
                    onChange={(e) => handleDurationChange(e, "endDate")}
                    placeholder="Enter"
                    value={formatDate(campaignDetails?.Duration?.endDate)}
                    min={getMinEndDate()}
                  />
                  {errors.endDate && (
                    <div className={styles.errorMessage}>{errors.endDate}</div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Max Tej Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="number"
                  name="maxTejCoins"
                  value={campaignDetails?.maxTejCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                  min="0"
                />
                {errors.maxTejCoins && (
                  <div className={styles.errorMessage}>
                    {errors.maxTejCoins}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Max Lakshmi Coins</p>
              </div>
              <div className={styles.fieldInput}>
                <input
                  type="number"
                  name="maxLakshmiCoins"
                  value={campaignDetails?.maxLakshmiCoins}
                  onChange={handleInputChange}
                  placeholder="Enter"
                  min="0"
                />
                {errors.maxLakshmiCoins && (
                  <div className={styles.errorMessage}>
                    {errors.maxLakshmiCoins}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Add Rule </p>
              </div>
              <div className={`${styles.fieldInput} ${styles.fieldInputRule}`}>
                {rules.map((rule, index) => (
                  <div key={index}>
                    <div className={styles.rule}>
                      <textarea
                        value={rule}
                        onChange={(e) => handleRuleChange(index, e)}
                      />
                      {rules.length > 1 && (
                        <button
                          onClick={() => handleRemoveRule(index)}
                        ></button>
                      )}
                    </div>
                    {errors.rule?.[index] && (
                      <div className={styles.errorMessage}>
                        {errors.rule[index]}
                      </div>
                    )}
                    {index === rules.length - 1 && (
                      <button onClick={handleAddRule}>Add More</button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.feildRight}>
              <div className={styles.fieldLabel}>
                <p>Add Terms & Conditions</p>
              </div>
              <div className={styles.fieldInput}>
                {termsAndConditions && (
                  <RichTextEditor
                    value={campaignDetails?.termsAndConditions}
                    onChange={handleTermsChange}
                  />
                )}
                {errors.termsAndConditions && (
                  <div className={styles.errorMessage}>
                    {errors.termsAndConditions}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer}>
            <div className={styles.feildLeft}>
              <div className={styles.fieldLabel}>
                <p>Geographical Scope</p>
              </div>
              <div className={styles.fieldInput}>
                <SelectComponent
                  options={geographicalScopeOptions}
                  onSelect={handleScopeSelect}
                  initialSelectedOption={campaignDetails?.geographicalScope}
                />
                {errors.geographicalScope && (
                  <span className={styles.error}>
                    {errors.geographicalScope}
                  </span>
                )}
              </div>
            </div>
            {selectedGeographicalScope !== "Global" &&
              campaignDetails?.geographicalScope !== "Global" && (
                <div className={styles.feildRight}>
                  <div className={styles.fieldLabel}>
                    <p>Geo Location</p>
                  </div>
                  <div className={styles.fieldInput}>
                    {selectedGeographicalScope !== "International" &&
                      campaignDetails?.geographicalScope !==
                        "International" && (
                        <LocationSearchInput
                          setInputValue={handleLocationChange}
                          setIsTyping={setIsTyping}
                        />
                      )}
                    {!isTyping &&
                      campaignDetails?.geographicalScope !== "International" &&
                      selectedGeographicalScope !== "International" && (
                        <div>
                          <span>
                            Country:{" "}
                            {campaignDetails?.geolocation[0]?.country || ""}
                          </span>
                          <br />
                          <span>
                            State:{" "}
                            {campaignDetails?.geolocation[0]?.state || ""}
                          </span>
                          <br />
                          <span>
                            City: {campaignDetails?.geolocation[0]?.city || ""}
                          </span>
                        </div>
                      )}
                    {campaignDetails?.geographicalScope === "International" && (
                      <>
                        <LocationSearchInput
                          setInputValue={handleAddCountry}
                          selectedGeographicalScope={
                            campaignDetails?.geographicalScope
                          }
                        />
                        <div className={styles.selectedChips}>
                          {selectedCountries.map((item) => (
                            <span key={item.country} className={styles.chip}>
                              {item.country}
                              <button
                                type="button"
                                onClick={() =>
                                  handleRemoveCountry(item.country)
                                }
                                className={styles.removeChip}
                              >
                                ×
                              </button>
                            </span>
                          ))}
                        </div>
                      </>
                    )}
                    {errors.geoLocation && (
                      <div className={styles.errorMessage}>
                        {errors.geoLocation}
                      </div>
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
        <div className={styles.horizontalRow}></div>
        <div className={styles.levelDetailsMain}>
          <p className={styles.levelDetailsHeading}>Level Details</p>
          <button onClick={handleAddLevel}>Add More</button>
        </div>
        <Spin spinning={levelAdded} size="small">
          <Stepper
            strokeColor="#f68b21"
            fillStroke="#f68b21"
            activeColor="#f68b21"
            showProgressBar="true"
            activeProgressBorder="2px solid #f68b21"
            submitBtn={false}
            continueBtn={<button className={styles.stepperBtn}>Next</button>}
            backBtn={<button className={styles.stepperBtn}>Back</button>}
            // onSubmit={(step) => alert(`Thank you!!! Final Step -> ${step}`)}
          >
            <div className={styles.pointsbx}>
              {campaignDetails?.levelsData.map((level, levelIndex) => (
                <div
                  key={levelIndex}
                  className="card"
                  style={{ marginBottom: "5px" }}
                >
                  <div
                    className="card-header d-flex justify-content-between align-items-center cursor-pointer"
                    onClick={() => toggleAccordion(levelIndex)}
                    style={{ padding: "0px 10px" }}
                  >
                    <span className="font-weight-bold">
                      Level {levelIndex + 1}
                    </span>
                    <button className="btn btn-link text-warning">
                      {activeIndex === levelIndex ? "△" : "▽"}
                    </button>
                  </div>
                  {/* Accordion Content */}
                  {activeIndex === levelIndex && (
                    <div className="card-body">
                      <div className={styles.stepperSubDiv}>
                        <button
                          className={styles.deleteItem}
                          onClick={() => handleRemoveLevel(levelIndex)}
                        ></button>
                        <div className={styles.section}>
                          <div className={styles.fieldContainer}>
                            <div className={styles.feildLeft}>
                              <div className={styles.fieldLabel}>
                                <p>Level {levelIndex + 1}</p>
                              </div>
                            </div>
                          </div>
                          <div className={styles.fieldContainer}>
                            <div className={styles.feildLeft}>
                              <div className={styles.fieldLabel}>
                                <p>Name of Level</p>
                              </div>
                              <div className={styles.fieldInput}>
                                <input
                                  type="text"
                                  value={
                                    campaignDetails.levelsData[levelIndex]
                                      ?.levelName
                                  }
                                  onChange={(e) =>
                                    handleLevelInputChange(
                                      levelIndex,
                                      "levelName",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Enter"
                                />
                                {errors.levels?.[levelIndex]?.levelName && (
                                  <div className={styles.errorMessage}>
                                    {errors.levels[levelIndex].levelName}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className={styles.fieldContainer}>
                            <div className={styles.feildLeft}>
                              <div className={styles.fieldLabel}>
                                <p>Max Coins</p>
                              </div>
                              <div className={styles.fieldInput}>
                                <input
                                  type="number"
                                  value={
                                    campaignDetails.levelsData[levelIndex]
                                      ?.maxCoins
                                  }
                                  onChange={(e) =>
                                    handleLevelInputChange(
                                      levelIndex,
                                      "maxCoins",
                                      e.target.value
                                    )
                                  }
                                  min={0}
                                  placeholder="Enter"
                                />
                                {errors.levels?.[levelIndex]?.maxCoins && (
                                  <div className={styles.errorMessage}>
                                    {errors.levels[levelIndex].maxCoins}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={styles.feildRight}>
                              <div className={styles.fieldLabel}>
                                <p>Select Coins</p>
                              </div>
                              <div className={styles.fieldInput}>
                                <SelectComponent
                                  options={coinOptions}
                                  onSelect={(option) =>
                                    handleCoinSelect(option, levelIndex)
                                  }
                                  initialSelectedOption={
                                    campaignDetails?.levelsData[levelIndex]
                                      ?.coins
                                  }
                                />
                                {errors.levels?.[levelIndex]?.coins && (
                                  <div className={styles.errorMessage}>
                                    {errors.levels[levelIndex].coins}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={styles.section}>
                          <div className={styles.taskDetailsMain}>
                            <p>Task Details</p>
                            <button
                              className={styles.taskAdd}
                              onClick={() => handleAddTask(levelIndex)}
                              disabled={taskAdded}
                            >
                              Add Task
                            </button>
                          </div>

                          {level.tasksData.map((task, taskIndex) => (
                            <div
                              key={taskIndex}
                              className="card"
                              style={{ marginBottom: "5px" }}
                            >
                              <div
                                className="card-header d-flex justify-content-between align-items-center cursor-pointer"
                                onClick={() => toggleTaskAccordion(taskIndex)}
                                style={{ padding: "0px 10px" }}
                              >
                                <span className="font-weight-bold">
                                  Task {taskIndex + 1}
                                </span>
                                <button className="btn btn-link text-warning">
                                  {activeTaskIndex === taskIndex ? "△" : "▽"}
                                </button>
                              </div>
                              {/* Task Accordion Content */}
                              {activeTaskIndex === taskIndex && (
                                <div
                                  className={`${styles.fieldContainer} ${styles.fieldContainerTwo}`}
                                  key={taskIndex}
                                >
                                  <div className={styles.deleteDiv}>
                                    <button
                                      className={styles.deleteItemTwo}
                                      onClick={() =>
                                        handleRemoveTask(levelIndex, taskIndex)
                                      }
                                    ></button>
                                  </div>
                                  <div className={styles.feildLeft}>
                                    <div className={styles.fieldLabel}>
                                      <p>Coin Value</p>
                                    </div>
                                    <div className={styles.fieldInput}>
                                      <input
                                        type="text"
                                        value={
                                          campaignDetails.levelsData[levelIndex]
                                            ?.tasksData[taskIndex]?.coinValue
                                        }
                                        onChange={(e) =>
                                          handleTaskInputChange(
                                            levelIndex,
                                            taskIndex,
                                            "coinValue",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Enter"
                                      />
                                      {errors.levels?.[levelIndex]?.tasks?.[
                                        taskIndex
                                      ]?.coinValue && (
                                        <div className={styles.errorMessage}>
                                          {
                                            errors.levels[levelIndex].tasks[
                                              taskIndex
                                            ].coinValue
                                          }
                                        </div>
                                      )}
                                    </div>

                                    <div className={styles.fieldLabel}>
                                      <p>Task Description</p>
                                    </div>
                                    <div className={styles.fieldInput}>
                                      <textarea
                                        value={
                                          campaignDetails.levelsData[levelIndex]
                                            ?.tasksData[taskIndex]
                                            ?.taskDescription
                                        }
                                        onChange={(e) =>
                                          handleTaskInputChange(
                                            levelIndex,
                                            taskIndex,
                                            "taskDescription",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Enter"
                                      />
                                      {errors.levels?.[levelIndex]?.tasks?.[
                                        taskIndex
                                      ]?.taskDescription && (
                                        <div className={styles.errorMessage}>
                                          {
                                            errors.levels[levelIndex].tasks[
                                              taskIndex
                                            ].taskDescription
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className={styles.feildRight}>
                                    <div className={styles.fieldLabel}>
                                      <p>Task Type</p>
                                    </div>
                                    <div className={styles.fieldInput}>
                                      <SelectComponent
                                        options={taskTypes.map((task) => ({
                                          label: task.title,
                                          value: task.description,
                                          operationId: task._id,
                                          taskType: task.taskType,
                                        }))}
                                        onSelect={(option) =>
                                          handleTaskInputChange(
                                            levelIndex,
                                            taskIndex,
                                            "taskName",
                                            option
                                          )
                                        }
                                        initialSelectedOption={
                                          campaignDetails?.levelsData[
                                            levelIndex
                                          ]?.tasksData[taskIndex]?.taskName
                                        }
                                      />
                                      {errors.levels?.[levelIndex]?.tasks?.[
                                        taskIndex
                                      ]?.taskName && (
                                        <div className={styles.errorMessage}>
                                          {
                                            errors.levels[levelIndex].tasks[
                                              taskIndex
                                            ].taskName
                                          }
                                        </div>
                                      )}
                                    </div>

                                    {campaignDetails?.levelsData[levelIndex]
                                      ?.tasksData[taskIndex]?.taskType ===
                                      "Continuous" && (
                                      <>
                                        <div className={styles.fieldLabel}>
                                          <p>Number Of Days</p>
                                        </div>
                                        <div className={styles.fieldInput}>
                                          <input
                                            type="text"
                                            value={
                                              campaignDetails.levelsData[
                                                levelIndex
                                              ]?.tasksData[taskIndex]
                                                ?.numberOfDays
                                            }
                                            onChange={(e) =>
                                              handleTaskInputChange(
                                                levelIndex,
                                                taskIndex,
                                                "numberOfDays",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Enter"
                                          />
                                        </div>
                                      </>
                                    )}
                                    {campaignDetails?.levelsData[levelIndex]
                                      ?.tasksData[taskIndex]?.taskType ===
                                      "Multiple" && (
                                      <>
                                        <div className={styles.fieldLabel}>
                                          <p>Task Count</p>
                                        </div>
                                        <div className={styles.fieldInput}>
                                          <input
                                            type="text"
                                            value={
                                              campaignDetails.levelsData[
                                                levelIndex
                                              ]?.tasksData[taskIndex]?.taskCount
                                            }
                                            onChange={(e) =>
                                              handleTaskInputChange(
                                                levelIndex,
                                                taskIndex,
                                                "taskCount",
                                                e.target.value
                                              )
                                            }
                                            placeholder="Enter"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Stepper>
        </Spin>
      </div>
    </div>
  );
}

export default EditCampaign;
