import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const LocationSearchInput = ({
  setInputValue,
  selectedGeographicalScope,
  fullAddress,
  setIsTyping,
}) => {
  const [address, setAddress] = useState("");
  const [locationDetails, setLocationDetails] = useState({
    city: "",
    state: "",
    country: "",
  });
  
  if (setIsTyping !== undefined) {
    if (address) {
      setIsTyping(true);
    } else {
      setIsTyping(false);
    }
  }

  const handleSelect = async (value) => {
    try {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      console.log("results", results);
      console.log("Selected Address:", value);
      console.log("Coordinates:", latLng);

      const components = results[0].address_components;

      const city =
        components.find((comp) =>
          comp.types.includes("administrative_area_level_3")
        )?.long_name ||
        components.find((comp) => comp.types.includes("locality"))?.long_name;

      const state = components.find((comp) =>
        comp.types.includes("administrative_area_level_1")
      )?.long_name;

      const stateCode = components.find((comp) =>
        comp.types.includes("administrative_area_level_1")
      )?.short_name;

      const country = components.find((comp) =>
        comp.types.includes("country")
      )?.long_name;

      const countryCode = components.find((comp) =>
        comp.types.includes("country")
      )?.short_name;

      setInputValue({
        city: city || "",
        state: state || "",
        country: country || "",
        placeId: results[0].place_id,
        coordinates: latLng,
        fullAddress: value,
        country_code: countryCode,
        state_code: stateCode,
      });

      setLocationDetails({ city, state, country });
      setAddress(value);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Search Location...",
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#fafafa" : "#ffffff",
                  cursor: "pointer",
                  padding: "10px",
                };
                return (
                  <div
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion, { style })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {address && selectedGeographicalScope !== "International" && (
        <div>
          <span>Country: {locationDetails.country || ""}</span>
          <br />
          <span>State: {locationDetails.state || ""}</span>
          <br />
          <span>City: {locationDetails.city || ""}</span>
        </div>
      )}
    </>
  );
};

export default LocationSearchInput;
