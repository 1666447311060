import React from "react";
import styles from "./masterPandit.module.css";
import PageHeader from "../../../components/PageHeader/PageHeader";
import MenuItems from "../../../components/MenuItems/MenuItems";
import PoojaCategory from "./PoojaCategory/PoojaCategory";
import PanditKyc from "./PanditKyc/PanditKyc";
import PanditType from "./PanditType/PanditType";
import PoojaMaterial from "./PoojaMaterial/PoojaMaterial";
import MasterPooja from "./MasterPooja/MasterPooja";

function MasterPandit() {
  const menuItems = [
    { label: "Master Pooja", state: "MasterPooja" },
    { label: "Pooja Category", state: "PoojaCategory" },
    { label: "KYC", state: "KYC" },
    { label: "Pandit Type", state: "PanditType" },
    { label: "Pooja Material", state: "PoojaMaterial" },
  ];
  const contentMap = {
    MasterPooja: <MasterPooja />,
    PoojaCategory: <PoojaCategory />,
    KYC: <PanditKyc />,
    PanditType: <PanditType />,
    PoojaMaterial: <PoojaMaterial />,
  };

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[
          { label: "Master Data", path: "/master/pandit" },
          { label: "Pandit", path: "/master/pandit" },
        ]}
      />

      <MenuItems menuItems={menuItems} contentMap={contentMap} />
    </div>
  );
}

export default MasterPandit;
