import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./NotificationFilter.module.css";
import SelectComponent from "../../components/SelectComponent";

const NotificationFilter = ({
  isModalOpen,
  handleOk,
  handleCancel,
  roleData,
}) => {
  const [formData, setFormData] = useState({
    userType: "",
    date: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFilter = () => {
    handleOk(formData);
  };

  const options = [
    { value: "", label: "All" },
    { value: "Individual", label: "Individual" },
    ...roleData.map((option) => ({
      value: option.roleName,
      label: option.roleName,
    })),
  ];

  return (
    <Modal
      visible={isModalOpen}
      onCancel={handleCancel}
      onOk={handleFilter}
      className={styles.modal}
    >
      <div className={styles.modalTitle}>
        <p>Filter Notification</p>
      </div>
      <div className={styles.contentMain}>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Date</p>
          </div>
          <div className={styles.fieldInput}>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>User Type</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={options}
              onSelect={(selectedOption) => {
                setFormData({
                  ...formData,
                  userType: selectedOption.value,
                });
              }}
            />
          </div>
        </div>
        <div className={styles.modalButton}>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleFilter}>Filter</button>
        </div>
      </div>
    </Modal>
  );
};

export default NotificationFilter;
