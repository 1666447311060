import React, { useEffect, useState } from "react";
import styles from "./notificationMgmt.module.css";
import { useNavigate } from "react-router-dom";
import { userRequest } from "../../requestMethod";
import NotificationFilter from "./NotificationFilter";
import useDebounce from "../../utils/useDebounce";
import PageHeader from "../../components/PageHeader/PageHeader";
import SearchFilterComponent from "../../components/SearchFilter/SearchFilterComponent";
import { handleExportDoc } from "../../utils/exportDoc";
import TableComponent from "../../components/TableComponent/TableComponent";
import { formatDate } from "../../utils/DateFormat";
import { Tooltip } from "antd";

function NotificationManagement() {
  const [notificationData, setNotificationData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [roleData, setRoleData] = useState([]);

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  console.log("notificationData", notificationData);

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [debouncedSearchQuery, page, filterData]);

  const fetchData = async () => {
    try {
      const filterQuery = filterData
        ? `&date=${filterData.date}&userType=${filterData.userType}`
        : "";
      const response = await userRequest.get(
        `/admin/notification/getNotificationList?search=${debouncedSearchQuery}${filterQuery}&page=${page}`
      );
      setNotificationData(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
      setCurrentPage(response.data.pagination.page);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserRoles = async () => {
    try {
      const response = await userRequest.get("/userRoles/getAllUserRoles");
      setRoleData(response.data.data);
    } catch (error) {
      console.error("Error fetching user roles:", error);
    }
  };

  const handleFilterClick = () => {
    fetchUserRoles();
    setIsModalOpen(true);
  };

  const handleModalOk = (data) => {
    setFilterData(data);
    setIsModalOpen(false);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleExport = () => {
    handleExportDoc(
      "/pushNotification/exportPushNotificationDataToExcel",
      "PushNotification.xlsx"
    );
  };

  const columns = [
    { key: "title", title: "Title" },
    {
      key: "createdAt",
      title: "Date",
      render: (item) => formatDate(item?.notifications[0].createdAt),
    },
    {
      key: "message",
      title: "Message",
      render: (item) => item?.notifications[0].message,
    },
    {
      key: "userType",
      title: "User Type",
      render: (item) => item?.notifications[0]?.target?.user_segments[0],
    },

    {
      key: "sendTo",
      title: "Send To",
      render: (item) => {
        const userList = item?.userNotificationList || [];
        if (userList.length === 0) return "No recipients";

        const firstUser = userList[0]?.user_id;
        if (!firstUser) return "Invalid User Data";

        const fullName = `${firstUser.firstName} ${firstUser.lastName}`;

        if (userList.length === 1) {
          return fullName;
        }

        const remainingUsers = userList
          .slice(1)
          .map((user) => `${user.user_id.firstName} ${user.user_id.lastName}`);
        const tooltipContent = remainingUsers.join(", ");

        return (
          <Tooltip title={tooltipContent}>
            <span style={{ cursor: "pointer"}} >
              {`${fullName} + `} <span className="text-warning">{userList.length - 1} more</span>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <PageHeader
        breadcrumbs={[{ label: "Notification Management", path: "#" }]}
        buttonLabel={"Add New"}
        onButtonClick={() =>
          navigate("/notificationmanagement/createnotification")
        }
      />
      <div className={styles.contentContainer}>
        <div className={styles.activePage}>
          <SearchFilterComponent
            setSearchQuery={setSearchQuery}
            onExportClick={handleExport}
            filter
            onFilterClick={handleFilterClick}
          />
          <TableComponent
            columns={columns}
            data={notificationData}
            loading={loading}
            currentPage={currentPage}
            totalPages={totalPages}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        </div>
      </div>
      <NotificationFilter
        isModalOpen={isModalOpen}
        handleOk={handleModalOk}
        handleCancel={handleModalCancel}
        roleData={roleData}
      />
    </div>
  );
}

export default NotificationManagement;

function formatSendTo(sendToArray) {
  if (!sendToArray || sendToArray.length === 0) {
    return "No recipients";
  }
  const names = sendToArray.map((recipient) => recipient.firstName);
  return names.length > 1 ? `${names[0]} + ${names.length - 1} more` : names[0];
}
