import React from 'react'
import styles from './header.module.css'
import notificationIcon from '../../assets/images/notificationIcon.png'
import userIcon from '../../assets/images/userProfileIcon.png'

function HeaderComp() {
  return (
    <div className={styles.headerMain}>
      <div className={styles.headerLeft}></div>
      <div className={styles.headerRight}>
        {/* <input type="text" /> */}
        {/* <img src={notificationIcon} alt="notificationIcon"  className={styles.notificationIcon}/> */}
        <img src={userIcon} alt="userIcon" className={styles.userIcon}/>
      </div>
    </div>
  )
}

export default HeaderComp
