import React from "react";
import styles from "./setKYC.module.css";
import SelectComponent from "../../../../components/SelectComponent";

function SetCoupon() {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Country</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent />
          </div>
        </div>
        <div className={styles.feildRight}>
          <div className={styles.fieldLabel}>
            <p>Applicable for Role</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>State</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" />
          </div>
        </div>
        <div className={styles.feildRight}>
          <div className={styles.fieldLabel}>
            <p>Condions</p>
          </div>
          <div className={styles.fieldInput}>
            <textarea />
          </div>
        </div>
      </div>
      {/* <div className={styles.fieldContainer} >
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Conditions</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent />
          </div>
        </div>
      </div> */}
      <div className={styles.fieldContainer} style={{ marginTop: "-4%" }}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Coupon Name</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Coupon Type</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Coupon Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Final Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Maximum Amount</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text" />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Duration</p>
          </div>
          <div className={styles.fieldDate}>
            <input type="date" placeholder="YYYY-MM-DD" />
            <input type="date" placeholder="End Date" />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer}>
        <div className={styles.feildLeft}>
          <div className={styles.fieldLabel}>
            <p>Usage Count</p>
          </div>
          <div className={styles.fieldInput}>
            <input type="text"  style={{background:"#F5F5F5B2"}}/>
          </div>
        </div>
      </div>
      <div className={styles.footer}></div>
    </div>
  );
}

export default SetCoupon;
