import React, { useState } from "react";
import { Modal } from "antd";
import styles from "./samhitaFilter.module.css";
import SelectComponent from "../../components/SelectComponent";

const SamhitaFilter = ({ isModalOpen, handleOk, handleCancel,categoriesData, subCategoriesData,languageData }) => {
  const [formData, setFormData] = useState({
    category: "",
    subCategory: "",
    languages: "",
  });

  const categoryOptions = categoriesData.map((option) => ({
      value: option._id,
      label: option.category,
    }));
  const subCategoryOption = subCategoriesData.map((option) => ({
      value: option._id,
      label: option.subCategory,
    }));
  const languageOption = languageData.map((option) => ({
      value: option._id,
      label: option.language,
    }));


  const handleCategorySelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      category: selectedOption.value,
    }));
  };
  const handleSubCategorySelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      subCategory: selectedOption.value,
    }));
  };
  const handleLanguageSelect = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      languages: selectedOption.value,
    }));
  };

  const handleFilter = () => {
    handleOk(formData); // Pass formData back to parent component
  };

  const getSelectedLabel = (value, options) => {
    const selectedOption = options.find(option => option.value === value);
    return selectedOption ? selectedOption.label : "Select";
  };

  return (
    <Modal
      visible={isModalOpen}
      onCancel={handleCancel}
      onOk={handleFilter}
      width={"29vw"}
      className={styles.modal}
    >
      <div className={styles.modalTitle}>
        <p>Filter Samhitas</p>
      </div>
      <div className={styles.contentMain}>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Category</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={categoryOptions}
              onSelect={handleCategorySelect}
              initialSelectedOption={getSelectedLabel(formData.category, categoryOptions)}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Subcategory</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={subCategoryOption}
              onSelect={handleSubCategorySelect}
              initialSelectedOption={getSelectedLabel(formData.subCategory, subCategoryOption)}
            />
          </div>
        </div>
        <div className={styles.fieldContainer}>
          <div className={styles.fieldLabel}>
            <p>Languages</p>
          </div>
          <div className={styles.fieldInput}>
            <SelectComponent
              options={languageOption}
              onSelect={handleLanguageSelect}
              initialSelectedOption={getSelectedLabel(formData.languages,languageOption)}
            />
          </div>
        </div>
        <div className={styles.modalButton}>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleFilter}>Filter</button>
        </div>
      </div>
    </Modal>
  );
};

export default SamhitaFilter;
